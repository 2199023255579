
import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
@Directive({
    selector: '[validateFrom][formControlName],[validateFrom][formControl],[validateFrom][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => FromValidatorDirective), multi: true }
    ]
})
export class FromValidatorDirective implements Validator {
    constructor(@Attribute('validateFrom') public validateFrom: string,
    @Attribute('match') public match: string,
    @Attribute('reverse') public reverse: string,
    ) {
    }

    private get isReverse() {
        if (!this.reverse) return false;
        return this.reverse === 'true' ? true: false;
    }

    validate(c: AbstractControl): { [key: string]: any } {
        // self value
        	
        		var v = c.value;

       

	        // control vlaue
	        var e = c.root.get(this.validateFrom);
	    if(e!=null){
	    	

	        if(!this.isReverse){
	       	 var val=this.match;
	        }

	    	// value equal //altro
	    	if(!this.isReverse){
		    	console.log("valore campo: ",v);
		    	console.log("valore select; ",e.value);
		    	console.log("valore match:",this.match);
		    	console.log("reverse: ",this.isReverse);
	    }

		    if(this.isReverse){
		    	console.log("valore select; ",v);
		    	console.log("valore campo: ",e.value);
		    	console.log("reverse: ",this.isReverse);

		    }
	    
	        if ( e.value === this.match && (v==='' || v===undefined ) && !this.isReverse) {
	        	console.log("altro attivo e restituisco falso");
	            return { validateFrom: false };
	        } else { console.log("altro non attivo e restituisco null");}

	        if(v ==='altro' && this.isReverse && (e.value==='' || e.value===undefined )){
	        	console.log("devo lanciare un errore perchè il campo è vuoto");
	        	 e.setErrors({ validateFrom: false });
	        }

	         // value equal and reverse
	        if ( v === 'altro' && e.value !='' && this.isReverse) {
	        	console.log("ilcampo on è vuoto allora tolgo eventuali errori");
	            delete e.errors['validateFrom'];
	            if (!Object.keys(e.errors).length) e.setErrors(null);
	        }

	         if ( v === 'predefinita' && this.isReverse) {
	        	console.log("select vale predefinita, tolgo errori");
	        	if(e.errors!=null)
	        		if(e.errors['validateFrom']!=null){
	            		delete e.errors['validateFrom'];
	            		if (!Object.keys(e.errors).length) e.setErrors(null);
	        		}
	            
	        }
	       }
	     


      	// value not equal //diverso da altro
       /* if ((e && e.value === this.match && v!='')||(e && e.value !== this.match )){
            delete e.errors['validateFrom'];
            if (!Object.keys(e.errors).length) e.setErrors(null);
        }*/

      
        return null;
    }
}