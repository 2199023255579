
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {DbService} from '../../services/db.service';
import {StatusService} from '../../services/status.service';
import {Router} from '@angular/router';
import {Username} from '../../models/username';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import 'rxjs';
import {AppSettings} from '../../app-settings';

@Component({
  selector: 'app-add-ads',
  templateUrl: './add-ads.component.html',
  styleUrls: ['./add-ads.component.css'],
  providers: [DbService]
})
export class AddAdsComponent implements OnInit {






  private titlePage:string="sostegno all'amministratore";
  constructor(private http:HttpClient, private dbService:DbService, private router:Router, private statusService:StatusService) { }


checkAccess(){
    if(!this.statusService.getAccess()){
        this.router.navigate(['']);
    }
   }
  ngOnInit() {

    this.checkAccess();
   
  }


 public accessError(){

  	return this.accessE;

  }

logButton(){
  return "log in";
}
regButton(){
	return "registrati";
}

rememberMe(){
	return "ricordami";
}

lostPassword(){
	return "password dimenticata?";
}


onSubmit(){
  console.log("onsubmit clicked;");
  
  this.logIn();

}
 accessE:string="errore";
  home:string="www.test.com";
activeAccount:boolean=false;

saveCookie:boolean=false;
success:boolean=true;
agent=null;
account?=null;

public user= new Username();


private alreadyIn(){
  return this.activeAccount;

}
private getSuccess(){
  return this.success;
}




  private setParams(p){
      this.user.userName=p.userName;
      this.saveCookie=(p.saveCookie==1);
      console.log("user:",this.user);
      console.log("saveCookie:",this.saveCookie);
      if(this.saveCookie){
        this.user.password="valida";
      }else
      {
        this.user.password="";
        this.user.userName="";
      }

  }
  private handleError(error: Response) {
        console.error(error);
        this.accessE=error.statusText;
        console.log("loggo l'errore:",error);
        console.log("statusText: ",error.statusText);
        return Observable.throw(error);
    }

     

   


private logIn(){
    

    

  this.accessE="log in chiamato";
   

      
          this.dbService.linkAccount(this.user,this.statusService.getSpecialAccount())
            /*  .map(res =>{
           if(res.status==204)
             throw new Error("Utente/email inesistente");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



         })*/
              .subscribe(
              data => this.setAccount(data),
              err => console.error(err+"errore da login standard")
              );
          
  }





private setAccount(datum){
  console.warn("set account chiamta");
  this.account=datum.data;

  if(datum.status=="Success"){
    console.log("sono su ramo success");
      this.success=true;
      this.activeAccount=true;
       //carico profilo ads in area di lavoro
      this.statusService.setLoadedAccount(this.account);
      


      //carico cognome dell'ads caricaato su navbar
      this.dbService.getAmministratore(this.statusService.getId())
      //.map(res => res.json())
      .subscribe( data =>  this.statusService.updateSurname((data as any).data.cognome));

      console.log("loggo idAccount da statusService: ",this.statusService.getId());
      alert('amministratore di sostegno aggregato con successo!');

      this.router.navigate(['/dashboard']);
      //this.salvaCookie();

    }
   else if(datum.status=="Failure")
   {  
     console.log("sono su ramo failure");
      this.success=false;
     this.activeAccount=false;
     this.accessE=datum.msg;
     // this.unsetCookie();
      //this.execLogOut();*/
   }

   console.log("da submit logIn:",datum);

 

}


          
        
      
    
    
    
    
    
 

}