import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders,HttpResponse } from '@angular/common/http';
import {DbService} from '../../services/db.service';
import {Username} from '../../models/username';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {LangService} from '../../services/lang.service';

import {AppSettings} from '../../app-settings';

@Component({
  selector: 'app-lostpassword',
  templateUrl: './lostpassword.component.html',
  styleUrls: ['./lostpassword.component.css'],
  providers:[DbService,LangService]
})
export class LostpasswordComponent implements OnInit {

  /*
lunedì 28 novembre start: 15:43

  */

 constructor(private route:ActivatedRoute, private http:HttpClient,private dbService:DbService, private langService: LangService) { }

 kind:string;

  ngOnInit() {
     this.kind=this.route.snapshot.params['kind'];
  }
public rUserName:string;

  send(){

  	return this.langService.send();
  }

  backToLogIn(){
  	return this.langService.backToLogIn();
  }

   private handleError(error: HttpResponse<any>) {
        console.error(error);

        return Observable.throw(error);
    }



  resetPassword(){

    if(this.kind=='usr')

   return  this.dbService.getUserId(this.rUserName)  // Luke 15-12-2020 (al posto di getUserName)
        /*.map(res => {
          if(res.status==204){
            throw new Error("Utente/email inesistente");


          }
          if(res.status==406){
               throw new Error("errore di rete");

          }
          if(res.status==200)
            return res.json();

        })*/
        .subscribe(
          data => this.processNewPassword(data,this.kind),
          err => this.processNewPasswordError(err)
          );

    if(this.kind=='opr'){
      return  this.dbService.getUserIdOperator(this.rUserName) // Luke 15-12-2020 (al posto di getUserNameOperator)
       /* .map(res => {
          if(res.status==204){
            throw new Error("Utente/email inesistente");


          }
          if(res.status==406){
               throw new Error("errore di rete");

          }
          if(res.status==200)
            return res.json();

        })*/
        .subscribe(
          data => this.processNewPassword(data,this.kind),
          err => this.processNewPasswordError(err)
          );
    }


  }

 processNewPasswordError(msg){
   alert(msg);
   console.error(msg);
 }



  processNewPassword(data,kind){
    //let msg:string="All'indirizzo "+data.email+" verranno inviate le istruzioni per generare una nuova password";  // original

    // Riflessione sulla sicurezza: se avviso l'utente che l'email non è presente, fornisco informazioni
    // a un potenziale malintenzionato sugli account presenti nel database
    let msg:string="All'indirizzo email inserito verranno inviate le istruzioni per generare una nuova password";  // Luke 15-12-2020
    if(data['email']=="none") {
      msg = "L'indirizzo email inserito non è associato a un account";
      alert(msg);
    }
    else {
      alert(msg);
      this.sendNewPassword(data,kind);
    }
    console.log(data);
  }


  sendNewPassword(user,kind){


      // var data = {nomeUtente:user.userName,email:user.email,idUtente:user.idAccount,kind:kind};
      // var json=JSON.stringify(data);
      var json=JSON.stringify({'id':user.id, 'email':user.email, 'token':user.token, 'kind':kind});  // Luke 15-12-2020 (for security, send idAccount only)

      var params=json;
      var headers;

      console.log(json, "to sendNewPassword.php");


      headers=new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
      return this.http.post(`${AppSettings.API_ENDPOINT}/php/sendNewPwd.php`,params,{headers:headers})
      //.map(res=>res.json())
      //.catch(this.handleError)
      .subscribe(data=>console.log(data,"new password instruction sent"));


  }


     /* method: "post",
              url: "php/sendNewPwd.php",
              data: {
                  nomeUtente: data.data.nomeUtente,
                  email: data.data.email,
                  idUtente:data.data.AG_RE_Cpt,

              },
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
*/



}
