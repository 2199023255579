/*
- rg:string;
- cartaIdNumero:string;
- cartaIdRilasciato:string;
- cartaIdRilasciatoData:string;
- delegatoNome:string;
- delegatoCognome:string;
- pec:string;
 */

export class DomicilioDigitale {
    constructor(public rg:string='',public cartaIdNumero:string='',public cartaIdRilasciato:string='',public cartaIdRilasciatoData:string='', public delegatoNome:string='', public delegatoCognome:string='',public pec:string=''){}
}
