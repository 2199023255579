import { Component, OnInit, ViewChild } from '@angular/core';
import {LangService} from '../../services/lang.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {StatusService} from '../../services/status.service';
import {DbService} from '../../services/db.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {AccountOperator} from '../../models/account-operator';
import {Account} from '../../models/account';
import {AppSettings} from '../../app-settings';


@Component({
  selector: 'app-create-operator',
  templateUrl: './create-operator.component.html',
  styleUrls: ['./create-operator.component.css'],
    providers:[LangService,DbService]
})
export class CreateOperatorComponent implements OnInit {


  private idSportello:number;

  constructor(private router:Router,private activatedRoute: ActivatedRoute,private dbService:DbService, private http:HttpClient, private langService:LangService, private statusService:StatusService) {
 }

 checkAccess(){
    if(!this.statusService.getAccess()){
        this.router.navigate(['']);
    }
}
  ngOnInit() {
    this.checkAccess();
  		this.windows=[];
  		this.accountOperator=new AccountOperator();
      this.accountOperator.idSportello=undefined;
  		this.updateWindowList();
  }

  public windows;
  private accountOperator;
   @ViewChild('account',{static:false}) accComponent;
   @ViewChild('operatorForm',{static:false}) form;

      disableCreateAccountForm=false;
   createAccountMessage="profilo creato con successo! Abbiamo inviato un messaggio di attivazione all'indirizzo email dell'operatore. Ricordagli di accedere al suo profilo di posta elettronica (e di controllare anche lo spam) e di seguire le istruzioni indicate nella mail.";
   hideCreateAccuontMessage=true;

   LabelOperator(){
  	return this.langService.operatore();
  }

  LabelTelefono(){
  	return this.langService.telefono();
  }

  LabelAnagrafica(){
  	return this.langService.anagrafica();
  }

  LabelTutela(){
  	return this.langService.inizioTutela();
  }

  LabelWindow(){
  	 	return this.langService.window();
  }

   handleError(error:Response){
    console.log("loggo l'errore:",error);
        console.log("statusText: ",error.statusText);
        this.windows=[];
        return of(error);
  }

    updateWindowList(){
  	


     this.statusService.getSpecialAccount()

     //console.warn("init RicrosoArchive - account:",this.account);

// head si intente il responsabile, ovvero E.Bortolus

  if(this.statusService.getSpecialAccount().kind=='opr' && !this.statusService.getSpecialAccount().head){
    console.warn("sono un operatore");
    this.idSportello=this.statusService.getIdSportello();
    console.warn("idSportello: ",this.idSportello);
    if(this.idSportello!=0){
        console.warn("idSpertelo != 0");
        this.dbService.getWindow(this.idSportello).pipe(
        catchError((error)=>{
          return this.handleError(error)
        }))
        .subscribe(data => this.setList(data));

    }
   }
   if(this.statusService.getSpecialAccount().kind=='adm' || this.statusService.getSpecialAccount().head){
     console.warn("sono admin or head (responsabile)");
    
      this.dbService.getWindowList()
        .pipe(
        catchError((error)=>{
          return this.handleError(error)
        })
        )
    .subscribe(data => this.setList(data));

     
    }





  	 

  }

 setList(data){
 	console.log(data);
  	this.windows=data.data;
  	console.log("lista di sportelli:", this.windows);
  }

  createAccuont(account:boolean,acc:Account){
   /* console.log("accountOperator:");
    console.log(this.accountOperator);
    console.log("account:");
    console.log(this.accComponent.account);
    console.log("operatorform:");
    console.log(this.form);*/


   		this.accComponent.form.onSubmit();
   		this.form.onSubmit();

       console.log("operator form is valid? ",this.form.valid);
       console.log("sportello field is valid? ")
       console.log("sportello field is valid? ",this.form.controls.sportello.valid);
   		
   		if(account && this.form.valid){
   			this.accountOperator.email=acc.email;
   			this.accountOperator.password=acc.password;
         console.log("loggo account operator");
         console.log("loggo account operator");
         console.log("loggo account operator");
         console.log("loggo account operator");
         console.log("loggo account operator");
         console.log("loggo account operator");
         console.log("loggo account operator");
   			//console.log(this.accountOperator);

         console.log("prima di piantarmi");
   			this.accountOperator.cypherPwd();
   			console.log("cifro");
   			console.log(this.accountOperator);
   		
        //sembra che chi è admin possa creare solo supervisori
           if(this.statusService.isAdmin())
           {
            console.warn("SONO ADMIN");
           }
           else{
            console.warn("non sono admin");
           }

        if(this.statusService.isAdmin())
   			this.dbService.createAccountSupervisor(this.accountOperator,'opr')
   				/*.map(res => {
     			if(res.status==204)
     				throw new Error("profilo non creato");

     			if(res.status==406)
     				 throw new Error("errore di rete");

     			if(res.status==200)
     				return res.json();



     		})*/
   				.subscribe(
   					data => this.sendActivationEmail(this.accountOperator),
          			err => console.error(err)
   					);

          //chi non è admin, ma è supervisore crea altri operatori
       if(this.statusService.getSpecialAccount().kind=='opr')
         this.dbService.createAccountOperator(this.accountOperator,'opr')
          /* .map(res => {
           if(res.status==204)
             throw new Error("profilo non creato");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



         })*/
           .subscribe(
             data => this.sendActivationEmail(this.accountOperator),
                err => console.error(err)
             );

   		}

 }

 sendActivationEmail(user){


      var data = {email:user.email};

      var json=JSON.stringify(data);
       
      var params=json;


      var headers=new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
      return this.http.post(`${AppSettings.API_ENDPOINT}/php/sendActivationEmailOperator.php`,params,{headers:headers})
      //.map(res=>res.json())
      //.catch(this.handleError)
      .subscribe(data=>this.createAccountFeedback(data));


     }


     resetForm(){
     	this.disableCreateAccountForm=false;
   		this.hideCreateAccuontMessage=true;
   		this.accountOperator=new AccountOperator();
   		this.accComponent.account=new Account();

     }

        createAccountFeedback(data){
     if(data.success){
       this.disableCreateAccountForm=true;
       this.hideCreateAccuontMessage=false;
      }
      else {
        this.createAccountMessage="Il profilo operatore &egrave; stato creato ma si &egrave; verificato un errore nell'attivazione. Se il problema persiste contattaci.";
  
        this.hideCreateAccuontMessage=false;
      }
       


   }



}
