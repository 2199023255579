import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders,HttpResponse } from '@angular/common/http';
import {DbService} from '../../services/db.service';
import {StatusService} from '../../services/status.service';
import {Router} from '@angular/router';
import {Username} from '../../models/username';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import * as CryptoJS from 'crypto-js';

import {AppSettings} from '../../app-settings';
import {catchError} from 'rxjs/operators';

export interface LoginCookie{
  userName:string;
  saveCookie:boolean;
  password:string;
  cypheredPass:boolean;
};


@Component({
  selector: 'app-login-operator',
  templateUrl: './login-operator.component.html',
  styleUrls: ['./login-operator.component.css'],
  providers: [DbService]
})
export class LoginOperatorComponent implements OnInit {

  private loaded:boolean;
  private secret:string="hvYKsxz7wkZVVm6w";
  private cypheredPass:boolean=false;

  private titlePage:string="sostegno all'amministratore";
  constructor(private http:HttpClient, private dbService:DbService, private router:Router, private statusService:StatusService) { }

  ngOnInit() {
    this.readCookie();
    this.dbService.deleteOldProfiles();
    this.deleteOldPdf();
    this.loaded=false;
     setTimeout(()=>{
       this.loaded=true;
    },4500);
  }


  deleteOldPdf(){

    var json={};
     var headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
       return this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/deletePdf.php`,json,{headers:headers})
         //.map(res => res.json())
         .subscribe(data => console.log((data as any).msg));
    }


 public accessError(){

  	return this.accessE;

  }

logButton(){
  return "log in";
}
regButton(){
	return "registrati";
}

rememberMe(){
	return "ricordami";
}

lostPassword(){
	return "password dimenticata?";
}


onSubmit(){
  console.log("onsubmit clicked;");

  this.salvaCookie();


  this.logIn();

}
 accessE:string="errore";
  home:string="www.test.com";
activeAccount:boolean=false;

saveCookie:boolean=false;
success:boolean=true;
agent=null;
account?=null;

public user= new Username();


private alreadyIn(){
  return this.activeAccount;

}
private getSuccess(){
  return this.success;
}


  private   salvaCookie(){

   // this.syncCookie().subscribe((sync:LoginCookie)=>{

   /*   if(sync.cypheredPass!=undefined)
        this.cypheredPass=sync.cypheredPass;
      else
        this.cypheredPass=false;
*/


      console.warn("salva cookie chiamata:",this.user.userName,this.saveCookie);
      console.log(`this.cypheredPass vale ${this.cypheredPass}`)


      var data = {uName:this.user.userName,remember:this.saveCookie,password:this.user.password};

      var json=JSON.stringify(data);

      var params=json;


      var headers=new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
      return this.http.post(`${AppSettings.API_ENDPOINT}/php/writeCookie.php`,params,{headers:headers})
      //.map(res=>res.json())
      .pipe(catchError(error=>{return this.handleError(error);}))
     // .catch(this.handleError)
      .subscribe((data)=>console.log("ho salvato cookie",data));

  //  });

     }

     unsetCookie(){


      var data = {uName:'',remember:false,password:''};

      var json=JSON.stringify(data);

      var params=json;


      var headers=new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
      return this.http.post(`${AppSettings.API_ENDPOINT}/php/writeCookie.php`,params,{headers:headers})
      //.map(res=>res.json())
       .pipe(catchError(error=>{return this.handleError(error);}));
      //.catch(this.handleError)
      //.subscribe((data)=>console.log("unsetCookieCompleted"));

     }



    syncCookie(){

     var json=JSON.stringify(this.user);
      console.log("vorrei inserire: ",json);
      var params=json;

      var headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'  });
      return this.http.post(`${AppSettings.API_ENDPOINT}/php/readCookie.php`,params,{headers:headers})
     // .map(res=>res.json())
      .pipe(catchError(error=>{return this.handleError(error);}));



    }



   readCookie (){


    /* const body = new URLSearchParams();
    body.set('userName', this.user.userName);
    body.set('password', this.user.password);*/


     var json=JSON.stringify(this.user);
      console.log("vorrei inserire: ",json);
      var params=json;

      var headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'  });
      return this.http.post(`${AppSettings.API_ENDPOINT}/php/readCookie.php`,params,{headers:headers})
     // .map(res=>res.json())
      .pipe(catchError(error=>{return this.handleError(error);}))
      //.catch(this.handleError)
      .subscribe(
      (data) => this.setParams(data)
      );

    //insertUser
  }


  private setParams(p){
      this.user.userName=p.userName;
      this.user.password=p.password;
      this.saveCookie=(p.saveCookie==1);
      console.log("user:",this.user);
      console.log("saveCookie:",this.saveCookie);
     /* if(this.saveCookie){
        this.user.password="valida";
      }else
      {
        this.user.password="";
        this.user.userName="";
      } */

  }
  private handleError(error: Response) {
        console.error(error);
        this.accessE=error.statusText;
        console.log("loggo l'errore:",error);
        console.log("statusText: ",error.statusText);
        return Observable.throw(error);
    }



  private rememberMeClick(){

    setTimeout(()=>{
      if(this.saveCookie && this.user.password!=''){
        this.user.password=CryptoJS.AES.encrypt(this.user.password,this.secret).toString();
      } else if(this.user.password==''){
          this.saveCookie=false;
      }

     if(!this.saveCookie){
       this.unsetCookie().subscribe((data)=>{

          this.user=new Username();

       });
    }


    },400);


   }


private logIn(){




  this.accessE="log in chiamato";


       if(this.user.userName!='' && this.saveCookie){
         //this.logInFromCookie(this.cookieUserName);
         console.log("=>logInFromCookie: ");

         let _userLogin={userName:this.user.userName,password:CryptoJS.AES.decrypt(this.user.password, this.secret).toString(CryptoJS.enc.Utf8)};
         this.dbService.logInCookieOperator(_userLogin)
             /* .map(res =>{
           if(res.status==204)
             throw new Error("Utente/email inesistente");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



         })*/
              .subscribe(
              data => this.setAccount(data),
              err => console.error(err+"erroe da log inFromCookie")
              );



       }
       else {


        if(!this.alreadyIn()){
          console.warn("active account vale: ",this.activeAccount);

          if(this.user.userName=="" && this.user.password==""){
              this.user.userName="void";
              this.user.password="void";
          }

          this.dbService.logInOperator(this.user)
              /*.map(res =>{
           if(res.status==204)
             throw new Error("Utente/email inesistente");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



         })*/
              .subscribe(
              data => this.setAccount(data),
              err => { console.error("errore da login standard"); console.error(err);}
              );
           }else {
               console.log("già loggato, procedi entrando nell'app...");
           }
      }
  }


private execLogOut(){

  this.agent=null;
  this.success=false;
  console.warn("logOutCompleted");
}


private setAccount(datum){
  console.warn("set account chiamata, datum vale");
  console.log(datum);
  this.account=datum.data;

  if(datum.status=="Success"){
    console.log("sono su ramo success, account vale ",this.account);
      this.success=true;
      this.activeAccount=true;
      this.statusService.setToken(datum.data.token);
      this.statusService.updateSpecialAccount(this.account);
      this.statusService.setAccess(true);
      console.log("loggo idAccount da statusService: ",this.statusService.getSpecialId());

      this.router.navigate(['/dashboard']);
      //this.salvaCookie();

    }
   else if(datum.status=="Failure")
   {
     console.log("sono su ramo failure");
     this.success=false;
     this.activeAccount=false;
     this.accessE=datum.msg;
     // this.unsetCookie();
      //this.execLogOut();*/
   }

   console.log("token: ", datum.data.token);
   console.log("msg: ", datum.msg);
   console.log("datum.status: ",datum.status);
   console.log("da submit logIn: ",datum);



}












}
