import { Directive, Renderer2, ElementRef, forwardRef ,Output, EventEmitter} from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR, DefaultValueAccessor } from '@angular/forms';

const UPPERCASE_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UpperCaseDirective),
  multi: true,
};


@Directive({
  selector: '[ngModel][upper]',
  host:
  {
  	'(input)': 'onInput($event.target.value)',
    '(blur)': 'onTouched()'
   
},
providers: [NgModel,
    UPPERCASE_INPUT_CONTROL_VALUE_ACCESSOR,
  ],
})
export class UpperCaseDirective extends DefaultValueAccessor {
 @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  value: any;



constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef,false);
  }

  /*writeValue(value: any): void {
    const transformed = this.transformValue(value);

    super.writeValue(transformed);
  }*/

  onInput(value: any): void {
    const transformed = this.transformValue(value);

    super.writeValue(transformed);
    this.onChange(transformed);
    this.ngModelChange.emit(transformed);
   // this.model.valueAccessor.writeValue(transformed);
  }

  private transformValue(value: any): any {
   return  value.toUpperCase();

    
  }
}
