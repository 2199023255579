

import { Component, OnInit,ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {LangService} from '../../services/lang.service';
import {Ricorrente} from '../../models/ricorrente';
import {DbService} from '../../services/db.service';
import {Comune,Indirizzo,IndirizzoStrutturato} from '../../models/indirizzo';

@Component({
  selector: 'input-ricorrente-modale',
  templateUrl: './ricorrente-modale.component.html',
  styleUrls: ['./ricorrente-modale.component.css'],
   providers: [LangService,DbService]
})
export class RicorrenteModaleComponent implements OnInit {

 constructor(private langService:LangService, private dbService:DbService) { }

 @ViewChild('ricorrenteForm',{static:false}) form;
 @Input() n:number;
 @Input() ricorrente: Ricorrente;
 @Output() creaAccount = new EventEmitter();

  public comune:Comune;
  public indirizzo:Indirizzo;

 getComuneForRicorrente(){
  if(this.ricorrente.indirizzoStrutturato==undefined || this.ricorrente.indirizzoStrutturato== null)
    return {cap:'',comune:'',provincia:''};
  else return this.ricorrente.indirizzoStrutturato.comune;
}
getIndirizzoForRicorrente(){
   if(this.ricorrente.indirizzoStrutturato==undefined || this.ricorrente.indirizzoStrutturato == null)
    return {prefixTopo:'',denomTopo:'',civico:'',nazionalita:''};
  else return this.ricorrente.indirizzoStrutturato.indirizzo;
}



  ngOnInit() {
      if(this.ricorrente.indirizzoStrutturato!=undefined){

        this.comune={cap:'',comune:'',provincia:''};
        this.indirizzo={prefixTopo:'',denomTopo:'',civico:'',nazionalita:''};
    

    this.indirizzo.prefixTopo=this.ricorrente.indirizzoStrutturato.indirizzo.prefixTopo;
    this.indirizzo.denomTopo=this.ricorrente.indirizzoStrutturato.indirizzo.denomTopo;
    this.indirizzo.civico=this.ricorrente.indirizzoStrutturato.indirizzo.civico;
    this.comune.cap=this.ricorrente.indirizzoStrutturato.comune.cap;
    this.comune.comune=this.ricorrente.indirizzoStrutturato.comune.comune;
    this.comune.provincia=this.ricorrente.indirizzoStrutturato.comune.provincia;
    this.indirizzo.nazionalita=this.ricorrente.indirizzoStrutturato.indirizzo.nazionalita;
   } else {
     this.comune={cap:'',comune:'',provincia:''};
    this.indirizzo={prefixTopo:'',denomTopo:'',civico:'',nazionalita:''};


   }

  }

  
 copyAddress(){

   if(this.ricorrente.indirizzoStrutturato!=undefined){
     console.log('this.ricorrente.indirizzoStrutturato!=undefined');

     if(this.ricorrente.indirizzoStrutturato.comune.provincia!='' && this.ricorrente.indirizzoStrutturato.comune.provincia!=null)
        this.ricorrente.indirizzo=`${this.ricorrente.indirizzoStrutturato.indirizzo.prefixTopo} ${this.ricorrente.indirizzoStrutturato.indirizzo.denomTopo} ${this.ricorrente.indirizzoStrutturato.indirizzo.civico!=''?'n.':''} ${this.ricorrente.indirizzoStrutturato.indirizzo.civico}${this.ricorrente.indirizzoStrutturato.comune.cap!=''?',':''} ${this.ricorrente.indirizzoStrutturato.comune.cap} ${this.ricorrente.indirizzoStrutturato.comune.comune} (${this.ricorrente.indirizzoStrutturato.comune.provincia})`;
     else
          this.ricorrente.indirizzo=`${this.ricorrente.indirizzoStrutturato.indirizzo.prefixTopo} ${this.ricorrente.indirizzoStrutturato.indirizzo.denomTopo} ${this.ricorrente.indirizzoStrutturato.indirizzo.civico!=''?'n.':''} ${this.ricorrente.indirizzoStrutturato.indirizzo.civico}${this.ricorrente.indirizzoStrutturato.comune.cap!=''?',':''} ${this.ricorrente.indirizzoStrutturato.comune.cap} ${this.ricorrente.indirizzoStrutturato.comune.comune}`;
  
   } else {
     console.log('this.ricorrente.indirizzoStrutturato==undefined');

      if(this.comune.provincia!='' && this.comune.provincia!=null)
    

      this.ricorrente.indirizzo=`${this.indirizzo.prefixTopo} ${this.indirizzo.denomTopo} ${this.indirizzo.civico!=''?'n.':''} ${this.indirizzo.civico}${this.comune.cap!=''?',':''} ${this.comune.cap} ${this.comune.comune} (${this.comune.provincia})`;
       else
         this.ricorrente.indirizzo=`${this.indirizzo.prefixTopo} ${this.indirizzo.denomTopo} ${this.indirizzo.civico!=''?'n.':''} ${this.indirizzo.civico}${this.comune.cap!=''?',':''} ${this.comune.cap} ${this.comune.comune}`;
     

      this.ricorrente['indirizzoStrutturato']={indirizzo:{prefixTopo:this.indirizzo.prefixTopo,denomTopo:this.indirizzo.denomTopo,civico:this.indirizzo.civico,nazionalita:this.indirizzo.nazionalita},comune:{cap:this.comune.cap,comune:this.comune.comune,provincia:this.comune.provincia}};
   
   }
   
    console.log('copyAddress()')
    console.log( this.ricorrente.indirizzoStrutturato);
    
 }

 setComuneScelto(comune:Comune){
   console.log('alla setComune arriva:')
   console.log(comune);
   if(this.ricorrente.indirizzoStrutturato!=undefined){
    
       this.ricorrente.indirizzoStrutturato.comune.cap=comune.cap;
       this.ricorrente.indirizzoStrutturato.comune.comune=comune.comune;
       this.ricorrente.indirizzoStrutturato.comune.provincia=comune.provincia;
   } else {
      this.comune.cap=comune.cap;
      this.comune.comune=comune.comune;
      this.comune.provincia=comune.provincia;
   }
  
   console.log(`cap ${comune.cap} comune ${comune.comune} prov ${comune.provincia}`);
   this.copyAddress();
   }

 setIndirizzo(indirizzo:Indirizzo){
   console.log('alla setINdirizzo arriva')
   console.log(indirizzo);

    if(this.ricorrente.indirizzoStrutturato!=undefined){

       this.ricorrente.indirizzoStrutturato.indirizzo.prefixTopo=indirizzo.prefixTopo;
     this.ricorrente.indirizzoStrutturato.indirizzo.denomTopo=indirizzo.denomTopo;
     this.ricorrente.indirizzoStrutturato.indirizzo.civico=indirizzo.civico;
     this.ricorrente.indirizzoStrutturato.indirizzo.nazionalita=indirizzo.nazionalita;
    } else
    {
       this.indirizzo.prefixTopo=indirizzo.prefixTopo;
   this.indirizzo.denomTopo=indirizzo.denomTopo;
   this.indirizzo.civico=indirizzo.civico;
   this.indirizzo.nazionalita=indirizzo.nazionalita;

    }
  
   this.copyAddress();


 }

  isValid(){
    return this.form.valid;
  }
  
  sendForm(){
  	this.form.onSubmit();
  	if(this.isValid())
  		this.creaAccount.emit();
  }

 // ricorrente=new Ricorrente();
  LabelNome(){
  	return this.langService.nome();
  }  
  LabelGradoDiParentela(){
  	return this.langService.gradoDiParentela(this.ricorrente.relazione);
  }

 LabelCognome(){
 		return this.langService.cognome();
 }

 LabelDataDiNascita(){
 	return this.langService.dataDiNascita();
 }
 
LabelLuogoDiNascita(){
	return this.langService.luogoDiNascita();
}

LabelCodiceFiscale(){
	return this.langService.codiceFiscale();
}

LabelIndirizzo(){
	return this.langService.indirizzo();
}

PlaceholderIndirizzo(){
  return this.langService.indirizzoPlaceholder();
}

LabelTelefono(){
  	return this.langService.telefono();
  }

LabelCellulare(){
  	return this.langService.cellulare();
  }

LabelEmail(){
  	return this.langService.email();
  }
 LabelCandidato(){
 		return this.langService.candidato();
 }


}
