import { Pipe, PipeTransform } from '@angular/core';

export function filterfunction(searchText) {
    return (it) => {
        let found = false;
        let newData;
        if (it.dataCreazione != undefined)
            newData = convertDate(it.dataCreazione);
        if (it.data != undefined)
            newData = convertDate(it.data);

        if (it.cognomeBeneficiario != undefined)
            found = found || it.cognomeBeneficiario.toLowerCase().includes(searchText);

        if (it.kind != undefined) {
            found = found || it.kind.toLowerCase().includes(searchText);
        }

        if (it.beneficiario != undefined)
            found = found || (it.beneficiario.nome.toLowerCase().includes(searchText)
                || it.beneficiario.cognome.toLowerCase().includes(searchText)
                || newData.includes(searchText));

        if (it.cognome != undefined && it.nome != undefined) {
            found = found || (it.nome.toLowerCase().includes(searchText)
                || it.cognome.toLowerCase().includes(searchText)
                || newData.includes(searchText));
        }

        console.log('searchText is ');
        console.log(searchText);
        if(searchText!=undefined && searchText!=''){
            console.log('filter is returning '+found);
            return found;
        } else {
            console.log('filter is returning rigid true');
            return true;
        }
    };
}

export function convertMonth(month:string):string{
    if(month=="gennaio"){
        return "-01-";
    }
    if(month=="febbraio")
        return "-02-";
    if(month=="marzo")
        return "-03-";
    if(month=="aprile")
        return "-04-";
    if(month=="maggio")
        return "-05-";
    if(month=="giugno")
        return "-06-";
    if(month=="luglio")
        return "-07-";
    if(month=="agosto")
        return "-08-";
    if(month=="settembre")
        return "-09-";
    if(month=="ottobre")
        return "-10-";
    if(month=="novembre")
        return "-11-";
    if(month=="dicembre")
        return "-12-";
    return month;

}

export function convertDate(date:string):string{

    let splitDate=date.split("-");
    let month=splitDate[1];
    if(month=="01"){
        return splitDate[2]+" gennaio "+splitDate[0];
    }
    if(month=="02"){
        return splitDate[2]+" febbraio "+splitDate[0];
    }
    if(month=="03"){
        return splitDate[2]+" marzo "+splitDate[0];
    }
    if(month=="04"){
        return splitDate[2]+" aprile "+splitDate[0];
    }
    if(month=="05"){
        return splitDate[2]+" maggio "+splitDate[0];
    }
    if(month=="06"){
        return splitDate[2]+" giugno "+splitDate[0];
    }
    if(month=="07"){
        return splitDate[2]+" luglio "+splitDate[0];
    }
    if(month=="08"){
        return splitDate[2]+" agosto "+splitDate[0];
    }
    if(month=="09"){
        return splitDate[2]+" settembre "+splitDate[0];
    }
    if(month=="10"){
        return splitDate[2]+" ottobre "+splitDate[0];
    }
    if(month=="11"){
        return splitDate[2]+" novembre "+splitDate[0];
    }
    if(month=="12"){
        return splitDate[2]+" dicembre "+splitDate[0];
    }
    return date;

}

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {







  transform(items: any[], searchText: string): any[] {
        if(!items) return [];
        if(!searchText || searchText == undefined) return items;

        searchText = searchText.toLowerCase();
        return items.filter(filterfunction(searchText));
       }
}
