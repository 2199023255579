import { Directive, ElementRef, HostListener, Input, Renderer2,Attribute,forwardRef } from '@angular/core';
import {NgControl,Validator, AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors, AsyncValidator} from '@angular/forms';
import {DbService} from '../services/db.service';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map,debounceTime} from 'rxjs/operators';



 interface ValidationResult {
       [key: string]: boolean;
  }
  
 export class localUser {
     email:string;
    
 }


@Directive({
  selector: '[uniqueEmail]',
  providers: [
        { provide: NG_ASYNC_VALIDATORS,useExisting: forwardRef(() => UniqueEmailDirective), multi: true }
    ]
})

export class UniqueEmailDirective implements AsyncValidator {

constructor(private dbService:DbService,private el: ElementRef, private renderer: Renderer2) { }

validate(ctrl:AbstractControl):Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
      return  this.dbService.findAccount(ctrl.value).pipe(
        debounceTime(4000),
         map(res  =>{console.log(res);

           if(res!=null)

             return {taken:true};
         
           if(res==null)
             return null;

           }));
       /*	.map(res => {
          if(res.status==204){
           		 throw new Error("email inesistente");
           
            
          }
          if(res.status==406){
               throw new Error("errore di rete");
             
          }
          if(res.status==200)
          	res.json();
            

        })
        .subscribe(
          data =>  {
              console.log('directive uniqueEmail sono sul ramo data=> taken:true');
           resolve({ taken: true })},
           error => {
              console.log('directive uniqueEmail sono sul ramo error => resolve(null)');
            resolve(null); }
          );



   
    });*/
  }

  private processUniqueEmail(data){
  	this.highlight('red');
  	alert("profilo già registrato");

  }

 private highlight(color: string) {
    this.renderer.setStyle(this.el.nativeElement, 'backgroundColor', color);
  }

}


