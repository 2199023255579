import { Component, OnInit } from '@angular/core';


import { HttpClient,HttpHeaders, HttpResponse } from '@angular/common/http';


import {DbService} from '../../services/db.service';
import {map,catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {LangService} from '../../services/lang.service';
import 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {AppSettings} from '../../app-settings';


@Component({
  selector: 'app-accountactivation',
  templateUrl: './accountactivation.component.html',
  styleUrls: ['./accountactivation.component.css'],
  providers: [DbService,LangService]
})
export class AccountactivationComponent implements OnInit {

 
  constructor(private route: ActivatedRoute,private http:HttpClient,private dbService:DbService, private langService:LangService) { }

  ngOnInit() {
  	 let email=this.route.snapshot.params['email'];
  	 let date=this.route.snapshot.params['date'];
     console.log("mail cifrata: ",email,"date cifrata: ",date);
  	this.undecodePassword();
  
  }
 undecodePassword(){


      var data = {agentID:this.route.snapshot.params['email'],data:this.route.snapshot.params['date']};

      var json=JSON.stringify(data);
       
      var params=json;


      var headers=new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
      return this.http.post(`${AppSettings.API_ENDPOINT}/php/undecodeParams.php`,params,{headers:headers})
     // .map(res=>res.json())
      .pipe(catchError(e => this.handleError(e)))
      .subscribe(data=>this.resetPassword(data));

     }

  

     backToLogIn(){
       return this.langService.backToLogIn();
     }

     resetPassword(user){

       console.log("account activation()");
       console.log(user);

       console.log("sto resettando la password");
        
          
      
            this.dbService.activateAccount(user)
              /*.map(res => {
                console.log(res);

                  if(res.status==204)
                     throw new Error("Utente/email inesistente");

                   if(res.status==406)
                      throw new Error("errore di rete");

                   if(res.status==200) 
                     return res.json();

           
           })*/
                .subscribe(
          data => this.displayConfirm(data),
          err => this.displayConfirm(err)
          );

}
       

  public displayConfirm(msg){
    alert(msg.status+' '+msg.msg);
  }


      private handleError(error: HttpResponse<any>) {
        console.error(error);
       
        return Observable.throw(error);
    }

}
