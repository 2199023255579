import {Injectable} from '@angular/core';
import {Ricorrente} from '../models/ricorrente';
import {Beneficiario} from '../models/beneficiario';
import {Anagrafica} from '../models/anagrafica';


@Injectable()


export class DataIdoneita {


	public ricorrenti:Ricorrente[];
	public idx:number;
    public idxProposta:number;
	public beneficiario:Beneficiario;
    public proposteNominativi:Anagrafica[];
    public intestazione:string;


	constructor(bene:Beneficiario=new Beneficiario(),ric:Ricorrente[]=[new Ricorrente(),new Ricorrente(),new Ricorrente(),new Ricorrente(),new Ricorrente(), new Ricorrente()],proposte: Anagrafica[] = [new Anagrafica()]
 ){
		this.beneficiario=bene;
		this.ricorrenti=ric;
        this.proposteNominativi=proposte;
		this.idx=0;
        this.idxProposta = 0;
        this.intestazione = '';


	}
}
