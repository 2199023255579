import { Injectable } from '@angular/core';


export enum DeleteRequestType {
    PROPOSTA_NOMINATIVO,
    CARICAMENTO
}

@Injectable()


export class actionsBeneficiando {
	description:string;
	value:string;
}


/* le more erano inizializzate così, poi ora uso il campo more per le note finali */
/* Chiede, infine, che nelle more dell’istruttoria la persona sopra indicata sia nominata, con provvedimento immediato efficace, amministratore di sostegno provvisorio al fine di compiere i seguenti atti:  */

export class Ricorso {

	public codiceOggetto:string;
	public residenzaEffettiva:string;
	public residenzaAlt:string;
	public trasporto:string;
	public ragioni:string;
	public altreMansioni:string;
	public sedeTrasporto:string;
	public more:string;
	public note:string;
	public nRicorrenti:number;
	public bisogni:string;
	public durata:number;
	public durataValue:string;
	public volontaAccordo:boolean;
	public volontaRifiuta:boolean;
	public azioni:actionsBeneficiando[];
	public draft:boolean;


	constructor(codiceOggetto:string="", residenzaEffettiva:string="", residenzaAlt:string="", trasporto:string="", ragioni:string="",  altreMansioni:string="",sedeTrasporto:string="la sede del tribunale",more:string="",note:string="", ric:number=1,bisogni:string="", durata:number=0,durataValue:string="per la durata di ", volontaRifiuta:boolean=false,volontaAccordo:boolean=false, azioni:actionsBeneficiando[]=[], draft:boolean=false){
		this.codiceOggetto=codiceOggetto;
		this.residenzaEffettiva=residenzaEffettiva;
		this.residenzaAlt=residenzaAlt;
		this.trasporto=trasporto;
		this.ragioni=ragioni;
		this.sedeTrasporto=sedeTrasporto;
		this.altreMansioni=altreMansioni;
		this.nRicorrenti=ric;
		this.more=more;
		this.note=note;
		this.bisogni=bisogni;
		this.durata=durata;
		this.durataValue=durataValue;
		this.volontaAccordo=volontaAccordo;
		this.volontaRifiuta=volontaRifiuta;
		this.azioni=azioni;
		this.draft=draft;

	}

}
