import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import {StatusService} from '../../services/status.service';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css']
})
	
export class IconComponent implements OnInit {

	@Input() kind:string;
	@Input() size:string;
  @Input() where:string;
  @Input() whereOpt2:string;
  @Input() disabled?:boolean;
  @Input() id:string;
  @Output() action=new EventEmitter();


	


  lanciaClick(){
    this.action.emit();
  }



  constructor(public statusService:StatusService) { }

  ngOnInit() {

   

    if(this.id=="idBene"){
        this.disabled=!this.statusService.getAccount().completeAds;
     //   console.log("chiamato get account(), che vale : ",this.statusService.getAccount());

    }
    if(this.id=="idAds"){
   //   console.log("idAds ed è ");
      this.disabled=(!this.statusService.getLoadedAccount() && (this.statusService.getSpecialAccount().kind=='opr' || this.statusService.getSpecialAccount().kind=='adm')) ;
    //  console.log(this.disabled);
    }

    if(this.id=="idList"){
        this.disabled=!this.statusService.getAccount().completeBene || !this.statusService.availableBeneficiario();

    }
   // console.log("chiamato get account(), che vale : ",this.statusService.getAccount());


    //console.log(this.id," ", this.disabled);
  }

  getDisabled(){
     if(this.id=="idBene"){
        this.disabled=!this.statusService.getAccount().completeAds;
     //   console.log("chiamato get account(), che vale : ",this.statusService.getAccount());

    }
    if(this.id=="idAds"){
   //   console.log("idAds ed è ");
      this.disabled=(!this.statusService.getLoadedAccount() && (this.statusService.getSpecialAccount().kind=='opr' || this.statusService.getSpecialAccount().kind=='adm')) ;
    //  console.log(this.disabled);
    }

    if(this.id=="idList"){
        this.disabled=!this.statusService.getAccount().completeBene || !this.statusService.availableBeneficiario();

    }

    return this.disabled;
  }

}
