import {Injectable} from '@angular/core';

@Injectable()


export class DataBuyCar {

	public data:string;
	public modello:string;
	public importo:string;
	public print:boolean;
	public view:boolean;

	constructor(data:string="",m:string="",val:string="",print:boolean=false,view:boolean=false){
		this.data=data;
		this.modello=m;
		this.importo=val;
		this.print=print;
		this.view=view;

	}
}
