import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'draft-button',
  templateUrl: './draft-button.component.html',
  styleUrls: ['./draft-button.component.scss']
})
export class DraftButtonComponent implements OnInit {

  constructor() { }


  @Input() isDraft:boolean;

  @Input() iconClass:string;
  @Input() viewRelatives:boolean;

  @Output() updateDraft:EventEmitter<boolean>= new EventEmitter<boolean>();
  @Output() changePage:EventEmitter<boolean>= new EventEmitter<boolean>();

  ngOnInit() {
  }

  changeState(){
  	console.log(`changeState isDraft vale ${this.isDraft}`);
  	this.isDraft=!this.isDraft;
  console.log(`changeState isDraft dopo vale ${this.isDraft}`);
  this.updateDraft.emit(this.isDraft);
  }


   emitState(){

     setTimeout(()=>{
        this.updateDraft.emit(this.isDraft);
     },500)
     
   }

  fireEvent(){
    console.log('fire event click');
    this.changePage.emit();
    console.log("changePage emitted");
  }



}
