import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import {KindDocument} from '../../kind-document';
import {StatusService} from '../../services/status.service';
import {DbService} from '../../services/db.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'documentIcon',
  templateUrl: './document-icon.component.html',
  styleUrls: ['./document-icon.component.css'],
  providers:[DbService]
})
export class DocumentIconComponent implements OnInit {

	@Input() kind:string;
	@Input() kindDoc:KindDocument;
	@Input() size:string;
 	@Input() inputWhere:string;
 	@Input() printWhere:string;
    @Input() disabled:boolean;
    @Output() clickEmitter: EventEmitter<boolean> = new EventEmitter();

 	public showWhere:string;


 	//@Output() onSetting=new EventEmitter<WrapDocument>();

 	//private _d:WrapDocument;


  constructor(public statusService:StatusService,public dbService:DbService, public http:HttpClient) {


  		/*switch (this.kindDoc) {
  			case KindDocument.SELL_IMM:
  				this._d=new WrapDocument(this.kindDoc, new Documents());

  				break;

  			default:

  				break;
  		}*/

  }

  ngOnInit() {
  }

  handleClick(){
      if(!this.disabled){
          this.clickEmitter.emit(true)
      }
  }

  /*setDoc(){
  	this.onSetting.emit(this._d);

  }*/

}
