import { Component, OnInit } from '@angular/core';
import {DocumentIconComponent} from '../document-icon/document-icon.component';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {AppSettings} from '../../app-settings';
import {DbService} from '../../services/db.service'; 
import {StatusService} from '../../services/status.service';

@Component({
  selector: 'documentIconAuth',
  templateUrl: './document-icon-auth.component.html',
  styleUrls: ['./document-icon-auth.component.css'],
  providers: [DbService]
})
export class DocumentIconAuthComponent extends DocumentIconComponent {

 

}
