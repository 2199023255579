import { Injectable } from '@angular/core';
import {Anagrafica} from './anagrafica';
// import {IndirizzoStrutturato} from './indirizzo'; // Luke 17-01-2021

@Injectable()
export class Beneficiario extends Anagrafica{


	constructor(public idBeneficiario:number=0,public nome:string="",public cognome:string="",public dataDiNascita:string="",public codiceFiscale:string="", public luogoDiNascita:string="",public indirizzo:string="",public sesso:string="",public grado:string="" /* Luke 17-01-2021: added the following -> ,public professione:string="", public telefono:string="", public cellulare:string="",public email:string="", public indirizzoStrutturato:IndirizzoStrutturato=new IndirizzoStrutturato() */){
		super(nome,cognome,dataDiNascita,codiceFiscale,luogoDiNascita,indirizzo,sesso);
  }


}
