
import {LangService} from '../../services/lang.service';
import {DbService} from '../../services/db.service';

import { Component, ViewChild, OnInit,Input,Output, EventEmitter,ElementRef,AfterViewInit} from '@angular/core';
import { debounceTime, map,tap,filter,switchMap } from 'rxjs/operators';
import {fromEvent,timer,of} from 'rxjs';
import {Resp} from '../../models/resp';
import {Comune,Indirizzo,STATO} from '../../models/indirizzo';



@Component({
  selector: 'comune',
  templateUrl: './comune.component.html',
  styleUrls: ['./comune.component.scss']
})
export class ComuneComponent implements OnInit, AfterViewInit {

    public listaComuni:Comune[]=[];

    @Input() found:Comune;

    @Input() address:Indirizzo;

    @Input() offset:boolean;
    @Output() comuneScelto=new EventEmitter<Comune>();
    @Output() indirizzoCompleto=new EventEmitter<Indirizzo>();


    @ViewChild('comuneForm',{static:false}) form;
    @ViewChild('search',{static:true}) search : ElementRef;
    @ViewChild('prefixTopo',{static:true}) prefixTopo : ElementRef;
    @ViewChild('denomTopo',{static:true}) denomTopo : ElementRef;
    @ViewChild('civico',{static:true}) civico : ElementRef;
    @ViewChild('nazione',{static:true}) nazione : ElementRef;

   public prefix:string[]=[];

   public readonly EMPTY_PREFIX_POSITION = 10;

   public STATO=STATO;

   nazionalita() : Array<string> {
        const keys = Object.keys(this.STATO);
     //   return keys.slice(keys.length / 2);
        return keys;
    }

  constructor(public langService:LangService, public dbService:DbService) { }






    ngOnInit() {
        this.listaComuni=[];
        //this.address={prefixTopo:'',denomTopo:'',civico:'',nazionalita:''};
        //this.found={cap:'',comune:'',provincia:''};
        this.prefix=['Via','Viale','Vicolo','Largo','Piazza','Piazzale','Piazzetta','Calle','Campo','SCONOSCIUTO',''];

  	/*this.form.vauleChanges.pipe(debounceTime(700)).subscribe(data=>{
  		console.log(`ho scritto ${data}`);
  	}) */



    }


    getIndirizzo(){
        this.address.prefixTopo=this.prefixTopo.nativeElement.value;
        this.address.nazionalita=this.nazione.nativeElement.value;
        this.indirizzoCompleto.emit(this.address);
    }

    getComune(i){
        console.log(`getComune riga 78`);
        if(i != -1 ) {
            this.comuneScelto.emit(this.listaComuni[i]);
            this.found.cap = this.listaComuni[i].cap;
            this.found.comune = this.listaComuni[i].comune;
            this.found.provincia = this.listaComuni[i].provincia;
            this.search.nativeElement.value = this.listaComuni[i].cap;
            timer(700).subscribe(t => {
                this.listaComuni = null;
            });
        }
        if(i == -1){
            const emptyComune = {cap:'',comune:'',provincia:''};
            console.log(`getComune emette`);
            console.log(emptyComune);
            this.comuneScelto.emit(emptyComune);
            this.found.cap='';
            this.found.comune='';
            this.found.provincia='';
            this.search.nativeElement.value='';

        }
    }

    detectEstero(){
        console.log(`detectEstero change`);
        if(this.address.nazionalita == 'ESTERO'){
            this.address.prefixTopo = this.prefix[this.EMPTY_PREFIX_POSITION];
        }
    }

  ngAfterViewInit(){

    fromEvent(this.civico.nativeElement,'keyup').pipe(
                    debounceTime(900),
                    //tap(e => console.log(e)),

                    map((event : any) =>event.target.value ),
                    ).subscribe(res  =>{
                      this.address.civico=res;

                      this.getIndirizzo();

                  } );



     fromEvent(this.denomTopo.nativeElement,'keyup').pipe(
                    debounceTime(900),
                    //tap(e => console.log(e)),

                    map((event : any) =>event.target.value ),
                    ).subscribe(res  =>{
                      this.address.denomTopo=res;
                      this.getIndirizzo();

                  } );


     fromEvent(this.prefixTopo.nativeElement,'change').pipe(
                    //debounceTime(900),
                    tap(e => console.log(e)),

                    map((event : any) =>event.target.value ),
                    ).subscribe(res  =>{
                      this.address.prefixTopo=res;
                      this.getIndirizzo();
                  } );



     fromEvent(this.nazione.nativeElement,'click').pipe(
                    debounceTime(900),
                    tap(e => console.log(e)),

                    map((event : any) =>event.target.value ),
                    ).subscribe(res  =>{
                      this.address.nazionalita=res;
                      this.getIndirizzo();
                  } );




    //search cap comune
    fromEvent(this.search.nativeElement,'keyup').pipe(
                    debounceTime(700),
                    tap(e => {this.listaComuni=[];}),

                    map((event : any) =>event.target.value ),
                    switchMap(toFind=>{
                        if(toFind!='') {
                            return this.dbService.getListaComuni(toFind);
                        } else {
                            this.getComune(-1);
                            return of(new Resp());
                        }
                    })).subscribe(res  =>{

                    console.log('lista comuni:');
                    console.log((res as Resp).data);

                    this.listaComuni=(res as Resp).data;
                  } );
  }
  CapComune(){
  	return this.langService.CapComune();
  }

  LabelSearch(){
  	return this.langService.LabelSearch();
  }

}
