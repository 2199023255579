import { Component, OnInit, OnDestroy,ViewChild } from '@angular/core';
import {StatusService} from '../../services/status.service';
import {DbService} from '../../services/db.service';
import {Router} from '@angular/router';
import {Observable,throwError} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {filterfunction, FilterPipe} from '../../filter.pipe';
import {catchError, map} from 'rxjs/operators';

import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';

import { from } from 'rxjs';

import { ElementRef, AfterViewInit } from '@angular/core';
import {fromEvent, Subscription, of,timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, filter,switchMap } from 'rxjs/operators';
import {Resp} from '../../models/resp';
import {Comune} from '../../models/indirizzo';
import {DataEsportaAnagrafiche, DataEsportaAnagraficheAds} from '../../data/data-esporta-anagrafiche';
import {ConvertInterfaceToDict, CsvType} from '../../interfaces/csv-type';
import {Anagrafica} from '../../models/anagrafica';
import {Ricorrente} from '../../models/ricorrente';
import {Beneficiario} from '../../models/beneficiario';


@Component({
  selector: 'app-view-ads',
  templateUrl: './view-ads.component.html',
  styleUrls: ['./view-ads.component.css'],
    providers:[DbService]
})
export class ViewAdsComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(private router:Router, private statusService:StatusService, private dbService:DbService) { }

    public searchText:string;
    public searchSub:Subscription;
    public index:number;
    public idAccountToDelete:number;
    public idAccountStatoNomina:number;

    public loadingResult: boolean;
    public motivoCessazione:number;
    public filterStatoNomina:string;

    public currentYear: string;
    public yearList: string[];

    public foundComune:string;
    public listaComuni:Comune[]=[];
    public found:Comune;

    private list;
    private account;
    private idSportello;
    public ascAds:boolean;
    public ascData:boolean;

    // modal params
    cssClass = '';
    animation = true;
    keyboard = true;
    backdrop: string | boolean = true;

    public esportaAnagraficheSelection:DataEsportaAnagraficheAds;
    public refCsvObject : ConvertInterfaceToDict<CsvType>;


    @ViewChild('deleteModal',{static:false})  deleteModal: BsModalComponent;
    @ViewChild('statoNominaModal',{static:false})  statoNominaModal: BsModalComponent;

    @ViewChild('input', {static:false}) input: ElementRef;
    @ViewChild('searchComune',{static:true}) search : ElementRef;
    @ViewChild('esportaAnagraficheModal',{static:false}) esportaAnagraficheModal: BsModalComponent;

    closedEsportaAnagraficheModal(){}
    openedEsportaAnagraficheModal(){}
    dismissedEsportaAnagraficheModal(){
        this.esportaAnagraficheModal.dismiss();
    }



    checkAccess(){
    this.statusService.getAccess().subscribe(data=>{
      console.log(`checAccess ha ricevuto ${data}`);
      if(!data)  this.router.navigate(['']);


    });



}



    setModaleDelete(index,idAccount){
       console.warn('click per apertura modale');
       this.index=index;
       this.idAccountToDelete=idAccount;

       this.deleteModal.open();
    }
    setModaleStatoNomina(index,idAccount,statoNomina){
        this.index=index;
        this.idAccountStatoNomina = idAccount;
        this.motivoCessazione=statoNomina;
        this.statoNominaModal.open();
        //tofinish
    }

    updateStatoNomina(){
        if(this.motivoCessazione >= 0 && this.motivoCessazione <=3){
            this.dbService.updateStatoNominaAccount(this.idAccountStatoNomina,this.motivoCessazione).subscribe(
                ()=>{
                this.statoNominaModal.dismiss();
                this.updateAdsList();
                }
                );
        }
    }

    closed() {

    }

    dismissed() {

    }

    opened() {

    }


    open() {
        this.deleteModal.open();
    }

    salvaModale(){
       //this.deleteDoc(this.numericId);
       console.warn(`cancello il account ADS n. ${this.index} con id ${this.idAccountToDelete}`);
       this.deleteProfile(this.idAccountToDelete);
       this.deleteModal.close();
    }







  ngOnInit() {
        this.refCsvObject = {};
        this.esportaAnagraficheSelection = new DataEsportaAnagraficheAds();
        this.loadingResult = true;
  		this.list=[];
        this.ascAds=true;
        this.ascData=true;
        this.checkAccess();

        this.filterStatoNomina='*';
        //recuper account operatore o admin (SpecialAccount)
     this.account=this.statusService.getSpecialAccount();

  	  this.idSportello=this.statusService.getIdSportello();
    if(this.idSportello!=0){

        /* carico l'elenco delgli anni */
        this.dbService.getAdsListYearList(this.idSportello)
            .pipe(catchError(error => this.handleError(error)))
            .subscribe(data => this.setMaximumYear((data as Resp).data));

      this.dbService.loadWindowAdsList(this.idSportello)
      .subscribe(data =>{
        console.log('ngOnInit loadWindowAdsList gets');
        console.log(data);
       if(data!=null)
          this.logList(this.list=(data as any).data);
       else this.logList(this.list=[]);
     });

    }
  }
    setMaximumYear(data) {
        console.log('setMaximumYear data vale for adsa creation date');
        console.log(data);
        if (data != undefined) {
            this.yearList = data;

            let max = parseInt(data[0],10);
            for (let i = 1; i < data.length; i++) {
                if (parseInt(data[i],10) > max) {
                    max = parseInt(data[i],10);
                }
            }

            console.log(`anno max ${max}`);
            this.currentYear = '*';
            this.yearList.push('*');
        }

    }

    updateAdsList(){
        this.loadingResult = true;
        if (this.idSportello != 0) {
            this.dbService.loadWindowAdsList(this.idSportello,this.currentYear,(this.foundComune != '' ? this.foundComune: undefined),this.filterStatoNomina)
                .subscribe(data =>{
                    if(data!=null)
                        this.logList(this.list=(data as any).data);
                    else this.logList(this.list=[]);
                });
        }
    }

    ngOnDestroy(){
        this.searchSub.unsubscribe();
    }

    ngAfterViewInit() {
            // server-side search
        this.searchSub=fromEvent(this.input.nativeElement,'keyup')
            .pipe(
                filter(Boolean),
                debounceTime(450),
                distinctUntilChanged(),
                tap((text) => {
                  console.log(this.input.nativeElement.value);
                  this.searchText=this.input.nativeElement.value;
                })
            )
            .subscribe();

        //search cap comune
        fromEvent(this.search.nativeElement,'keyup').pipe(
            debounceTime(700),
            tap(e => {this.listaComuni=[];}),

            map((event : any) =>event.target.value ),
            switchMap(toFind=>{
                if(toFind!='') {
                    return this.dbService.getListaComuni(toFind);
                } else {
                    this.getComune(-1);
                    return of(new Resp());
                }
            })).subscribe(res  =>{

            console.log('lista comuni:');
            console.log((res as Resp).data);

            this.listaComuni=(res as Resp).data;
        } );


  }

    logList(list){
        this.loadingResult = false;
        console.log(list);
    }

    getComune(i){
     console.log(` getComune i vale ${i}`);
     console.log(`this.listaComuni[${i}] vale`);
     console.log(this.listaComuni[i]);
        const emptyComune = {cap:'',comune:'',provincia:''};
        if(i != -1 ) {
            this.found = emptyComune;
            //this.comuneScelto.emit(this.listaComuni[i]);
            this.found.cap = this.listaComuni[i].cap;
            this.found.comune = this.listaComuni[i].comune;
            this.foundComune = this.found.comune;
            this.found.provincia = this.listaComuni[i].provincia;
            this.search.nativeElement.value = this.listaComuni[i].comune;
            this.updateAdsList();
            timer(700).subscribe(t => {
                this.listaComuni = null;
            });
        }
        if(i == -1){
            this.found = emptyComune;
            this.foundComune = undefined;
            this.search.nativeElement.value='';

        }
    }

   handleError(error:Response){
    console.log('loggo l\'errore:',error);
        console.log('statusText: ',error.statusText);
        this.list=[];
        return Observable.throw(error);
  }


  sortSurname(ascParam){
    console.log('sortSurname account vale');
    console.log(this.account);

     this.ascAds=!ascParam;



   /*

RXJS 6, dovrei aggionare angular e tutto il resto...
   fromEvent(event).pipe(
      debounceTime(2500),
      switchMap(e => this.dbService.loadWindowArchiveListOrdered(this.idSportello,'surname',this.ascBene)
     ),
      tap(e => console.log(e)),
      map(res=>res.json()),
      catch(this.handleError)

      ).subscribe(data =>this.logList(this.list=(data as any).data));
*/



    if(this.account.kind=='opr'){
    console.warn('sono un operatore');
    this.idSportello=this.statusService.getIdSportello();
    console.warn('idSportello: ',this.idSportello);
    if(this.idSportello!=0){
        console.warn('idSpertelo != 0');
      this.dbService.loadWindowAdsOrderedList(this.idSportello,'surname',this.ascAds)
      //.map(res=>res.json())
        .pipe(catchError(error=> this.handleError(error)))
      .subscribe(data =>{

       if(data!=null)
          this.logList(this.list=(data as any).data);
       else this.logList(this.list=[]);
     });

    }
   }


   if(this.account.kind=='adm'){
     console.warn('sono admin');

      this.dbService.loadAdsOrderedList('surname',this.ascAds)
      //.map(res=>res.json())
      .pipe(catchError(error=> this.handleError(error)))
      .subscribe(data =>{

       if(data!=null)
          this.logList(this.list=(data as any).data);
       else this.logList(this.list=[]);
     });


    }

  }


  sortCreationDate(ascParam){
    this.ascData=!ascParam;
     if(this.account.kind=='opr'){
    console.warn('sono un operatore');
    this.idSportello=this.statusService.getIdSportello();
    console.warn('idSportello: ',this.idSportello);
    if(this.idSportello!=0){
        console.warn('idSpertelo != 0');
      this.dbService.loadWindowAdsOrderedList(this.idSportello,'creationDate',this.ascData)
      //.map(res=>res.json())
      .pipe(catchError(error=> this.handleError(error)))
      .subscribe(data =>{

       if(data!=null)
          this.logList(this.list=(data as any).data);
       else this.logList(this.list=[]);
     });

    }
   }
   if(this.account.kind=='adm'){
     console.warn('sono admin');
    //if(this.idSportello==0){
      this.dbService.loadAdsOrderedList('creationDate',this.ascData)
      //.map(res=>res.json())
       .pipe(catchError(error=> this.handleError(error)))

      .subscribe(data =>{

       if(data!=null)
          this.logList(this.list=(data as any).data);
       else this.logList(this.list=[]);
     });

     //}
    }

  }



  /* carico account selezionato dalla lista */

  loadProfile(idAccount:number){

    console.log('loadProfile: id account = '+idAccount);

    this.idSportello=this.statusService.getIdSportello();
    console.log('id sportello vale: ',this.idSportello);

    if(this.idSportello!=0){
      console.log('id sportello !=0 quindi  sono un operatore');
      this.dbService.getAccountFromId(idAccount)
       /*.map(res =>{
           if(res.status==204)
             throw new Error("Utente/email inesistente");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



         })*/
              .subscribe(
              data =>  this.configDoc((data as any).data),
              err => console.error(err+'errore da login standard')
              );
    }
  }


  configDoc(account){
    this.statusService.setLoadedAccount(account);
    console.log('configDoc: data from getAccountFromId:');
    console.log(account);

     this.dbService.getAmministratore(this.statusService.getId())
      //.map(res => res.json())
      .subscribe( data => {

        console.log('sono la config doc ho ricevuto dal db, caricando l\'amministratore, ');
        console.log(data);
        this.statusService.updateSurname((data as any).data.cognome);

      });

    alert('profilo caricato con successo!');
     this.router.navigate(['/dashboard']);
     console.warn('profilo caricto con successo!');

  }
/* fine area di cricamento */

/* completa cancellazione profilo account ads */

deleteProfile(idAccount:number){

  this.dbService.getAmministratore(idAccount)
     // .map(res => res.json())
      .subscribe( data => this.setAds((data as any).data,idAccount));

}

  setAds(a,idAccount:number){

    //2
    const ads=a;
    const idBeneficiario=ads.idBeneficiario;
    console.log('cancellazione di ', ads);

    const idBeneficiarioArray=this.statusService.getIdBeneficiarioArray();


    /* idBeneficiarioArray.forEach((localIdBenef)=> {
      if(localIdBenef!=null && localIdBenef!=-1)
        this.dbService.delBeneficiario(localIdBenef).subscribe(()=>{});
    }); */


    from(this.statusService.getIdBeneficiarioArray()).subscribe(
      localIdBenef =>  this.dbService.delBeneficiario(localIdBenef).subscribe(()=>{}),
      error => {},
      () => {setTimeout(()=>{
         this.dbService.delAmministratore(idAccount).subscribe(data => this.delAdsFromWindow(data,idAccount));
      },1500);}
      );







  }




  delAmm(data,idAccount){
    console.log(data);
     this.dbService.delAmministratore(idAccount)
      //.map(res => res.json())
      .subscribe(res => this.delAdsFromWindow(res,idAccount));


  }


delAdsFromWindow(data,idAccount){
  console.log(data);
   this.dbService.delAdsFromWindow(idAccount)
      //.map(res => res.json())
      .subscribe(res => this.delDocumentiSalvati(res,idAccount));



}

delDocumentiSalvati(data,idAccount){
  console.log(data);
   this.dbService.delDocumentiSalvati(idAccount)
      //.map(res => res.json())
      .subscribe(res => this.delAccount(res,idAccount));



}

delAccount(data,idAccount){
  console.log(data);

 this.dbService.delAccount(idAccount)
      //.map(res => res.json())
      .subscribe(res => this.updateList(res));




}



updateList(data){
  console.log(data);
   if(this.idSportello!=0){

     timer(1500).pipe(
       switchMap(t=>{
         return this.dbService.loadWindowAdsList(this.idSportello);

       })).subscribe(res => {

       if(res!=null)
          this.logList(this.list=(res as any).data);
       else this.logList(this.list=[]);
     });


    }

    this.statusService.downloadAccount();
    alert('account cancellato con successo!');
}

/* fien cancellazione */

    openModaleEsporta(){
        this.esportaAnagraficheModal.open();
    }

    sendFormEsportaAnagraficheModal(esportaAnagraficheSelectionAds:DataEsportaAnagraficheAds){

            console.log('sendFormEsportaAnagraficheModal');
            console.log(esportaAnagraficheSelectionAds);

            this.refCsvObject = {};
            let csv='';
            csv+=(esportaAnagraficheSelectionAds.nomeCognome ? 'NOME;COGNOME;SESSO;':'')+(esportaAnagraficheSelectionAds.email? 'EMAIL;RUOLO;':'')+(esportaAnagraficheSelectionAds.completa ? 'TELEFONO;CELLULARE;INDIRIZZO;DATA_DI_NASCITA;LUOGO_DI_NASCITA;CF;DATA_CREAZIONE;':'')+'\n';

            console.log('list is');
            console.log(this.list);
            for(let anagrafica of this.list.filter(filterfunction(this.searchText))){
                console.log(anagrafica);
                if(esportaAnagraficheSelectionAds.nomeCognome) {
                    csv += (anagrafica.nome != undefined ? anagrafica.nome : '') + ';';
                    csv += (anagrafica.cognome != undefined ? anagrafica.cognome : '') + ';';
                    csv += (anagrafica.sesso != undefined ? anagrafica.sesso : '') + ';';
                }
                if(esportaAnagraficheSelectionAds.email)
                {
                    csv += (anagrafica.email != undefined ? anagrafica.email : '') + ';';
                    csv += (anagrafica.kind != undefined ? anagrafica.kind : '') + ';';
                }
                if(esportaAnagraficheSelectionAds.completa) {
                    csv += (anagrafica.telefono != undefined ? anagrafica.telefono : '') + ';';
                    csv += (anagrafica.cellulare != undefined ? anagrafica.cellulare : '') + ';';
                    csv += (anagrafica.indirizzo != undefined ? anagrafica.indirizzo.trim() : '') + ';';
                    csv += (anagrafica.dataDiNascita != undefined ? anagrafica.dataDiNascita : '') + ';';
                    csv += (anagrafica.luogoDiNascita != undefined ? anagrafica.luogoDiNascita : '') + ';';
                    csv += (anagrafica.codiceFiscale != undefined ? anagrafica.codiceFiscale : '') + ';';
                    csv += (anagrafica.data != undefined ? new Date(anagrafica.data).getFullYear() : '') + ';';
                }


                csv+='\n';
            }


            this.downloadFile(csv);


    }

    downloadFile(data) {
        console.log('data is for the csv ');
        console.log(data);
        const anchorElement = document.createElement('a');
        const blob = new Blob([data], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        anchorElement.href = url;
        anchorElement.download = 'anagrafiche_ads_'+(new Date()).toISOString().split('T')[0]+'.csv';
        anchorElement.click();

        window.URL.revokeObjectURL(url);
        //window.open(url);
    }


}
