import { Component, OnInit, ViewChild } from '@angular/core';
import {AnagraficaComponent} from '../anagrafica/anagrafica.component';
import {LangService} from '../../services/lang.service';
import {Beneficiario} from '../../models/beneficiario';
import {DbService} from '../../services/db.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {Amministratore} from '../../models/amministratore';
import {StatusService} from '../../services/status.service';
import {Account} from '../../models/account';
import {Resp} from '../../models/resp';

import * as _ from 'lodash';

@Component({
  selector: 'app-beneficiario',
  templateUrl: './beneficiario.component.html',
  styleUrls: ['./beneficiario.component.css'],
  providers:[DbService,LangService]
})
export class BeneficiarioComponent implements OnInit {

  constructor(private router:Router, private langService:LangService, private activatedRoute:ActivatedRoute, private dbService:DbService, private statusService:StatusService) { }


  @ViewChild('anagrafica',{static:false}) anaComponent;
  @ViewChild('formGrado',{static:false}) formGrado;


  hideUpdateSuccessMessage=true;
  saveBeneficiarySuccessMsg=this.langService.updateSuccessMsg();
  idBeneficiario:number;
  beneficiario= new Beneficiario();
  amministratore:Amministratore;

  checkAccess(){
    if(!this.statusService.getAccess()){
        this.router.navigate(['']);
    }
}
  ngOnInit() {

    this.checkAccess();

  		this.idBeneficiario=+this.activatedRoute.snapshot.params['idBeneficiario'];
  		console.log("l'id ricevuto dal router è : ",this.idBeneficiario);

  		if(this.idBeneficiario!=null && this.idBeneficiario!=0 && this.idBeneficiario!=-1)
  		  this.dbService.getBeneficiario(this.idBeneficiario).subscribe(data => this.updateBeneficiario(data));
      else
        this.beneficiario.idBeneficiario=-1;


  }


  updateBeneficiario(data){
    console.log("sono updateBeneficairio(data) e loggo data: ",data);
    let benef=(data as Resp).data;
    console.log("ora loggo data.data", (data as Resp).data);
        console.log("id di getId: ",this.statusService.getId());
      this.dbService.getAccountFromId(this.statusService.getId())
        .subscribe(
          data => this.processAccount((data as Resp).data),
          err => this.processError(err+"errore da get account from id")
          );





  	this.beneficiario=benef;
    // Luke 16/02/2021 : caricamento indirizzo strutturato da anagrafica
    if(benef.indirizzoStrutturato!=undefined){
      this.beneficiario.indirizzoStrutturato=_.cloneDeep(benef.indirizzoStrutturato);
     }
    // Luke 16/02/2021 : caricamento indirizzo strutturato da anagrafica - END

  	console.log("benf locale aggiornato, proveniva da data.data: ",this.beneficiario);
  	this.amministratore=this.statusService.getAmministratore();
  	this.amministratore.idBeneficiario.push(this.beneficiario.idBeneficiario);
  	console.log("updateBeneficiario()");
  	console.log(this.amministratore);
  	console.log("updateBeneficiario()");
  	this.statusService.updateAmministratore(this.amministratore);
  	 	this.dbService.updateAmmBene(this.statusService.getId(),this.beneficiario.idBeneficiario)

  			.subscribe(data => console.log(data));


       }

 processAccount(data){
      console.log("sono la processAccount e ho ricevuto");

      let acc:Account=data;
       acc.completeBene=true;
       console.log(acc);
       this.dbService.completeBene(acc)
              /*.map(res => {
          if(res.status==204){
            throw new Error("account inesistente");


          }
          if(res.status==406){
               throw new Error("errore di rete");

          }
          if(res.status==200)
            return res.json();

        })*/
        .subscribe(
          data => this.processUpdateAccount((data as Resp).data),
          err => this.processError(err+" errore da update account su db")
          );


    }


   processUpdateAccount(acc){
      this.statusService.updateAccount(acc);
      console.log("update effettuato con successo");
    }



    processError(err){
      alert(err);
    }





  backToMain(){
  	return this.langService.backToMain();
  }

  saveBeneficiary(ana:Beneficiario){
  	console.log("saveBeneficiary()");
  	console.log(ana);
  	console.log("saveBeneficiary()");

  	this.anaComponent.form.onSubmit();
  	if(this.anaComponent.form.valid && this.formGrado.valid){

      ana.grado=this.beneficiario.grado;


   /*   this.beneficiario.cellulare=ana.cellulare;
      this.beneficiario.codiceFiscale=ana.codiceFiscale;
      this.beneficiario.cognome=ana.cognome;
      this.beneficiario.dataDiNascita=ana.dataDiNascita;
      this.beneficiario.dataNomina=ana.dataNomina;
      this.beneficiario.
      this.beneficiario.
      this.beneficiario.
      this.beneficiario.*/


  	this.dbService.updateBeneficiario(ana)
  			/*.map(res => {
           if(res.status==204)
             throw new Error("beneficiario inesistente");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



         }


  			)*/
  			.subscribe(data => this.updateBeneficiario(data));
  			this.hideUpdateSuccessMessage=false;

  	} else throw new Error("form non valido");






  }












}
