import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'action',
  templateUrl: './actionchoice.component.html',
  styleUrls: ['./actionchoice.component.css']
})


export class ActionchoiceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
