import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import {AppSettings} from '../app-settings';
import {Md5} from 'md5-typescript';

import {CompleteAccount} from '../models/complete-account';
import {Anagrafica} from '../models/anagrafica';
import {Account} from '../models/account';
import {Amministratore} from '../models/amministratore';
import {AccountOperator} from '../models/account-operator';
import {Beneficiario} from '../models/beneficiario';
import {Ricorrente} from '../models/ricorrente';
import {Username} from '../models/username';

import {Observable} from 'rxjs';
import {FrontOffice} from '../models/front-office';
import {DataSportello} from '../data/data-sportello';
import {Resp} from '../models/resp';
import {DataEsportaAnagrafiche} from '../data/data-esporta-anagrafiche';



@Injectable({
  providedIn: 'root'
})
export class DbService {

	 private serviceBase = `${AppSettings.API_ENDPOINT}/dbService/`;

  constructor(private http: HttpClient) { }
   updateAccountAmministratore(acc) {

     const json = JSON.stringify(acc);
     console.log(json);


     const params = json;
     let headers;

     headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     return this.http.post(this.serviceBase + 'updateAccountAmministratore', params, {headers});

  }


  createAccount(acc: Account, ana: Anagrafica, amm: Amministratore, kind: string, mode: string, idSportello) {

    console.log('sono la createAccount del dbService');

    const completeAccount = new CompleteAccount;

    console.log('dbService.completeAccount.addAccount()');
    completeAccount.addAccount(acc);
    console.log('dbService.completeAccount.addAnagrafica()');
    console.log('sono su dbSErvice, anagrafica vale: ', ana);
    completeAccount.addAnagrafica(ana);
    console.log('dbService.completeAccount.setKind()');
    completeAccount.setKind(kind);
    completeAccount.setSportello(idSportello);
    completeAccount.setAmministratoreData(amm);
    console.log('ora loggo l\'oggetto completeAccount');

    console.log(completeAccount);
    const json = JSON.stringify(completeAccount);
    console.log(json);

    const params = json;
    let headers;
    if (mode == 'standard') {
         headers = new HttpHeaders({ 'Content-Type': 'application/json' });
         return this.http.post(this.serviceBase + 'createAccount', params, {headers});
     }

    if (mode == 'direct') {
         headers = new HttpHeaders({ 'Content-Type': 'application/json' });
         return this.http.post(this.serviceBase + 'createActivatedAccount', params, {headers});
     }
  }

  uploadFile(file: FormData) {
    const headers = new HttpHeaders({'Content-Type': 'undefined'});
    return this.http.post(this.serviceBase + 'uploadFile', file);
  }

   uploadLogo(file: FormData) {
    const headers = new HttpHeaders({'Content-Type': 'undefined'});
    return this.http.post(this.serviceBase + 'uploadLogo', file);
  }

  getFileForRicorso(idRicorso: number) {

    return this.http.get(this.serviceBase + 'getFileForRicorso?idRicorso=' + idRicorso);
  }

  getListaComuni(search: string) {

    return this.http.get(this.serviceBase + 'getListaComuni?search=' + search);
  }
  checkAccess() {

    return this.http.get(this.serviceBase + 'checkAccess');
  }
  confermaPresaVisioneRicorso(idRicorso: number) {

    return this.http.get(this.serviceBase + 'confermaPresaVisioneRicorso?idRicorso=' + idRicorso);
  }

    confermaPresaVisioneAmministratore(idAccount: number) {

    return this.http.get(this.serviceBase + 'confermaPresaVisioneAmministratore?idAccount=' + idAccount);
  }



  /* creaPermesso(idAccount:number){
    return this.http.get(this.serviceBase+'creaPermesso?idAccount='+idAccount+'&key='+Md5.init(this.keyPermessi).toString());
  } */

  deleteCaricamentoFromId(idCaricamento: number) {
      return this.http.delete(this.serviceBase + 'deleteCaricamentoFromId?idCaricamento=' + idCaricamento);
  }

  deleteOperatorFromId(id: number) {
      return this.http.delete(this.serviceBase + 'deleteOperatorFromId?idOperatore=' + id);
  }
  getUploadedDocumentFromId(idRicorso, idCaricamento) {
      return this.http.get(this.serviceBase + 'getUploadedDocumentFromId?idRicorso=' + idRicorso + '&idCaricamento=' + idCaricamento);

  }

   toggleAccountOperatorById(idAccount, stato) {

     console.log('dbSErvice idAccount: ', idAccount, ' stato: ', stato );
     return this.http.get(this.serviceBase + 'toggleAccountOperatorById?idAccount=' + idAccount + '&stato=' + stato);

  }

  updateAccountOperator(data) {
    const  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.serviceBase + 'updateAccountOperator', data, {headers});
  }

  updateAccount(user) {
    const json = JSON.stringify(user);
    const params = json;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(this.serviceBase + 'updateAccount', params, {headers});
  }

  /*
  updateAccountOperatorAnagrafica(data){

    var params=data;
    var headers=new HttpHeaders({'Content-Type':'application/json'});
    return this.http.post(this.serviceBase+'updateAccountOperatorAnagrafica',params,{headers:headers});
  } */



  createAccountFromRicorso(acc: Account, ana: Anagrafica, ric: Ricorrente, bene: Beneficiario, nrg: string, kind: string, mode: string) {

    const completeAccount = new CompleteAccount;
    completeAccount.addAccount(acc);
    completeAccount.addAnagrafica(ana);
    completeAccount.setKind(kind);
    console.log(completeAccount);
    const jsonCompleteAccount = JSON.stringify(completeAccount);


    const toSend = {completeAccount: jsonCompleteAccount, beneficiario: JSON.stringify(bene), ric: JSON.stringify(ric), numero: nrg};
    const toSendJson = JSON.stringify(toSend);

    console.log('__________');
    console.log(toSendJson);

    const params = toSendJson;
    let headers;

    if (mode == 'direct') {
         headers = new HttpHeaders({ 'Content-Type': 'application/json' });
         return this.http.post(this.serviceBase + 'createActivatedAccountFromRicorso', params, {headers});
     }
  }


  getZip(idRicorso) {
   return this.http.get(this.serviceBase + 'getZip?idRicorso=' + idRicorso);
  }

  deleteZip(idRicorso) {
   return this.http.delete(this.serviceBase + 'deleteZip?idRicorso=' + idRicorso);
  }

  deletePdf(name) {
   return this.http.delete(this.serviceBase + 'deletePdf?name=' + name);
  }

  addZip(idRicorso: number, path: string) {
    const params = {idRicorso, path};

    const json = JSON.stringify(params);
    console.log('_________');
    console.log(json);
    console.log('_________');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.serviceBase + 'addZip', params, {headers});
  }

   acceptCopy(idRicorso, kind) {
     const toSend = {idRicorso, kind};
     let toSendJson;
     toSendJson = JSON.stringify(toSend);

     const params = toSendJson;
     let headers;
     headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     return this.http.post(this.serviceBase + 'acceptCopy', params, {headers});



   }

   discardCopy(idRicorso, kind) {
     const toSend = {idRicorso, kind};
     let toSendJson;
     toSendJson = JSON.stringify(toSend);

     const params = toSendJson;
     let headers;
     headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     return this.http.post(this.serviceBase + 'discardCopy', params, {headers});


   }


  createAccountOperator(acc: AccountOperator, kind: string) {
       console.log('dbService.create account operator');



       acc.setKind(kind);


       const json = JSON.stringify(acc);
       console.log(json);

       const params = json;
       const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       return this.http.post(this.serviceBase + 'createAccountOperator', params, {headers});

  }


  createAccountSupervisor(acc: AccountOperator, kind: string) {

   console.log('dbService.create account supervisor');

   acc.setKind(kind);


   const json = JSON.stringify(acc);
   console.log(json);

   const params = json;
   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.http.post(this.serviceBase + 'createAccountSupervisor', params, {headers});

  }

  addWindow(w: FrontOffice) {


     const json = JSON.stringify(w);
     console.log(json);

     const params = json;
     const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     return this.http.post(this.serviceBase + 'addWindow', params, {headers});

  }


  updateWindow(w: FrontOffice) {

     console.log('dbService-updateWindow()');
     const json = JSON.stringify(w);
     console.log(json);

     const params = json;
     const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     return this.http.post(this.serviceBase + 'updateWindow', params, {headers});
  }

  getNrg(idRicorso: number) {
    return this.http.get(this.serviceBase + 'getNrg?idRicorso=' + idRicorso);
  }


  getWindowDetails(idSportello: number): Observable<Resp> {

    return this.http.get<Resp>(this.serviceBase + 'getWindowDetails?idSportello=' + idSportello);
  }

  updateNrg(data) {
      const params = data;
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.post(this.serviceBase + 'updateNrg', params, {headers});

  }

  getWindowList() {

     return this.http.get(this.serviceBase + 'getWindowList');


  }

  getWindow(idSportello: number) {
    return this.http.get(this.serviceBase + 'getWindow?idSportello=' + idSportello);

  }

  getRequestCopyState(idRicorso: number) {
    return this.http.get(this.serviceBase + 'getRequestCopyState?idRicorso=' + idRicorso);
  }

  deleteWindow(idSportello: number) {
         return this.http.delete(this.serviceBase + 'deleteWindow?idSportello=' + idSportello);
  }


  delBeneficiario(idBeneficiario: number) {
      return this.http.delete(this.serviceBase + 'delBeneficiario?idBeneficiario=' + idBeneficiario);
  }



  delAmministratore(idAccount: number) {
      return this.http.delete(this.serviceBase + 'delAmministratore?idAccount=' + idAccount);
  }
  delAdsFromWindow(idAccountAds: number) {
      return this.http.delete(this.serviceBase + 'delAdsFromWindow?idAccountAds=' + idAccountAds);
  }
  delAccount(idAccount: number) {
      return this.http.delete(this.serviceBase + 'delAccount?idAccount=' + idAccount);
  }

   delDocumentiSalvati(idAccount: number) {
      return this.http.delete(this.serviceBase + 'delDocumentiSalvati?idAccount=' + idAccount);
  }



  linkAccount(u: Username, account: Account) {

      // var json=JSON.stringify(u);
    const json = {userName: u.userName, password: u.password, idSportello: account.idSportello};
    const s = JSON.stringify(json);

    console.log(s);

    const params = s;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      // headers.append('Accept', 'q=0.8;application/json;q=0.9');

    return this.http.post(this.serviceBase + 'linkAccountFromRicorrente', params, {headers});


    // insertUser
  }

    linkAccountFromRicorrente(u: Username, account: Account) {

      // var json=JSON.stringify(u);
    const json = {userName: u.userName, idSportello: account.idSportello};
    const s = JSON.stringify(json);

    console.log(s);

    const params = s;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      // headers.append('Accept', 'q=0.8;application/json;q=0.9');

    return this.http.post(this.serviceBase + 'linkAccountFromRicorrente', params, {headers});


    // insertUser
  }


  logIn(u: Username) {

    //  var json=JSON.stringify(u);
  //  var json={nome:u.nome,cognome:u.cognome,email:u.email,tell:u.tel,professione:u.professione,dataNascita:u.dataNascita,pranzo:u.pranzo}
      const json = JSON.stringify(u);

      const params = json;

      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      // headers.append('Accept', 'q=0.8;application/json;q=0.9');

      return this.http.post(this.serviceBase + 'login', params, {headers});


    // insertUser
  }

  logInCookie(u: Username) {

    //  var json=JSON.stringify(u);
  //  var json={nome:u.nome,cognome:u.cognome,email:u.email,tell:u.tel,professione:u.professione,dataNascita:u.dataNascita,pranzo:u.pranzo}
      const json = JSON.stringify(u);

      const params = json;

      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      // headers.append('Accept', 'q=0.8;application/json;q=0.9');

      return this.http.post(this.serviceBase + 'loginCookie', params, {headers});


    // insertUser
  }


  /**** log in operator ****/


  logInOperator(u: Username) {

    //  var json=JSON.stringify(u);
  //  var json={nome:u.nome,cognome:u.cognome,email:u.email,tell:u.tel,professione:u.professione,dataNascita:u.dataNascita,pranzo:u.pranzo}
      const json = JSON.stringify(u);
      console.log(json);

      const params = json;

      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      // headers.append('Accept', 'q=0.8;application/json;q=0.9');

      return this.http.post(this.serviceBase + 'loginOperator', params, {headers});


    // insertUser
  }

  logInCookieOperator(u: Username) {

    //  var json=JSON.stringify(u);
  //  var json={nome:u.nome,cognome:u.cognome,email:u.email,tell:u.tel,professione:u.professione,dataNascita:u.dataNascita,pranzo:u.pranzo}
      const json = JSON.stringify(u);

      const params = json;

      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      // headers.append('Accept', 'q=0.8;application/json;q=0.9');

      return this.http.post(this.serviceBase + 'loginCookieOperator', params, {headers});


    // insertUser
  }


  /**** fine login operator ***/

  updateAmministratore(amm) {
    const params = JSON.stringify(amm);
    console.log('sono la dbService.updateAmministratore() faccio una POST');
    console.log(params);


    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(this.serviceBase + 'updateAmministratore', params, {headers});

  }

  updateAmmBene(idAccount, idBeneficiario) {
    const params = JSON.stringify({idAccount, idBeneficiario});
    console.log('sono la dbService.updateAmmBene() faccio una POST');
    console.log(params);


    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(this.serviceBase + 'updateAmmBene', params, {headers});

  }

  save2db(data) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(this.serviceBase + 'save2db', data, {headers});
  }

  update2db(data) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(this.serviceBase + 'update2db', data, {headers});

  }

  /* gestione ricorso */


 save2dbRicorso(data) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(this.serviceBase + 'save2dbRicorso', data, {headers});
  }

  update2dbRicorso(data) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(this.serviceBase + 'update2dbRicorso', data, {headers});

  }

    save2dbPropostaNominativo(data) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        return this.http.post(this.serviceBase + 'save2dbPropostaNominativo', data, {headers});
    }

    update2dbPropostaNominativo(data) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        return this.http.post(this.serviceBase + 'update2dbPropostaNominativo', data, {headers});
    }

    getPropostaNominativiFromDb(idRicorso){
        return this.http.get(this.serviceBase + 'getProposteNominativiFromDb?idRicorso=' + idRicorso);
    }
    cancellaAnagraficaPropostaNominativo(idProposta){
        return this.http.get(this.serviceBase + 'requestDeletePropostaNominativoFromDb?idProposta=' + idProposta);
    }

    updateStatoNominaAccount(idAccount,statoNomina){
        return this.http.get(this.serviceBase + 'updateStatoNominaAccount?idAccount=' + idAccount+'&statoNomina='+statoNomina);
    }


    update2dbParenti(data) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(this.serviceBase + 'update2dbParenti', data, {headers});

  }


  update2dbRicorsoStatus(data) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(this.serviceBase + 'update2dbRicorsoStatus', data, {headers});

  }




/* fine gestione ricorso */



  getDocFromDb(idDocumento: number, tipoDocumento: string) {
      console.log('faccio richeista di idDocumento ', idDocumento);
      const a = this.http.get(this.serviceBase + 'getDocFromDb?idDocumento=' + idDocumento + '&tipoDocumento=' + tipoDocumento);
      console.warn(a);
      return a;
  }

  getRicorsoFromDb(idDocumento: number) {
      console.log('faccio richeista di idDocumento ', idDocumento);
      const a = this.http.get(this.serviceBase + 'getRicorsoFromDb?idDocumento=' + idDocumento);
      console.warn(a);
      return a;
  }

  getBeneFromRicorsoFromDb(idDocumento: number) {
      console.log('faccio richeista di benef con idDocumento ', idDocumento);
      const a = this.http.get(this.serviceBase + 'getBeneFromRicorsoFromDb?idDocumento=' + idDocumento);
      console.warn(a);
      return a;
  }

    getParentiFromDb(idDocumento: number) {
        console.log('carico parenti: faccio richeista di idDocumento ', idDocumento);
        return this.http.get(this.serviceBase + 'getParentiFromDb?idDocumento=' + idDocumento);
    }

    esportaAnagraficheCSV(idDocumento:number, selection:DataEsportaAnagrafiche){
        return this.http.get(this.serviceBase + 'esportaAnagraficheCSV?idDocumento=' + idDocumento + '&ricorrenti=' + selection.ricorrenti + '&beneficiario=' + selection.beneficiario+'&parenti='+selection.parenti);

    }



  loadDocumentList(idAccount: number) {
      console.log('faccio richeista di lista documenti salvati per account ', idAccount);
      return this.http.get(this.serviceBase + 'loadDocumentList?idAccount=' + idAccount);

  }

  loadWindowDocumentList(idSportello: number) {
      console.log('faccio richeista di lista documenti dell\'operatore salvati per sportello ', idSportello);
      return this.http.get(this.serviceBase + 'loadWindowDocumentList?idSportello=' + idSportello);

  }

  loadWindowAdsList(idSportello: number,year?:string,comune?:string,statoNomina?:string) {
      console.log(`faccio richeista di lista documenti dell\'operatore salvati per sportello ${idSportello} filtrati per anno ${year}`);

      return this.http.get(this.serviceBase + 'loadWindowAdsList?idSportello=' + idSportello+(year != undefined && year !='*' ? '&year='+year : '')+(comune != undefined ? '&comune='+comune:'')+(statoNomina != undefined && statoNomina !='*' ? '&statoNomina='+statoNomina : ''));
  }

  loadAdsOrderedList(mode: string, asc: boolean) {
      console.log('faccio richeista di lista documenti di admin');
      return this.http.get(this.serviceBase + 'loadWindowAdsList?mode=' + mode + '&asc=' + asc);

  }

  loadWindowAdsOrderedList(idSportello: number, mode: string, asc: boolean) {
      console.log('faccio richeista di lista documenti dell\'operatore salvati per sportello ', idSportello);
      return this.http.get(this.serviceBase + 'loadWindowAdsList?idSportello=' + idSportello + '&mode=' + mode + '&asc=' + asc);

  }


/*ricorsi */

 loadWindowArchiveList(idSportello: number, currentYear?: number) {
      console.log('faccio richeista di lista documenti dell\'operatore salvati per sportello ', idSportello);
      if (currentYear == undefined) {
         return this.http.get(this.serviceBase + 'loadWindowArchiveList?idSportello=' + idSportello);
      } else {
        return this.http.get(this.serviceBase + 'loadWindowArchiveList?idSportello=' + idSportello + '&year=' + currentYear);
      }

  }

   getArchiveYearList(idSportello: number) {
      console.log('faccio richeista di lista anni ricorsi per sportello ', idSportello);
      return this.http.get(this.serviceBase + 'getArchiveYearList?idSportello=' + idSportello);

  }

    getAdsListYearList(idSportello: number) {
        console.log('faccio richeista di lista anni (creazione ads) per sportello ', idSportello);
        return this.http.get(this.serviceBase + 'getAdsYearList?idSportello=' + idSportello);

    }

 loadWindowArchiveListOrdered(idSportello: number, mode: string, asc: boolean, year?: number) {
      console.log('faccio richeista di lista documenti dell\'operatore salvati per account ', idSportello);
      if (year != undefined) {
       return this.http.get(this.serviceBase + 'loadWindowArchiveList?idSportello=' + idSportello + '&mode=' + mode + '&asc=' + asc + '&year=' + year);
      } else {
       return this.http.get(this.serviceBase + 'loadWindowArchiveList?idSportello=' + idSportello + '&mode=' + mode + '&asc=' + asc);
      }

  }

  loadArchiveList() {
      console.log('admin fa richeista di lista documenti salvati ');
      return this.http.get(this.serviceBase + 'loadArchiveList');

  }

   loadArchiveListOrdered(mode: string, asc: boolean) {
      console.log('admin fa richeista di lista documenti salvati ');
      return this.http.get(this.serviceBase + 'loadArchiveList?mode=' + mode + '&asc=' + asc);

  }

  /* fine ricorsi */

  /* lista operatori */
 loadOperatorListByWindow(idSportello: number) {
      console.log('faccio richeista di lista operatori per sportello ', idSportello);
      return this.http.get(this.serviceBase + 'loadOperatorList?idSportello=' + idSportello);

  }

  loadOperatorListGlobal() {
      console.log('admin fa richeista di lista operatori globale');
      return this.http.get(this.serviceBase + 'loadOperatorList?idSportello=-1');

  }

/* fine lista operatori */




   deleteRequest(idDocumento: number) {
     console.log('db.service, deleteRequest: ', 'requestDelete?idDocumento=', idDocumento);
     return this.http.get(this.serviceBase + 'requestDelete?idDocumento=' + idDocumento);
    }
    deleteRequestRicorso(idDocumento: number) {
        console.log('db.service, deleteRequest: ', 'requestDeleteRicorso?idDocumento=', idDocumento);
        return this.http.get(this.serviceBase + 'requestDeleteRicorso?idDocumento=' + idDocumento);
    }

    copyRequestRicorso(idDocumento: number,escludiBeneficiario:boolean,escludiRicorrenti:boolean){
        return this.http.get(this.serviceBase + 'requestCopyRicorso?idDocumento=' + idDocumento+'&escludiBeneficiario='+escludiBeneficiario+'&escludiRicorrenti='+escludiRicorrenti);
    }

   undoDeleteRequest(idDocumento: number) {
       return this.http.get(this.serviceBase + 'undoDeleteRequest?idDocumento=' + idDocumento);
    }


  updateBeneficiario(benef) {


    const params = JSON.stringify(benef);
   // var params=JSON.stringify({'benefciario':JSON.stringify(benef),'idAccount':idAccount});

    console.log('sono dbService.updateBeneficiario() e faccio POST');
    console.log(params);

    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.post(this.serviceBase + 'updateBeneficiario', params, {headers});

  }


  getAccountFromId(idAccount: number) {
     return this.http.get(this.serviceBase + 'getAccountFromId?idAccount=' + idAccount);
  }

  getAnagrafica(idAccount: number) {
    return this.http.get(this.serviceBase + 'anagrafica?idAccount=' + idAccount);
  }

   getBeneficiario(idBeneficiario: number) {
    return this.http.get(this.serviceBase + 'beneficiario?idBeneficiario=' + idBeneficiario);
  }

   getAmministratore(idAccount: number) {
    return this.http.get(this.serviceBase + 'amministratore?idAccount=' + idAccount);
  }

  updateAccountOperatorAnagrafica(data) {

    const params = data;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    // attenzione a questo metodo, deve chiamarsì così, perchè se iniziasse per updateAccountOperator sarebbe uguale ad un'altro metodo presente
    // nel backend php e farebbe match sul controllo dei metodi non autenticati (rest.inc.php riga 30 circa)
    return this.http.post(this.serviceBase + 'updateAnagraficaAccountOperator', params, {headers});
  }

  completeAds(acc) {
    const json = JSON.stringify(acc);
    const params = json;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(this.serviceBase + 'completeAds', params, {headers});


  }

   completeBene(acc) {
    const json = JSON.stringify(acc);
    const params = json;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(this.serviceBase + 'completeBene', params, {headers});


  }


    activateAccount(user) {
    const json = JSON.stringify(user);
    const params = json;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(this.serviceBase + 'activateAccount', params, {headers});
  }

   activateAccountOperator(user) {
    const json = JSON.stringify(user);
    const params = json;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(this.serviceBase + 'activateAccountOperator', params, {headers});
  }


  getUserName(user: string) {
    return this.http.get(this.serviceBase + 'getUserName?user=' + user);
  }

  getUserNameOperator(user: string) {
    return this.http.get(this.serviceBase + 'getUserNameOperator?user=' + user);
  }

  // Luke 15-12-2020
  getUserId(user: string) {
    return this.http.get(this.serviceBase + 'loginResetUser?user=' + user);
  }

  getUserIdOperator(user: string) {
    return this.http.get(this.serviceBase + 'loginResetUser?user=' + user + '&kind=opr');
  }
  // Luke 15-12-2020 end

  findAccount(user: string) {
    return this.http.get(this.serviceBase + 'findAccount?user=' + user);
  }

  deleteOldProfiles() {
    console.log('url base:');
    console.log(this.serviceBase);
    this.http.get(this.serviceBase + 'deleteOldProfiles')

      .subscribe(data => console.log(data));
  }


  getAccountFromEmail(email: string) {
    return this.http.get(this.serviceBase + 'getAccountFromEmail?email=' + email);
  }
   getAccountOperatorFromEmail(email: string) {
    return this.http.get(this.serviceBase + 'getAccountOperatorFromEmail?email=' + email);
  }

   getAccountOperatorFromId() {
    return this.http.get(this.serviceBase + 'getAccountOperatorFromId');
  }

  private handleError(error: Response) {
        console.error(error);
        return Observable.throw(error);
    }
}
