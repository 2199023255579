import { Component, OnInit,ViewChild } from '@angular/core';
import {StatusService} from '../../../services/status.service';
import {DataExt} from '../../../data/data-ext';
import {LangService} from '../../../services/lang.service';
import {DbService} from '../../../services/db.service';
import { HttpHeaders,HttpClient} from '@angular/common/http';
import {AppSettings} from '../../../app-settings';
import { FlashMessagesService } from 'angular2-flash-messages';
import {Router} from '@angular/router';
import {Resp} from '../../../models/resp';
import {RespSavedDocument} from '../../../models/resp-saved-document';



/* Luke 28-01-2021: copied from ricorrente component */
// import {Ricorrente,Relazione} from '../../models/ricorrente';
import { Input,Output, EventEmitter } from '@angular/core';
import {Anagrafica} from '../../../models/anagrafica';
import {Comune,Indirizzo,IndirizzoStrutturato} from '../../../models/indirizzo';
/* END Luke 28-01-2021: copied from ricorrente component */

@Component({
  selector: 'app-ext',
  templateUrl: './ext.component.html',
  styleUrls: ['./ext.component.css'],
  providers:[LangService,DbService]
})
export class ExtComponent implements OnInit {

  @ViewChild('extForm',{static:false}) form;

	private disableForm=false;
	private hideSuccessMessage=true;

  public statusMsg;

  private dataExt=new DataExt();

  /* Luke 28-01-2021: copied from ricorrente component */
  private changed:boolean;
  public comune:Comune;
  public indirizzo:Indirizzo;
  @Input() ana: Anagrafica;
  /* END Luke 28-01-2021: copied from ricorrente component */

 checkAccess(){
    if(!this.statusService.getAccess()){
        this.router.navigate(['']);
    }

}
  ngOnInit() {
    this.checkAccess();
  	this.dataExt=this.statusService.getExt();

    let idB=this.statusService.getIdBeneficiario();
    if(idB==undefined){
      window.alert('seleziona un beneficiario prima di iniziare a produrre un documento');
      this.router.navigate(['/dashboard'])
    }

    /* Luke 28-01-2021 : copied from ricorrente component */
    /*
    console.warn("sono la input-ext e loggo: ",this.ana);
    if(this.dataExt.indirizzoStrutturato!=undefined){

    this.comune={cap:'',comune:'',provincia:''};
    this.indirizzo={prefixTopo:'',denomTopo:'',civico:'',nazionalita:''};


    this.indirizzo.prefixTopo=this.dataExt.indirizzoStrutturato.indirizzo.prefixTopo;
    this.indirizzo.denomTopo=this.dataExt.indirizzoStrutturato.indirizzo.denomTopo;
    this.indirizzo.civico=this.dataExt.indirizzoStrutturato.indirizzo.civico;
    this.comune.cap=this.dataExt.indirizzoStrutturato.comune.cap;
    this.comune.comune=this.dataExt.indirizzoStrutturato.comune.comune;
    this.comune.provincia=this.dataExt.indirizzoStrutturato.comune.provincia;
    this.indirizzo.nazionalita=this.dataExt.indirizzoStrutturato.indirizzo.nazionalita;
  } else {
    this.comune={cap:'',comune:'',provincia:''};
    this.indirizzo={prefixTopo:'',denomTopo:'',civico:'',nazionalita:''};
  }*/
  this.comune={cap:'',comune:'',provincia:''};
  this.indirizzo={prefixTopo:'',denomTopo:'',civico:'',nazionalita:''};
  /* END Luke 28-01-2021 : copied from ricorrente component */

  }

  /* Luke 28-01-2021: indirizzo strutturato beneficiario */
  copyAddress(){
    /* Questo metodo copia il valore dell'indirizzo strutturato nella variabile
       contenente l'indirizzo finale (un'unica stringa)
    */

  if(this.dataExt.indirizzoStrutturato!=undefined){
    console.log('this.anagrafica.indirizzoStrutturato!=undefined');

    if(this.dataExt.indirizzoStrutturato.indirizzo.prefixTopo!='SCONOSCIUTO'){
      if(this.dataExt.indirizzoStrutturato.comune.provincia!='' && this.dataExt.indirizzoStrutturato.comune.provincia!=null)
        // Luke 25-01-2021 : l'indirizzo finale è dataExt.indirizzo
        this.dataExt.indirizzo=`${this.dataExt.indirizzoStrutturato.indirizzo.prefixTopo} ${this.dataExt.indirizzoStrutturato.indirizzo.denomTopo} ${this.dataExt.indirizzoStrutturato.indirizzo.civico!=''?'n.':''} ${this.dataExt.indirizzoStrutturato.indirizzo.civico}${this.dataExt.indirizzoStrutturato.comune.cap!=''?',':''} ${this.dataExt.indirizzoStrutturato.comune.cap} ${this.dataExt.indirizzoStrutturato.comune.comune} (${this.dataExt.indirizzoStrutturato.comune.provincia})`;
        //this.ana.indirizzo=`${this.ana.indirizzoStrutturato.indirizzo.prefixTopo} ${this.ana.indirizzoStrutturato.indirizzo.denomTopo} ${this.ana.indirizzoStrutturato.indirizzo.civico!=''?'n.':''} ${this.ana.indirizzoStrutturato.indirizzo.civico}${this.ana.indirizzoStrutturato.comune.cap!=''?',':''} ${this.ana.indirizzoStrutturato.comune.cap} ${this.ana.indirizzoStrutturato.comune.comune} (${this.ana.indirizzoStrutturato.comune.provincia})`;
      else
        this.dataExt.indirizzo=`${this.dataExt.indirizzoStrutturato.indirizzo.prefixTopo} ${this.dataExt.indirizzoStrutturato.indirizzo.denomTopo} ${this.dataExt.indirizzoStrutturato.indirizzo.civico!=''?'n.':''} ${this.dataExt.indirizzoStrutturato.indirizzo.civico}${this.dataExt.indirizzoStrutturato.comune.cap!=''?',':''} ${this.dataExt.indirizzoStrutturato.comune.cap} ${this.dataExt.indirizzoStrutturato.comune.comune}`;
        //this.ana.indirizzo=`${this.ana.indirizzoStrutturato.indirizzo.prefixTopo} ${this.ana.indirizzoStrutturato.indirizzo.denomTopo} ${this.ana.indirizzoStrutturato.indirizzo.civico!=''?'n.':''} ${this.ana.indirizzoStrutturato.indirizzo.civico}${this.ana.indirizzoStrutturato.comune.cap!=''?',':''} ${this.ana.indirizzoStrutturato.comune.cap} ${this.ana.indirizzoStrutturato.comune.comune}`;

    } else {
      this.dataExt.indirizzo='SCONOSCIUTO';
      //this.ana.indirizzo='SCONOSCIUTO';
    }


  } else {
    console.log('indirizzoStrutturato==undefined');


    if(this.indirizzo.prefixTopo!='SCONOSCIUTO'){

     if(this.comune.provincia!='' && this.comune.provincia!=null)


      this.dataExt.indirizzo=`${this.indirizzo.prefixTopo} ${this.indirizzo.denomTopo} ${this.indirizzo.civico!=''?'n.':''} ${this.indirizzo.civico}${this.comune.cap!=''?',':''} ${this.comune.cap} ${this.comune.comune} (${this.comune.provincia})`;
      //this.ana.indirizzo=`${this.indirizzo.prefixTopo} ${this.indirizzo.denomTopo} ${this.indirizzo.civico!=''?'n.':''} ${this.indirizzo.civico}${this.comune.cap!=''?',':''} ${this.comune.cap} ${this.comune.comune} (${this.comune.provincia})`;
     else
        this.dataExt.indirizzo=`${this.indirizzo.prefixTopo} ${this.indirizzo.denomTopo} ${this.indirizzo.civico!=''?'n.':''} ${this.indirizzo.civico}${this.comune.cap!=''?',':''} ${this.comune.cap} ${this.comune.comune}`;
        //this.ana.indirizzo=`${this.indirizzo.prefixTopo} ${this.indirizzo.denomTopo} ${this.indirizzo.civico!=''?'n.':''} ${this.indirizzo.civico}${this.comune.cap!=''?',':''} ${this.comune.cap} ${this.comune.comune}`;
    } else {
      this.dataExt.indirizzo='SCONOSCIUTO';
      //this.ana.indirizzo='SCONOSCIUTO';
    }

     this.dataExt['indirizzoStrutturato']={indirizzo:{prefixTopo:this.indirizzo.prefixTopo,denomTopo:this.indirizzo.denomTopo,civico:this.indirizzo.civico,nazionalita:this.indirizzo.nazionalita},comune:{cap:this.comune.cap,comune:this.comune.comune,provincia:this.comune.provincia}};

  }

   console.log('anagrafica copyAddress()')
   console.log( this.dataExt.indirizzoStrutturato);
   // Luke 25-01-2021 END l'indirizzo finale è dataExt.indirizzo

}

getComune(){
  if(this.dataExt.indirizzoStrutturato==undefined || this.dataExt.indirizzoStrutturato== null)
    return {cap:'',comune:'',provincia:''};
  else return this.dataExt.indirizzoStrutturato.comune;
}
getIndirizzo(){
   if(this.dataExt.indirizzoStrutturato==undefined || this.dataExt.indirizzoStrutturato == null)
    return {prefixTopo:'',denomTopo:'',civico:'',nazionalita:''};
  else return this.dataExt.indirizzoStrutturato.indirizzo;
}
setComuneScelto(comune:Comune){
   console.log('alla setComune arriva:')
   console.log(comune);
   if(this.dataExt.indirizzoStrutturato!=undefined){

       this.dataExt.indirizzoStrutturato.comune.cap=comune.cap;
       this.dataExt.indirizzoStrutturato.comune.comune=comune.comune;
       this.dataExt.indirizzoStrutturato.comune.provincia=comune.provincia;
   } else {
      this.comune.cap=comune.cap;
      this.comune.comune=comune.comune;
      this.comune.provincia=comune.provincia;
   }

   console.log(`cap ${comune.cap} comune ${comune.comune} prov ${comune.provincia}`);
   this.copyAddress();
   }

 setIndirizzo(indirizzo:Indirizzo){
   console.log('alla setINdirizzo arriva')
   console.log(indirizzo);

    if(this.dataExt.indirizzoStrutturato!=undefined){

       this.dataExt.indirizzoStrutturato.indirizzo.prefixTopo=indirizzo.prefixTopo;
     this.dataExt.indirizzoStrutturato.indirizzo.denomTopo=indirizzo.denomTopo;
     this.dataExt.indirizzoStrutturato.indirizzo.civico=indirizzo.civico;
     this.dataExt.indirizzoStrutturato.indirizzo.nazionalita=indirizzo.nazionalita;
    } else
    {
       this.indirizzo.prefixTopo=indirizzo.prefixTopo;
   this.indirizzo.denomTopo=indirizzo.denomTopo;
   this.indirizzo.civico=indirizzo.civico;
   this.indirizzo.nazionalita=indirizzo.nazionalita;

    }

   this.copyAddress();


 }

/* END Luke 28-01-2021*/


  showUpdateButton(){
    return this.statusService.updateDoc('EXT');
  }
    updateMessage(){
    this._flashMessagesService.show(this.statusMsg, { cssClass: 'alert-success', timeout: 3700 });
    console.warn("updateMessage()");
  }

  saveData(){
      this.statusService.setNewDoc('EXT');
  	console.log(this.form.valid);
  	if(this.form.valid){
  		this.disableForm=true;
  		this.hideSuccessMessage=false;
  		this.statusService.updateExt(this.dataExt);
      this.statusService.setPrintExt(true);
      this.statusPrintExt=this.statusService.getExt().print;
      this.statusMsg="campi impostati correttamente";
      this.updateMessage();

  	}




  }

  updateData(){
      if(this.form.valid){
        this.disableForm=true;
        this.hideSuccessMessage=false;
        this.statusService.updateExt(this.dataExt);
        this.statusService.setPrintExt(true);
        this.statusPrintExt=this.statusService.getExt().print;
        this.statusMsg="campi aggiornati correttamente";
        this.updateMessage();

      }
  }

 enableForm(){
       this.hideSuccessMessage=true;
       this.disableForm=false;
       this.statusMsg="documento pronto per essere modificato";

       this.updateMessage();



    }

   activateForm(){
       this.hideSuccessMessage=true;
       this.disableForm=false;

     }

    clear(){
        this.statusService.updateExt(new DataExt());
        this.statusService.setPrintExt(false);
        this.statusService.setViewExt(false);
        this.statusPrintExt=this.statusService.getExt().print;
        this.statusViewExt=this.statusService.getExt().view;
        this.dataExt=this.statusService.getExt();
        this.activateForm();
        this.statusMsg="nuovo documento generato con successo";
        this.updateMessage();
    }

    /* roba spostata da document-icon-sell-imm */

  public statusPrintExt:boolean;
  public statusViewExt:boolean;


  private ads;
  private bene;
  private obj;
   public showWhere:string;

   constructor(private router:Router,private _flashMessagesService: FlashMessagesService,private langService:LangService, public statusService:StatusService,public dbService:DbService, public http:HttpClient) {


    this.statusPrintExt=statusService.getExt().print;
      console.log("statusPrint: ",this.statusPrintExt);
      this.statusViewExt=statusService.getExt().view;
      console.log("statusView:",this.statusViewExt);
  }




  setAds(a){

    //2
    this.ads=a;
    console.log(this.ads);

      this.dbService.getBeneficiario(this.statusService.getIdBeneficiario())
      //.map(res => res.json())
      .subscribe(data => this.setBene((data as Resp).data));
  }

  setBene(b){
    //3
    this.bene=b;
    console.log(this.bene);
    //faccio chiamata a libreria per creare pdf

    var data = {"ads":JSON.stringify(this.ads),"bene":JSON.stringify(this.bene),"obj":JSON.stringify(this.obj), "id":this.statusService.getId()};

    var json=JSON.stringify(data);

      /*var params=json;
    let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('ads', );
        urlSearchParams.append('bene', this.bene.json());
        urlSearchParams.append('fields', this.obj.json());
        let params = urlSearchParams.toString();*/

        console.log(json);
        var headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
       return this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/ext.php`,json,{headers:headers})
        // .map(res => res.json())
         .subscribe(data => this.showDocument(data));
  }

  showDocument(data){

    this.showWhere=data.fileName;
    this.statusService.setViewExt(true);
    this.statusViewExt=this.statusService.getExt().view;
     this.statusMsg="documento generato con successo";
    this.updateMessage();


  }

  print(){

    //1


     this.obj=this.statusService.getExt();
    this.dbService.getAmministratore(this.statusService.getId())
      //.map(res => res.json())
      .subscribe( data => this.setAds((data as Resp).data));




    console.log("print() called()");
    console.log(this.obj);


  }



  save(){
    console.log("ho chiamato il metodo save: ");
  this.obj=this.statusService.getExt();

  //console.log(this.obj);

   var update= this.statusService.updateDoc('EXT');
    var data;
    var json;



   if(update){
      let idDoc=this.statusService.getIdDocToSave();
      data = {"obj":JSON.stringify(this.obj), "id":this.statusService.getId(),"idDoc":idDoc,"kind":"EXT","idBeneficiario":this.statusService.getIdBeneficiario()};
      json=JSON.stringify(data);
     this.dbService.update2db(json)
       //.map(res =>res.json())
       .subscribe(data => this.showSuccessMsg(data));

    }else
    {
      data = {"obj":JSON.stringify(this.obj), "id":this.statusService.getId(),"kind":"EXT","idBeneficiario":this.statusService.getIdBeneficiario()};
      console.log("sono su ramo save (non update), salverò: ",data);
      json=JSON.stringify(data);
       this.dbService.save2db(json)
      //.map(res =>res.json())
        .subscribe(data =>{
           if((data as RespSavedDocument).id!=-1){
            this.statusService.updateIdDocToSave((data as RespSavedDocument).id);
          }

         this.showSuccessMsg(data)
       });


    }


  }

  showSuccessMsg(msg){
    console.log(msg);
     this.statusMsg="documento salvato con successo";
    this.updateMessage();

  }



  LabelSesso() {
    return this.langService.sesso();
  }
  LabelTelefono(){
  	return this.langService.telefono();
  }

  LabelCellulare(){
  	return this.langService.cellulare();
  }

  LabelNome(){
  	return this.langService.nome();
  }

 LabelCognome(){
 		return this.langService.cognome();
 }

 LabelDataDiNascita(){
 	return this.langService.dataDiNascita();
 }

LabelLuogoDiNascita(){
	return this.langService.luogoDiNascita();
}

LabelCodiceFiscale(){
	return this.langService.codiceFiscale();
}

LabelIndirizzo(){
	return this.langService.indirizzo();
}

PlaceholderIndirizzo(){
  return this.langService.indirizzoPlaceholder();
}



}
