import { Component, OnInit } from '@angular/core';
import {DocumentIconComponent} from '../document-icon/document-icon.component';
import {DocumentIconSellImmComponent} from '../document-icon-sell-imm/document-icon-sell-imm.component';
import {DocumentIconAuthComponent} from '../document-icon-auth/document-icon-auth.component';
import {DocumentIconBuyImmComponent} from '../document-icon-buy-imm/document-icon-buy-imm.component';
import {DocumentIconSellGoodsComponent} from '../document-icon-sell-goods/document-icon-sell-goods.component';
import {DocumentIconBuyCarComponent} from '../document-icon-buy-car/document-icon-buy-car.component';
import {DocumentIconLegacyComponent} from '../document-icon-legacy/document-icon-legacy.component';
import {DocumentIconExtComponent} from '../document-icon-ext/document-icon-ext.component';
import {KindDocument} from '../../kind-document';
import {StatusService} from '../../services/status.service';
import {NavBarComponent} from '../nav-bar/nav-bar.component';
import {Router} from '@angular/router';
@Component({
  selector: 'app-list-new-document',
  templateUrl: './list-new-document.component.html',
  styleUrls: ['./list-new-document.component.css']
})
export class ListNewDocumentComponent implements OnInit {

  constructor( private router:Router,private statusService:StatusService) { 

  	}

  	checkAccess(){
    if(!this.statusService.getAccess()){
        this.router.navigate(['']);
    }

   }

  ngOnInit() {
  	this.checkAccess();
  }




}
