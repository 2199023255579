import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID } from '@angular/core';
import localeIt from '@angular/common/locales/it';

import { registerLocaleData } from '@angular/common';
registerLocaleData(localeIt);


import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { UniqueEmailDirective } from './directives/unique-email.directive';
import { FromValidatorDirective } from './directives/from-validator.directive';
import { FilterPipe } from './filter.pipe';
import { EqualValidatorDirective } from './directives/equal-validator.directive';

import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';

import { LostpasswordComponent } from './components/lostpassword/lostpassword.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { CreateaccountComponent } from './components/createaccount/createaccount.component';
import { AnagraficaComponent } from './components/anagrafica/anagrafica.component';
import { AccountComponent } from './components/account/account.component';
import { AccountactivationComponent } from './components/accountactivation/accountactivation.component';
import { ActionchoiceComponent } from './components/actionchoice/actionchoice.component';
import { AmministratoreComponent } from './components/amministratore/amministratore.component';
import { BeneficiarioComponent } from './components/beneficiario/beneficiario.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { IconComponent } from './components/icon/icon.component';


import {ListNewDocumentComponent} from './components/list-new-document/list-new-document.component';
import {DocumentArchiveComponent} from './components/document-archive/document-archive.component';
import { DocumentIconComponent } from './components/document-icon/document-icon.component';
import { DocumentIconSellImmComponent } from './components/document-icon-sell-imm/document-icon-sell-imm.component';
import { SellImmComponent } from './components/documents/sell-imm/sell-imm.component';
import { DocumentIconExtComponent } from './components/document-icon-ext/document-icon-ext.component';
import { DocumentIconLegacyComponent } from './components/document-icon-legacy/document-icon-legacy.component';
import { DocumentIconBuyCarComponent } from './components/document-icon-buy-car/document-icon-buy-car.component';
import { DocumentIconSellGoodsComponent } from './components/document-icon-sell-goods/document-icon-sell-goods.component';
import { DocumentIconBuyImmComponent } from './components/document-icon-buy-imm/document-icon-buy-imm.component';
import { DocumentIconAuthComponent } from './components/document-icon-auth/document-icon-auth.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { BuyImmComponent } from './components/documents/buy-imm/buy-imm.component';
import { LegacyComponent } from './components/documents/legacy/legacy.component';
import { SellGoodsComponent } from './components/documents/sell-goods/sell-goods.component';
import { BuyCarComponent } from './components/documents/buy-car/buy-car.component';
import { ExtComponent } from './components/documents/ext/ext.component';
import { AuthComponent } from './components/documents/auth/auth.component';
import { OptionsComponent } from './components/options/options.component';
import { AddWindowComponent } from './components/add-window/add-window.component';
import { CreateOperatorComponent } from './components/create-operator/create-operator.component';
import { OperatorActivationComponent } from './components/operator-activation/operator-activation.component';


import { AccountOperatorComponent } from './components/account-operator/account-operator.component';

import { LoginOperatorComponent } from './components/login-operator/login-operator.component';
import { AddAdsComponent } from './components/add-ads/add-ads.component';
import { ViewAdsComponent } from './components/view-ads/view-ads.component';
import { RicorrenteComponent } from './components/ricorrente/ricorrente.component';
import { RicorsoComponent } from './components/ricorso/ricorso.component';

import { RicorsiArchiveComponent } from './components/ricorsi-archive/ricorsi-archive.component';
import { RicorrenteModaleComponent } from './components/ricorrente-modale/ricorrente-modale.component';
import { RichiestaCopieComponent } from './components/richiesta-copie/richiesta-copie.component';

import { DetailsPanelComponent } from './components/details-panel/details-panel.component';
import { ElencoParentiComponent } from './components/elenco-parenti/elenco-parenti.component';
import { ExtraRicorsoComponent } from './components/extra-ricorso/extra-ricorso.component';

import { EditOperatorComponent } from './components/edit-operator/edit-operator.component';
import {AdministratorEditComponent} from './components/administrator-edit/administrator-edit.component';
import {KindAccountComponent} from './components/kind-account/kind-account.component';
import { BsModalModule } from 'ng2-bs3-modal';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { SafeHtmlPipe } from './safe-html.pipe';
import { DraftButtonComponent } from './components/draft-button/draft-button.component';
import { IconListComponent } from './components/icon-list/icon-list.component';
import {HttpErrorInterceptor} from './http-error-interceptor';
import {InformativaComponent} from './components/informativa/informativa.component';
import { ComuneComponent } from './components/comune/comune.component';
import { PasswordStrengthBarComponent } from './components/password-strength-bar/password-strength-bar.component';
import { NotaRuoloComponent } from './components/nota-ruolo/nota-ruolo.component';
import { FilterStatoNominaPipe } from './filter-stato-nomina.pipe';
import { InputDomicilioDigitaleComponent } from './components/input-domicilio-digitale/input-domicilio-digitale.component';
import { AutofocusDirective } from './directives/autofocus.directive';

@NgModule({
  declarations: [
    AppComponent,
    UpperCaseDirective,
    UniqueEmailDirective,
    FromValidatorDirective,
    FilterPipe,
    EqualValidatorDirective,
     LoginComponent,

    LostpasswordComponent,
    ResetpasswordComponent,
    CreateaccountComponent,
    AnagraficaComponent,
    AccountComponent,
    AccountactivationComponent,
    ActionchoiceComponent,
    AmministratoreComponent,
    BeneficiarioComponent,
    DashboardComponent,
    IconComponent,
    DocumentArchiveComponent,
     ListNewDocumentComponent,
    DocumentIconComponent,
    DocumentIconSellImmComponent,
    SellImmComponent,
    DocumentIconExtComponent,
    DocumentIconLegacyComponent,
    DocumentIconBuyCarComponent,
    DocumentIconSellGoodsComponent,
    DocumentIconBuyImmComponent,
    DocumentIconAuthComponent,
    NavBarComponent,
    BuyImmComponent,
    LegacyComponent,
    SellGoodsComponent,
    BuyCarComponent,
    ExtComponent,
    AuthComponent,
    OptionsComponent,
    AddWindowComponent,
    CreateOperatorComponent,
    OperatorActivationComponent,

    AccountOperatorComponent,

    LoginOperatorComponent,
    AddAdsComponent,
    ViewAdsComponent,
    RicorrenteComponent,
    RicorsoComponent,

    RicorsiArchiveComponent,
    RicorrenteModaleComponent,
    RichiestaCopieComponent,

    DetailsPanelComponent,
    ElencoParentiComponent,
    ExtraRicorsoComponent,

    EditOperatorComponent,
    AdministratorEditComponent,
    KindAccountComponent,
    SafeHtmlPipe,
    DraftButtonComponent,
    IconListComponent,
    InformativaComponent,
    ComuneComponent,
    PasswordStrengthBarComponent,
    NotaRuoloComponent,
    FilterStatoNominaPipe,
    InputDomicilioDigitaleComponent,
    AutofocusDirective,

  ],
  imports: [
  FormsModule,
  HttpClientModule,
  BsModalModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PdfViewerModule,
     FlashMessagesModule.forRoot(),
  ],
  providers: [{provide: LOCALE_ID, useValue: 'it-IT'},{
     provide: HTTP_INTERCEPTORS,
     useClass: HttpErrorInterceptor,
     multi: true
   }],
  bootstrap: [AppComponent]
})
export class AppModule { }
/*
platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [{provide: LOCALE_ID, useValue: 'it-IT' }]
});*/
