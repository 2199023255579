import { Component, OnInit,ViewChild } from '@angular/core';
import {DbService} from '../../services/db.service';
import { HttpClient } from '@angular/common/http';
import {Amministratore} from '../../models/amministratore';
import {LangService} from '../../services/lang.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {StatusService} from '../../services/status.service';
import {Account} from '../../models/account';
import {Resp} from '../../models/resp';

@Component({
  selector: 'input-amministratore',
  templateUrl: './amministratore.component.html',
  styleUrls: ['./amministratore.component.css'],
  providers:[LangService,DbService]
})
export class AmministratoreComponent implements OnInit {

  constructor(private router:Router, private activatedRoute: ActivatedRoute,private dbService:DbService, private http:HttpClient, private langService:LangService, private statusService:StatusService) {
     this.hideUpdateSuccessMessage=true;
      this.idAccount=this.activatedRoute.snapshot.params['idAccount'];
      console.log("l'id ricevuto dal router è : ",this.idAccount)
      this.dbService.getAmministratore(this.idAccount)
        /*.map(res => {
           if(res.status==204)
             throw new Error("Utente/email inesistente");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



         }


        )*/
        .subscribe(data => this.updateAmministratore((data as Resp).data));

   }

  amministratore= new Amministratore();
  idAccount=0;
   hideUpdateSuccessMessage:boolean;
  saveAdminSuccessMsg:string;

  checkAccess(){
    if(!this.statusService.getAccess()){
        this.router.navigate(['']);
    }
  }

  ngOnInit() {
    this.checkAccess();
      
  }

   @ViewChild('amministratoreForm', {static:false}) form;

  saveAdministrator(){

      this.form.controls.telefono.updateValueAndValidity();
      this.form.controls.cellulare.updateValueAndValidity();

      if(this.form.valid){
    

  this.amministratore.idAccount=this.idAccount
  	
   
   
    
  
 
  	this.dbService.updateAmministratore(this.amministratore)
  			/*.map(res => {
           if(res.status==204)
             throw new Error("Utente/email inesistente");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



         }


  			)*/
  			.subscribe(data => this.showSuccessMsg(data));
       }
  }


  LabelNumero(){
    return this.langService.numero();
  }


backToMain(){
  return this.langService.backToMain();
}
  




    showSuccessMsg(data){
      console.log("id di getId: ",this.statusService.getId());
      this.dbService.getAccountFromId(this.statusService.getId())
      /* .map(res => {
          if(res.status==204){
            throw new Error("account inesistente");
           
            
          }
          if(res.status==406){
               throw new Error("errore di rete");
             
          }
          if(res.status==200)
            return res.json();

        })*/
        .subscribe(
          data => this.processAccount((data as Resp).data),
          err => this.processError(err+"errore da get account from id")
          );
   
    	this.hideUpdateSuccessMessage=false;
    	this.saveAdminSuccessMsg=this.langService.updateSuccessMsg();
      console.log(data);

    }


    processAccount(data){
      console.log("sono la processAccount");
      let acc:Account=data;
       acc.completeAds=true;
       console.log(acc);
       this.dbService.completeAds(acc)
              /*.map(res => {
          if(res.status==204){
            throw new Error("account inesistente");
           
            
          }
          if(res.status==406){
               throw new Error("errore di rete");
             
          }
          if(res.status==200)
            return res.json();

        })*/
        .subscribe(
          data => this.processUpdateAccount((data as Resp).data),
          err => this.processError(err+" errore da update account su db")
          );


    }


    processError(err){
      alert(err);
    }

    processUpdateAccount(acc){
      this.statusService.updateAccount(acc);
      console.log("update effettuato con successo");
    }

  updateAmministratore(amm){
    console.log("mi arriva: ", amm);
  
  		this.amministratore=amm;
  	
  	console.log("dovrei aver salvato: ",this.amministratore);
  }

  LabelTribunale(){
  	return this.langService.tribunale();
  }

  LabelTelefono(){
  	return this.langService.telefono();
  }

  LabelCellulare(){
  	return this.langService.cellulare();
  }

  LabelTutela(){
  	return this.langService.inizioTutela();
  }


}
