import { Component, ViewChild, OnInit ,Input} from '@angular/core';
import {LangService} from '../../services/lang.service';
import {Account} from '../../models/account';


@Component({
  selector: 'account-operator',
  templateUrl: './account-operator.component.html',
  styleUrls: ['./account-operator.component.css']
})
export class AccountOperatorComponent implements OnInit {

   constructor(private langService:LangService) { }

  ngOnInit() {

  }

  @ViewChild('accountForm',{static:false}) form;

	submitForm(){
		this.form.onSubmit();
	}

	send(){

			
		   return (this.form.valid);
	}


  account=new Account();

passwordMismatch(){
    return this.langService.passwordMismatch();
  }
  emailMismatch(){
    return this.langService.emailMismatch();
  }

  LabelEmail(){
  	return this.langService.email();
  }

  LabelRipetiEmail(){
  	return this.langService.ripetiEmail();
  }

  LabelPassword(){
  	return this.langService.password();
  }

  LabelRipetiPassword(){
  	return this.langService.ripetiPassword();
  }

}
