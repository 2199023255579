import {Injectable} from '@angular/core';

@Injectable()

export class DataEsportaAnagrafiche {

    public ricorrenti:boolean;
    public beneficiario:boolean;
    public parenti:boolean;

    constructor(ric = true, bene = true, parenti = false)
    {
        this.ricorrenti=ric;
        this.beneficiario = bene;
        this.parenti = parenti;
    }
}

export class DataEsportaAnagraficheAds {

    public nomeCognome:boolean;
    public email:boolean;
    public completa:boolean;

    constructor(nomeCognome = true, email = true, completa = false)
    {
        this.nomeCognome=nomeCognome;
        this.email = email;
        this.completa = completa;
    }
}
