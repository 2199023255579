import { Injectable } from '@angular/core';
import {IndirizzoStrutturato} from './indirizzo';



export class Anagrafica {

	constructor(public nome:string="",public cognome:string="",public dataDiNascita:string="",public codiceFiscale:string="", public luogoDiNascita:string="",public indirizzo:string="", public sesso:string="",public indirizzoStrutturato:IndirizzoStrutturato=new IndirizzoStrutturato(), public telefono:string="", public cellulare:string="",public email:string=""){

	}
}
