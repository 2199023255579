import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStatoNomina'
})
export class FilterStatoNominaPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
      if(value == 0)
          return 'attivo';
      if(value == 1)
          return 'decesso benef.';
      if(value == 2)
          return 'decesso AdS';
      if(value == 3)
          return 'avvicendamento';
  }

}
