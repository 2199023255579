import { Component, OnInit } from '@angular/core';
import { DocumentIconComponent } from '../document-icon/document-icon.component';

import {AppSettings} from '../../app-settings';
import {DbService} from '../../services/db.service';
import {StatusService} from '../../services/status.service'; 
@Component({
  selector: 'documentIconLegacy',
  templateUrl: './document-icon-legacy.component.html',
  styleUrls: ['./document-icon-legacy.component.css'],
  providers: [DbService]
})
export class DocumentIconLegacyComponent extends DocumentIconComponent {


}
