
import { Injectable } from '@angular/core';


import {Md5} from "md5-typescript";
 


@Injectable()

export class AccountOperator {

	constructor(public idOperatore:number=0,public idSportello:number=0,public email:string="",public password:string="",public anagrafica:string="",public kind:string=""){}


	setKind(kind:string){
		this.kind=kind;
	}

	cypherPwd(){
		
		console.log(Md5.init(this.password).toString());
		 this.password=Md5.init(this.password).toString();
	}

	
}
