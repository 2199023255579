import { Injectable } from '@angular/core';

/*

questa è l'anagrafica dello sportello;
email e url sono i dati a mettere nell'informativa della privacy.
L'e-mail è l'indirizzo a cui scrivere (da indicare nell'informativa);
l'url è l'url da indicare nell'informativa.


*/


@Injectable()
export class FrontOffice {

	constructor(public idSportello=0, nomeSportello:string="", indirizzo:string="", telefono:string="",cf:string="",emial:string="", url:string=""){}
}
