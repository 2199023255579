import { Injectable } from '@angular/core';





@Injectable()

export class AppSettings {


    public static get API_ENDPOINT(): string {

     /*
     //let mode = 'prod';
    let mode = 'dev';  // correct one (set this before pushing to origin)
	 	else if(mode=='dev')
			return  '';
		else
			//return '/gest';
			return '';
    */
    return '';
  }

}
