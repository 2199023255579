import {Injectable} from '@angular/core';


@Injectable()


export class DataDettagliExtraRicorso{


	public nomeDocumento:string;
	public nomeFile:string;
	

	constructor(  nomeDocumento:string="",nomeFile:string=""){
		this.nomeDocumento=nomeDocumento;
		this.nomeFile=nomeFile;
		
	}



}

