import { Component, ViewChild, OnInit ,Input,Output, EventEmitter} from '@angular/core';
import {LangService} from '../../services/lang.service';
import {Account} from '../../models/account';




@Component({
  selector: 'informativa',
  templateUrl: './informativa.component.html',
  styleUrls: ['./informativa.component.scss']
})
export class InformativaComponent implements OnInit {

   @Input() mode;
  @Output() openInfo=new EventEmitter;

  openInformativa(){
    this.openInfo.emit();
  }
    isStandard(){
    return this.mode=='standard';
  }
  isDirect(){
    return this.mode=='direct';
  }

	
  constructor(private langService:LangService) { }

  ngOnInit() {
  }
  
	@ViewChild('accountForm',{static:false}) form;

	submitForm(){
		this.form.onSubmit();
	}

	send(){

			
		   return (this.form.valid);
	}


  account=new Account();

passwordMismatch(){
    return this.langService.passwordMismatch();
  }
  emailMismatch(){
    return this.langService.emailMismatch();
  }

  LabelEmail(){
  	return this.langService.email();
  }

  LabelRipetiEmail(){
  	return this.langService.ripetiEmail();
  }

  LabelPassword(){
  	return this.langService.password();
  }

  LabelRipetiPassword(){
  	return this.langService.ripetiPassword();
  }

}
