import { Component, OnInit, ViewChild } from '@angular/core';
import {IconComponent} from '../icon/icon.component';
import {StatusService} from '../../services/status.service';
import {DbService} from '../../services/db.service';
import {Router, ActivatedRoute} from '@angular/router';
import {LangService} from '../../services/lang.service';

import {Anagrafica} from '../../models/anagrafica';
import {Account} from '../../models/account';
import {Amministratore} from '../../models/amministratore';
import {Resp} from '../../models/resp';

import * as _ from 'lodash';

@Component({
  selector: 'app-administrator-edit',
  templateUrl: './administrator-edit.component.html',
  styleUrls: ['./administrator-edit.component.css'],
  providers:[DbService,LangService]

})
export class AdministratorEditComponent implements OnInit {



	public account:Account;
	public anagrafica:Anagrafica;
	public amministratore:Amministratore;
	public updateAccountMessage:string;  // Luke 17/02/2021 : messaggio "campi non validi" per l'utente
  public invalidFormMessage:string;

	public hideCreateAccuontMessage:boolean;

	public _idAccount:number;



  constructor(public langService:LangService, private route:ActivatedRoute, private router:Router,private statusService:StatusService, private dbService:DbService) {
  	this.account=new Account();
  	console.log("account init: ",this.account);
  	this.anagrafica=new Anagrafica();
  	console.log("ana init: ",this.anagrafica);
  	this.amministratore=new Amministratore();

  	}



ngOnInit(){

	this.updateAccountMessage="amministratore aggiornato con successo!";
  this.invalidFormMessage="completa il form per poter effettuare l'aggiornamento";  // Luke 17/02/2021 : messaggio "campi non validi" per l'utente
	 this._idAccount=this.route.snapshot.params['idAccount'];
	 console.log("idAccount: ",this._idAccount);

	 this.dbService.getAmministratore(this._idAccount)
	 	//.map(res =>res.json())
        .subscribe(data => this.setAnagrafica((data as Resp).data));

     this.hideCreateAccuontMessage=true;
}


setAnagrafica(ana){
	console.log("sono la setAnagrafica: ",ana);
	this.anagrafica.nome=ana.nome;
	this.anagrafica.cognome=ana.cognome;
	this.anagrafica.dataDiNascita=ana.dataDiNascita;
	this.anagrafica.codiceFiscale=ana.codiceFiscale;
	this.anagrafica.luogoDiNascita=ana.luogoDiNascita;
	this.anagrafica.indirizzo=ana.indirizzo;
	this.anagrafica.sesso=ana.sesso;

  // Luke 16/02/2021 : caricamento indirizzo strutturato da anagrafica
  if(ana.indirizzoStrutturato!=undefined){
    this.anagrafica.indirizzoStrutturato=_.cloneDeep(ana.indirizzoStrutturato);
   }
  // Luke 16/02/2021 : caricamento indirizzo strutturato da anagrafica - END

	this.amministratore.telefono=ana.telefono;
	this.amministratore.cellulare=ana.cellulare;
  this.amministratore.kind=ana.ruolo;
	this.setAccount(ana);
}

setAccount(acc){
	this.account.email=acc.email;
  let valida=+acc.emailValida;


  if(valida==1)
    this.account.emailValida=true;
  else
    this.account.emailValida=false;
}


ngOnDestroy(){


}


@ViewChild('anagraficaComponent', {static:false}) anaComponent;

@ViewChild('numeriForm', {static:false}) numeriForm;
@ViewChild('accountForm', {static:false}) accountForm;
 @ViewChild('kindAccount', {static:false}) kindAccount;

 updateAccuont(account:boolean,anagrafica:boolean,kindAccount:boolean,telefono:boolean,ana:Anagrafica){
   		this.hideCreateAccuontMessage=true;
   		this.anaComponent.form.onSubmit();
   		this.accountForm.onSubmit();
   		this.numeriForm.onSubmit();
      this.kindAccount.form.onSubmit();

   		if(account && anagrafica && kindAccount && telefono){
   			console.log("campi validi aggiorno");
   		} else console.log("campi non validi, non aggiorno");

   		if(!account){
   			console.log("errore di validazione in account");
   		}

   		if(!anagrafica){
   			console.log("errore di validazione in anagrafica");
   		}

   		if(!telefono){
   			console.log("errore di validazione in telefono");
   		}


       if(account && anagrafica && kindAccount && telefono){

       		//faccio update su db e alla fine

           // Luke 16/02/2021 : salvataggio indirizzo strutturato amministratore
       		let accountToSave={'idAccount':this._idAccount,'emailValida':this.account.emailValida,'nome':ana.nome,'cognome':ana.cognome,'codiceFiscale':ana.codiceFiscale,'dataDiNascita':ana.dataDiNascita,'luogoDiNascita':ana.luogoDiNascita,'indirizzo':ana.indirizzo,'sesso':ana.sesso,'cellulare':this.amministratore.cellulare,'telefono':this.amministratore.telefono,'email':this.account.email,'ruolo':this.amministratore.kind, 'indirizzoStrutturato': ana.indirizzoStrutturato};

       		console.log('vorrei salvere: ',accountToSave);

       		this.dbService.updateAccountAmministratore(accountToSave)
       	  .subscribe(
       					data => this.confirmUpdate(data),
              			err => console.error(err)
       					);
       }


  }


  confirmUpdate(data){
  	this.updateAccountMessage=data.msg;
  	this.hideCreateAccuontMessage=false;
  }


asswordMismatch(){
    return this.langService.passwordMismatch();
  }
  emailMismatch(){
    return this.langService.emailMismatch();
  }

  LabelEmail(){
  	return this.langService.email();
  }

  LabelRipetiEmail(){
  	return this.langService.ripetiEmail();
  }

  LabelPassword(){
  	return this.langService.password();
  }

  LabelRipetiPassword(){
  	return this.langService.ripetiPassword();
  }
  LabelTelefono(){
  	return this.langService.telefono();
  }

LabelCellulare(){
  	return this.langService.cellulare();
  }


}
