import { Component, OnInit } from '@angular/core';
import {StatusService} from '../../services/status.service';
import {DbService} from '../../services/db.service';
import {Account } from '../../models/account';
import {HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {Router} from '@angular/router';
 import { ViewChild} from '@angular/core';
 import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import {catchError} from 'rxjs/operators';
import {Resp} from '../../models/resp';
import {of} from 'rxjs';

@Component({
  selector: 'app-edit-operator',
  templateUrl: './edit-operator.component.html',
  styleUrls: ['./edit-operator.component.css'],
   providers: [DbService]
})
export class EditOperatorComponent implements OnInit {
 // se includo langService non funzina più la componente
  constructor( private dbService:DbService,private router:Router,  private statusService:StatusService) { }

    private list;
    private account;

    private idSportello;

    private editWorkingIndex:number;



    @ViewChild('deleteModal',{static:false}) deleteModal:BsModalComponent;
    private idToDelete:number;
    private deleteShowError:boolean;
    private nome_selezionato:string;
    private deleteErrorText:string;
    private successMessage:boolean;

    dismissedDeleteModal() {
        this.deleteShowError=false;
         this.successMessage=false;
    }

    openedDeleteModal() {
      this.deleteShowError=false;
       this.successMessage=false;
       
    }

   
    openDeleteModal() {
        this.deleteModal.open();
    }

  

    setModalDelete(nome:string,id:number){
      this.idToDelete=id;
      this.nome_selezionato=nome;
      this.deleteModal.open();
    }
    deleteConfirmModal(){
        console.warn("cancellazione confermata");
        this.dbService.deleteOperatorFromId(this.idToDelete)
        //(res => res.json())
        .subscribe(data =>this.processDeleteOperatore(data));
    }
  

    processDeleteOperatore(data){

      console.warn("cancellazione effettuata dal db");
      let time=0;
      if(data.error!=''){
        this.deleteShowError=true;
        this.deleteErrorText=data.error;
        time=4400;

      }else{
        this.deleteShowError=false;
        this.successMessage=true;
       time=2200;
      }


       setTimeout( () => {  this.deleteModal.close(); }, time );

      this.updateOperatorList();

      

    }

    /* modale modifica operatore */

    private showEditModaleSuccess:boolean;
    private EditModaleSuccess:string;
    private editEmail:string;
    private editRipetiEmail:string;
    private editAnagrafica:string;
    private editId:number;

    @ViewChild('EditModale',{static:false}) EditModale:BsModalComponent;

  closedEditModale(){}

  dismissedEditModale(){}

  openedEditModale(){
    this.showEditModaleSuccess=false;
  }

  openEditModale(idx){
   this.setEditModale(idx);
   
  }

  setEditModale(idx){
    this.editWorkingIndex=idx;
   
    this.EditModale.open();
    this.editEmail=this.list[this.editWorkingIndex].email;
    this.editAnagrafica=this.list[this.editWorkingIndex].anagrafica;
  }



sendFormEditModale(){

  console.warn("updte form click");
  var opUpdate={"idAccount":this.editId,"email":this.editEmail,"anagrafica":this.editAnagrafica,"ripetiEmail":this.editRipetiEmail};

  console.log(opUpdate);
  var json=JSON.stringify(opUpdate);
  console.log(json);

  this.dbService.updateAccountOperatorAnagrafica(json)
 // .map(res => res.json())
  .subscribe(data => this.successEditModaleForm(data) );

}

getEditModaleSuccess(){
    return this.showEditModaleSuccess;
  }

  successEditModaleForm(data){
    console.log("EditModale aggiornato con successo");
    this.showEditModaleSuccess=true;
console.log(this.showEditModaleSuccess);
    
    this.EditModaleSuccess=data.msg;

    if((data as Resp).data.email===(data as Resp).data.ripetiEmail)
      this.list[this.editWorkingIndex].email=(data as Resp).data.email;
    this.list[this.editWorkingIndex].anagrafica=(data as Resp).data.anagrafica;

     setTimeout( () => {   this.showEditModaleSuccess=false; 
console.log(this.showEditModaleSuccess);
     }, 4000 );  

     //this._flashMessagesService.show(msg, { cssClass: 'alert-success', timeout: 2000 });
  
  }




    /* fine modale modifica operatore */



  checkAccess(){
    if(!this.statusService.getAccess()){
        this.router.navigate(['']);
    }

  }


updateOperatorList(){
     this.account=this.statusService.getSpecialAccount();

     //console.warn("init RicrosoArchive - account:",this.account);


  if(this.account.kind=='opr' && !this.statusService.getSpecialAccount().head){
    console.warn("sono un operatore");
    this.idSportello=this.statusService.getIdSportello();
    console.warn("idSportello: ",this.idSportello);
    if(this.idSportello!=0){
        console.warn("idSpertelo != 0");
      this.dbService.loadOperatorListByWindow(this.idSportello).pipe(
        catchError((error)=>{
          return this.handleError(error)
        }))
     // .map(res=>res.json())
    //  .catch(this.handleError)
      .subscribe(data =>this.logList(this.list=(data as Resp).data));

    }
   }
   if(this.account.kind=='adm' || this.statusService.getSpecialAccount().head){
     console.warn("sono admin or head (responsbile)");
    //if(this.idSportello==0){
      this.dbService.loadOperatorListGlobal().pipe(
        catchError((error)=>{
          return this.handleError(error)
        })
        )
     // .map(res=>res.json())
    //  .catch(this.handleError)

      .subscribe(data =>this.logList(this.list=(data as Resp).data));

     //}
    }


}

  ngOnInit() {
    this.checkAccess();
  	this.list=[];
  
    this.updateOperatorList();
  }

    logList(list){
  	console.log("recupero list terminato");
    console.log(list);

  }

  showState(state){
  	
  	if(state==1)
  		return 'greenState';
  	
  	else return 'redState';
  }

  editState(idAccount,idx){
  	console.log("edit state click idAccount ",idAccount);
  	console.log("lo stato attuale vale: ",this.list[idx].stato);
  	

  	



  	//modifico in db;
    this.dbService.toggleAccountOperatorById(idAccount,this.list[idx].stato)
    //.map(res => res.json())
    .subscribe(data => this.updateStateToggle(data,idx));

  }


  updateStateToggle(data,idx){
    console.log(data);
    //in data avrei la conferma del cambiamento di stato
      if(this.list[idx].stato==1)
        this.list[idx].stato=0;
      else this.list[idx].stato=1;
      
  }

  showKind(kind,supervisor){
  	if(kind=='opr' && supervisor==1){
  		return 'supervisore';
  	}
  	if(kind=='opr' && supervisor==0){
  		return 'operatore';
  	}
  	if(kind=='adm'){
  		return 'amministratore del sistema';
  	}

  }


  deleteOperator(anagrafica,idAccount){
    this.openedDeleteModal();
  	this.setModalDelete(anagrafica,idAccount);
  	console.log("del click");

  }

  editOperator(idAccount,idx){
    this.editId=idAccount;
  	this.setEditModale(idx);
  	console.log("edit click");
  }



  handleError(error:Response){
  	console.log("loggo l'errore:",error);
        console.log("statusText: ",error.statusText);
        this.list=[];
        return of(error);
  }
}
