import { Component, ViewChild, OnInit } from '@angular/core';
import {Amministratore} from '../../models/amministratore';
import {AnagraficaComponent} from '../anagrafica/anagrafica.component';
import {AccountComponent} from '../account/account.component';
import {LangService} from '../../services/lang.service';
import {Anagrafica} from '../../models/anagrafica';
import {Account} from '../../models/account';
import {DbService} from '../../services/db.service';
import {DocService} from '../../services/doc.service';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {AppSettings} from '../../app-settings';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {Username} from '../../models/username';
import {StatusService} from '../../services/status.service';
import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { DataSportello} from '../../data/data-sportello';
import {Resp } from '../../models/resp';



@Component({
  selector: 'app-createaccount',
  templateUrl: './createaccount.component.html',
  styleUrls: ['./createaccount.component.css'],
  providers: [Amministratore, AnagraficaComponent, LangService, DbService]
})


export class CreateaccountComponent implements OnInit {









  /*fine metodi modale */


  constructor(private docService: DocService, private router: Router, private statusService: StatusService, private route: ActivatedRoute, private dbService: DbService, private langService: LangService, private http: HttpClient) {

   }

  public amministratore: Amministratore;

   /* metodi modale */

  @ViewChild('informativaModal', {static: false})
    modal: BsModalComponent;

    selected: string;
    output: string;


    backdropOptions = [true, false, 'static'];
    cssClass = '';

    animation = true;
    keyboard = true;
    backdrop: string | boolean = true;
    css = false;

   user: Username;
   mode: string;
   pathInformativa: string;

   localSportello = new DataSportello;


 @ViewChild('account', {static: false}) accComponent;
 @ViewChild('anagrafica', {static: false}) anaComponent;
 @ViewChild('numeriForm', {static: false}) numeriForm;
 @ViewChild('kindAccount', {static: false}) kindAccount;



   disableCreateAccountForm = false;
   createAccountMessage = 'profilo creato con successo! Ti abbiamo inviato un messaggio di attivazione. Accedi al tuo profilo di posta elettronica e segui le istruzioni.';
   hideCreateAccuontMessage = true;
   accAnagrafica = new Anagrafica();

   closed() {
        }

    dismissed() {

    }

    opened() {

    }


    openInformativa() {
        this.modal.open();
    }

  ngOnInit() {

    this.amministratore = new Amministratore();
    this.mode = this.route.snapshot.params.mode;  // Luke 9/3/2021 : mode è l'ultima parte dell'url (es. 'direct')
    this.user = new Username();



    this.dbService.getWindowDetails(this.statusService.getIdSportello())
     // .map(res => res.json())
      .subscribe(data => this.updateWindowDetails((data as Resp).data));

  }

  updateWindowDetails(data) {
    console.log(data);
    this.localSportello.nomeSportello = data.nomeSportello;
    this.localSportello.cf = data.cf;
    this.localSportello.comune = data.comune;
    this.localSportello.indirizzo = data.indirizzo;
    this.localSportello.responsabile = data.responsabile;
    this.localSportello.telefono = data.telefono;
    this.localSportello.email = data.email;


    const toSend = {sportello: data};
    const json = JSON.stringify(toSend);
    console.log(json);
    if (this.isDirect()) {
      this.docService.getPrivacy(this.localSportello)
         .subscribe(data => this.pathInformativa = (data as any).fileName);
    }

  }
  getNomeOnlus() {
    return this.localSportello.nomeSportello;
  }
  getIndirizzoOnlus() {
    return this.localSportello.indirizzo;
  }
  getTelOnlus() {
    return this.localSportello.telefono;
  }
  getCfOnlus() {
    return this.localSportello.cf;
  }
  getReferente() {
    return this.localSportello.responsabile;
  }

  isStandard() {
    return this.mode == 'standard';
  }
  isDirect() {
    return !this.isStandard();
  }

    backToLogIn() {
      return this.langService.backToLogIn();
    }

   createAccount(account: boolean, anagrafica: boolean, kindAccount: boolean, ana: Anagrafica, acc: Account) {
   		   console.log('createAnagrafica invocata');
        this.accComponent.form.onSubmit();
        console.log('account component send invocata');
   		   this.anaComponent.form.onSubmit();
        console.log('anagrafica component send invocata');
        this.numeriForm.onSubmit();
        console.log('numeri form send invocata');
        this.kindAccount.form.onSubmit();
        console.log('kind account component send invocata');


   		   if (account && anagrafica && kindAccount && this.numeriForm.valid) {

         console.log('account, anagrafica kindAccount sono validi');


         console.log('sono la createaccount.component.ts, anagrafica vale: ', ana);

         const idSportello = this.statusService.getIdSportello();
         if (idSportello != 0) {

   			this.dbService.createAccount(acc, ana, this.amministratore, 'ads', this.mode, idSportello)
   			/*	.map(res => {
     			if(res.status==204)
     				throw new Error("profilo non creato");

     			if(res.status==406)
     				 throw new Error("errore di rete");

     			if(res.status==200)
     				return res.json();



     		})*/
   				.subscribe(
   					data => this.sendActivationEmail(acc),
          			err => console.error(err)
   					);
   		}
     }

 }



   createAccountFeedback(data) {
     if (data.success) {
       this.disableCreateAccountForm = true;
       this.hideCreateAccuontMessage = false;
      } else {
        this.createAccountMessage = 'Il profilo &egrave; stato creato ma si &egrave; verificato un errore nell\'attivazione. Se il problema persiste contattaci.';

        this.hideCreateAccuontMessage = false;
      }



   }

     sendActivationEmail(user) {

       console.log('sendActivationEmail loggo user: ', user);

       if (this.mode == 'standard') {


      const data = {email: user.email};

      const json = JSON.stringify(data);

      const params = json;


      const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
      return this.http.post(`${AppSettings.API_ENDPOINT}/php/sendActivationEmail.php`, params, {headers})
      // .map(res=>res.json())
      // .catch(this.handleError)
      .subscribe(data => this.createAccountFeedback(data));

     }

       if (this.mode == 'direct') {
         this.user.userName = user.email;
         this.user.password = user.password;

         this.dbService.linkAccount(this.user, this.statusService.getSpecialAccount())
          /*    .map(res =>{
           if(res.status==204)
             throw new Error("Utente/email inesistente");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



         })*/
              .subscribe(
              data => this.setAccount(data),
              err => console.error(err + 'errore da login standard')
              );
     }

   }


private setAccount(datum) {
  console.warn('set account chiamta');
  console.log(datum);


  if (datum.status == 'Success') {
    console.log('sono su ramo success');


     // carico profilo ads in area di lavoro
    this.statusService.setLoadedAccount(datum.data);

      // carico cognome dell'ads caricaato su navbar
    this.dbService.getAmministratore(this.statusService.getId())
      // .map(res => res.json())
      .subscribe( data =>  this.statusService.updateSurname((data  as Amministratore).cognome));
    console.log('loggo idAccount da statusService: ', this.statusService.getId());
    alert('amministratore di sostegno aggregato con successo!');

    this.router.navigate(['/dashboard']);
      // this.salvaCookie();

    } else if (datum.status == 'Failure') {
     console.log('sono su ramo failure');


     // this.unsetCookie();
      // this.execLogOut();*/
   }

  console.log('da submit logIn:', datum);



}

  LabelTelefono() {
    return this.langService.telefono();
  }

LabelCellulare() {
    return this.langService.cellulare();
  }



}
