import {Injectable} from '@angular/core';

@Injectable()
export class DataSellImm {
	public cat1:string;
	public data:string;
	public causale:string;
	public perito:string;
	public realizzo:string;
	public prop:string;
	public print:boolean;
	public view:boolean;

	constructor(catasto:string="", d:string="",cau:string="", pe:string="",realizzo:string="",prop:string="",print:boolean=false,view:boolean=false){
		this.cat1=catasto;
		this.data=d;
		this.causale=cau;
		this.perito=pe;
		this.realizzo=realizzo;
		this.prop=prop;
		this.print=print;
		this.view=view;
	}
}


