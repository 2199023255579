import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';

import { LostpasswordComponent } from './components/lostpassword/lostpassword.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { CreateaccountComponent } from './components/createaccount/createaccount.component';
import { AnagraficaComponent } from './components/anagrafica/anagrafica.component';
import { AccountComponent } from './components/account/account.component';
import { AccountactivationComponent } from './components/accountactivation/accountactivation.component';
import { ActionchoiceComponent } from './components/actionchoice/actionchoice.component';
import { AmministratoreComponent } from './components/amministratore/amministratore.component';
import { BeneficiarioComponent } from './components/beneficiario/beneficiario.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { IconComponent } from './components/icon/icon.component';


import {ListNewDocumentComponent} from './components/list-new-document/list-new-document.component';
import {DocumentArchiveComponent} from './components/document-archive/document-archive.component';
import { DocumentIconComponent } from './components/document-icon/document-icon.component';
import { DocumentIconSellImmComponent } from './components/document-icon-sell-imm/document-icon-sell-imm.component';
import { SellImmComponent } from './components/documents/sell-imm/sell-imm.component';
import { DocumentIconExtComponent } from './components/document-icon-ext/document-icon-ext.component';
import { DocumentIconLegacyComponent } from './components/document-icon-legacy/document-icon-legacy.component';
import { DocumentIconBuyCarComponent } from './components/document-icon-buy-car/document-icon-buy-car.component';
import { DocumentIconSellGoodsComponent } from './components/document-icon-sell-goods/document-icon-sell-goods.component';
import { DocumentIconBuyImmComponent } from './components/document-icon-buy-imm/document-icon-buy-imm.component';
import { DocumentIconAuthComponent } from './components/document-icon-auth/document-icon-auth.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { BuyImmComponent } from './components/documents/buy-imm/buy-imm.component';
import { LegacyComponent } from './components/documents/legacy/legacy.component';
import { SellGoodsComponent } from './components/documents/sell-goods/sell-goods.component';
import { BuyCarComponent } from './components/documents/buy-car/buy-car.component';
import { ExtComponent } from './components/documents/ext/ext.component';
import { AuthComponent } from './components/documents/auth/auth.component';
import { OptionsComponent } from './components/options/options.component';
import { AddWindowComponent } from './components/add-window/add-window.component';
import { CreateOperatorComponent } from './components/create-operator/create-operator.component';
import { OperatorActivationComponent } from './components/operator-activation/operator-activation.component';


import { AccountOperatorComponent } from './components/account-operator/account-operator.component';

import { LoginOperatorComponent } from './components/login-operator/login-operator.component';
import { AddAdsComponent } from './components/add-ads/add-ads.component';
import { ViewAdsComponent } from './components/view-ads/view-ads.component';
import { RicorrenteComponent } from './components/ricorrente/ricorrente.component';
import { RicorsoComponent } from './components/ricorso/ricorso.component';

import { RicorsiArchiveComponent } from './components/ricorsi-archive/ricorsi-archive.component';
import { RicorrenteModaleComponent } from './components/ricorrente-modale/ricorrente-modale.component';
import { RichiestaCopieComponent } from './components/richiesta-copie/richiesta-copie.component';

import { DetailsPanelComponent } from './components/details-panel/details-panel.component';
import { ElencoParentiComponent } from './components/elenco-parenti/elenco-parenti.component';
import { ExtraRicorsoComponent } from './components/extra-ricorso/extra-ricorso.component';

import { EditOperatorComponent } from './components/edit-operator/edit-operator.component';
import {AdministratorEditComponent} from './components/administrator-edit/administrator-edit.component';
import {KindAccountComponent} from './components/kind-account/kind-account.component';



const routes: Routes = [

  // {path:'resetPassword/:email/:date/:kind', component: ResetpasswordComponent},  // original
  {path:'resetPassword/:kind/:id/:token', component: ResetpasswordComponent},  // 21-12-2020  Luke
  {path:'lostPassword/:kind', component: LostpasswordComponent},
  {path:'createAccount/:mode', component: CreateaccountComponent},
  {path:'dashboard', component: DashboardComponent},
  {path:'accountActivation/:email/:date', component: AccountactivationComponent},
  {path:'operatorActivation/:email/:date', component: OperatorActivationComponent},
  {path:'administratorDetails/:idAccount', component: AmministratoreComponent},
  {path:'beneficiaryDetails/:idBeneficiario', component: BeneficiarioComponent},
  {path:'listNewDocument', component: ListNewDocumentComponent},
  {path:'documentArchive', component: DocumentArchiveComponent},
  {path:'ricorsiArchive', component: RicorsiArchiveComponent},
  {path:'sellImm', component: SellImmComponent},
  {path:'buyImm', component: BuyImmComponent},
  {path:'auth', component: AuthComponent},
  {path:'buyCar', component: BuyCarComponent},
  {path:'legacy', component: LegacyComponent},
  {path:'sellGoods', component: SellGoodsComponent},
  {path:'ext', component: ExtComponent},
  {path:'addWindow', component: AddWindowComponent},
  {path:'editWindow/:idSportello', component: AddWindowComponent},
  {path:'addOperator', component: CreateOperatorComponent},
  {path:'editOperator', component: EditOperatorComponent},
  //{path:'', component: LoginComponent},
  //{path:'loginOperator', component: LoginOperatorComponent},
  {path:'', component: LoginOperatorComponent},
  {path:'addAds', component: AddAdsComponent},
  {path:'ricorso', component: RicorsoComponent},
  {path:'ricorso/continua', component: RicorsoComponent},
  {path: 'elencoParenti/:idRicorso', component: ElencoParentiComponent },
  {path:'viewAds', component: ViewAdsComponent},
  {path: 'extraRicorso/:idRicorso', component: ExtraRicorsoComponent},
  {path: 'administratorEdit/:idAccount',component:AdministratorEditComponent}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
