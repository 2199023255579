import {Injectable} from '@angular/core';

@Injectable()

export class DataRichiestaCopie {
      public procedimento: string;
      public numeroCopie: number;
      public mode: string;  // urgenza / noUrgenza
      public quality: string; // autentiche / semplici
      public kind: string; // PROVVEDIMENTO DEL GIUDICE TUTELARE
      // DECRETO NOMINA  AMM. DI SOSTEGNO
      // DECRETO FISSAZIONE UDIENZA
      // VERBALE DI GIURAMENTO
      public idx: number;
      public nominaUrgenteMotivazioni: string;
      public nominaUrgenteBool: boolean;
      public date: string;
      public comuneSportello: string;

      constructor(numero: string= '', nCopie: number= 1, urg: string= 'noUrgenza', auth: string= 'autentiche', k: string= '', i: number= 0, motivazioniUrgenza: string= '', nominaUrgente: boolean= false, date: string = '', comuneSportello = '') {
          this.procedimento = numero;
          this.numeroCopie = nCopie;
          this.mode = urg;
          this.quality = auth;
          this.kind = k;
          this.idx = i;
          this.nominaUrgenteMotivazioni = motivazioniUrgenza;
          this.nominaUrgenteBool = nominaUrgente;
          this.date = date;
          this.comuneSportello = comuneSportello;

	    }
}
