import {
 HttpEvent,
 HttpInterceptor,
 HttpHandler,
 HttpRequest,
 HttpResponse,
 HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {StatusService} from './services/status.service';

import {Router} from '@angular/router';

export class HttpErrorInterceptor implements HttpInterceptor {

	constructor(private router:Router,private statusService:StatusService){}

 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

 const modified = request.clone({headers: request.headers.set('Authorization', 'Bearer '+this.statusService.getToken())});

    let toSend:HttpRequest<any>;
        if(modified.url.includes('login') || modified.url.includes('profiles') ||  modified.url.includes('getUserNameOperator')){
            console.error("intercepted LOGIN or PROFILES or getUserNameOperator !!");
            toSend=request;
        }else {
            toSend=modified;
        }

   return next.handle(toSend)
     .pipe(
       retry(1),
       catchError((error: HttpErrorResponse) => {
         let errorMessage = '';
         if (error.error instanceof ErrorEvent) {
           // client-side error
           errorMessage = `Error: ${error.error.message}`;
         } else {
           // server-side error
           errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
         }
         if(error.status==406){
           errorMessage =`Codice errore 406: ACCESSO NON AUTENTICATO`;
           this.router.navigate(['']);
         }
         window.alert(errorMessage);
         return throwError(errorMessage);
       })
     )
 }
}