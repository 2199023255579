import {Injectable} from '@angular/core';

@Injectable()
export class DataSellGoods {
	
	public data:string;
	public realizzo:string;
	public cat1:string;
	public attach:string;
	public print:boolean;
	public view:boolean;

	constructor(d:string="",realizzo:string="",desc:string="",allegati:string="",print:boolean=false,view:boolean=false){
		
		this.data=d;
		this.realizzo=realizzo;
		this.cat1=desc;
		this.attach=allegati;
		this.print=print;
		this.view=view;
		
	}
}
