import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent implements OnInit {

	@Input() statusView;
	@Input() statusPrint;
	@Input() path;
	@Output() setSaveState= new EventEmitter;
	@Output() clean= new EventEmitter;
	@Output() print= new EventEmitter;
	@Output() save=new EventEmitter;
	@Output() changeStatus=new EventEmitter;
  @Input()  goToRelatives:boolean;
  @Output() relatives=new EventEmitter;
  @Input() ricorsoPage:boolean;



  constructor() { }


  ricorsoSave(){
    return !this.ricorsoPage;
  }


  showRelatives(){
    return !this.goToRelatives;
  }

  ngOnInit() {
   
  }

  showView(){
  	return !this.statusView;
  }

  showPrint(){
  	return !this.statusPrint;
  }


  relativesEmitter(){
    this.relatives.emit();
  }

  setSaveStateEmitter(){
    
  	this.setSaveState.emit();
  	this.changeStatus.emit();

  }

  cleanEmitter(){
    
  	this.clean.emit();
  	this.changeStatus.emit();


  }

  printEmitter(){
    
  	this.print.emit();
    this.changeStatus.emit();
  	

  }

  saveEmitter(){
   
  	this.save.emit();
  	this.changeStatus.emit();

  }

}
