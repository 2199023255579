import { Component, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Anagrafica} from '../../models/anagrafica';
import {LangService} from '../../services/lang.service';
/* Luke 17-01-2021: copied from ricorrente component */
// import {Ricorrente,Relazione} from '../../models/ricorrente';
import {DbService} from '../../services/db.service';
import {Comune, Indirizzo, IndirizzoStrutturato} from '../../models/indirizzo';
/* END Luke 17-01-2021: copied from ricorrente component */

@Component({
  selector: 'input-anagrafica',
  templateUrl: './anagrafica.component.html',
  styleUrls: ['./anagrafica.component.css'],
  providers: [Anagrafica, LangService]
})
export class AnagraficaComponent implements OnInit {
  /* END Lule 17-01-2021: copied from ricorrente component */

  // @Input() ana; // ?? Luke 17-01-2021


  constructor(private langService: LangService, private dbService: DbService) { }  // Luke 17-01-2021: added dbService

  /* Luke 17-01-2021: copied from ricorrente component */
  private changed: boolean;
  public comune: Comune;
  public indirizzo: Indirizzo;
  @Input() ana: Anagrafica;
  @Input() forModal:boolean;
  @Output() creaAccount = new EventEmitter();
  @Input() req:boolean;


@ViewChild('anagraficaForm', {static: true}) form;

  ngOnInit() {

    // console.warn("sono la input-anagrafica e loggo: ",this.ana);
   /* Luke 17-01-2021 */
   console.warn('sono la input-anagrafica e loggo: ', this.ana);
   if (this.ana.indirizzoStrutturato != undefined) {

    this.comune = {cap: '', comune: '', provincia: ''};
    this.indirizzo = {prefixTopo: '', denomTopo: '', civico: '', nazionalita: ''};


    this.indirizzo.prefixTopo = this.ana.indirizzoStrutturato.indirizzo.prefixTopo;
    this.indirizzo.denomTopo = this.ana.indirizzoStrutturato.indirizzo.denomTopo;
    this.indirizzo.civico = this.ana.indirizzoStrutturato.indirizzo.civico;
    this.comune.cap = this.ana.indirizzoStrutturato.comune.cap;
    this.comune.comune = this.ana.indirizzoStrutturato.comune.comune;
    this.comune.provincia = this.ana.indirizzoStrutturato.comune.provincia;
    this.indirizzo.nazionalita = this.ana.indirizzoStrutturato.indirizzo.nazionalita;
  } else {
    this.comune = {cap: '', comune: '', provincia: ''};
    this.indirizzo = {prefixTopo: '', denomTopo: '', civico: '', nazionalita: ''};
  }
  /* END Luke 17-01-2021 */

  }

submitForm() {
	this.form.onSubmit();
    if(this.forModal){
        if(this.send())
            this.creaAccount.emit();
    }
}


send() {
	return this.form.valid;
}

  LabelNome() {
  	return this.langService.nome();
  }

 LabelCognome() {
 		return this.langService.cognome();
 }

 LabelDataDiNascita() {
 	return this.langService.dataDiNascita();
 }

LabelLuogoDiNascita() {
	return this.langService.luogoDiNascita();
}

LabelCodiceFiscale() {
	return this.langService.codiceFiscale();
}

LabelIndirizzo() {
	return this.langService.indirizzo();
}

PlaceholderIndirizzo() {
  return this.langService.indirizzoPlaceholder();
}

    LabelTelefono(){
        return this.langService.telefono();
    }

    LabelCellulare(){
        return this.langService.cellulare();
    }

    LabelEmail(){
        return this.langService.email();
    }

/* Luke 17-01-2021: indiritto strutturato beneficiario */
copyAddress() {

  if (this.ana.indirizzoStrutturato != undefined) {
    console.log('this.anagrafica.indirizzoStrutturato!=undefined');

    if (this.ana.indirizzoStrutturato.indirizzo.prefixTopo != 'SCONOSCIUTO') {
      if (this.ana.indirizzoStrutturato.comune.provincia != '' && this.ana.indirizzoStrutturato.comune.provincia != null) {
       // this.ana.indirizzo=`${this.ana.indirizzoStrutturato.indirizzo.prefixTopo} ${this.ana.indirizzoStrutturato.indirizzo.denomTopo} ${this.ana.indirizzoStrutturato.indirizzo.civico!=''?'n.':''} ${this.ana.indirizzoStrutturato.indirizzo.civico}${this.ana.indirizzoStrutturato.comune.cap!=''?',':''} ${this.ana.indirizzoStrutturato.comune.cap} ${this.ana.indirizzoStrutturato.comune.comune} (${this.ana.indirizzoStrutturato.comune.provincia})`;  // original
       this.ana.indirizzo = `${this.ana.indirizzoStrutturato.indirizzo.prefixTopo} ${this.ana.indirizzoStrutturato.indirizzo.denomTopo != null ? this.ana.indirizzoStrutturato.indirizzo.denomTopo : ''} ${(this.ana.indirizzoStrutturato.indirizzo.civico != '' && this.ana.indirizzoStrutturato.indirizzo.civico != null) ? 'n.' : ''} ${this.ana.indirizzoStrutturato.indirizzo.civico != null ? this.ana.indirizzoStrutturato.indirizzo.civico : ''}${(this.ana.indirizzoStrutturato.comune.cap != '' && this.ana.indirizzoStrutturato.comune.cap != null) ? ',' : ''} ${this.ana.indirizzoStrutturato.comune.cap != null ? this.ana.indirizzoStrutturato.comune.cap : ''} ${this.ana.indirizzoStrutturato.comune.comune != null ? this.ana.indirizzoStrutturato.comune.comune : ''} (${this.ana.indirizzoStrutturato.comune.provincia})`;
      } else {
         // this.ana.indirizzo=`${this.ana.indirizzoStrutturato.indirizzo.prefixTopo} ${this.ana.indirizzoStrutturato.indirizzo.denomTopo} ${this.ana.indirizzoStrutturato.indirizzo.civico!=''?'n.':''} ${this.ana.indirizzoStrutturato.indirizzo.civico}${this.ana.indirizzoStrutturato.comune.cap!=''?',':''} ${this.ana.indirizzoStrutturato.comune.cap} ${this.ana.indirizzoStrutturato.comune.comune}`;  // original
         this.ana.indirizzo = `${this.ana.indirizzoStrutturato.indirizzo.prefixTopo} ${this.ana.indirizzoStrutturato.indirizzo.denomTopo != null ? this.ana.indirizzoStrutturato.indirizzo.denomTopo : ''} ${(this.ana.indirizzoStrutturato.indirizzo.civico != '' && this.ana.indirizzoStrutturato.indirizzo.civico != null) ? 'n.' : ''} ${this.ana.indirizzoStrutturato.indirizzo.civico != null ? this.ana.indirizzoStrutturato.indirizzo.civico : ''}${(this.ana.indirizzoStrutturato.comune.cap != '' && this.ana.indirizzoStrutturato.comune.cap != null) ? ',' : ''} ${this.ana.indirizzoStrutturato.comune.cap != null ? this.ana.indirizzoStrutturato.comune.cap : ''} ${this.ana.indirizzoStrutturato.comune.comune != null ? this.ana.indirizzoStrutturato.comune.comune : ''}`;
      }  // Luke 17/02/2021 : visualizzazione corretta indirizzo strutturato


    } else {
      this.ana.indirizzo = 'SCONOSCIUTO';
    }


  } else {
    console.log('this.ricorrente.indirizzoStrutturato==undefined');


    if (this.indirizzo.prefixTopo != 'SCONOSCIUTO') {

      if (this.comune.provincia != '' && this.comune.provincia != null) {
        // this.ana.indirizzo=`${this.indirizzo.prefixTopo} ${this.indirizzo.denomTopo} ${this.indirizzo.civico!=''?'n.':''} ${this.indirizzo.civico}${this.comune.cap!=''?',':''} ${this.comune.cap} ${this.comune.comune} (${this.comune.provincia})`;  // original
        this.ana.indirizzo = `${this.indirizzo.prefixTopo} ${this.indirizzo.denomTopo != null ? this.indirizzo.denomTopo : ''} ${(this.indirizzo.civico != '' && this.indirizzo.civico != null) ? 'n.' : ''} ${this.indirizzo.civico != null ? this.indirizzo.civico : ''}${(this.comune.cap != '' && this.comune.cap != null) ? ',' : ''} ${this.comune.cap != null ? this.comune.cap : ''} ${this.comune.comune != null ? this.comune.comune : ''} (${this.comune.provincia})`;
      } else {
        // this.ana.indirizzo=`${this.indirizzo.prefixTopo} ${this.indirizzo.denomTopo} ${this.indirizzo.civico!=''?'n.':''} ${this.indirizzo.civico}${this.comune.cap!=''?',':''} ${this.comune.cap} ${this.comune.comune}`;  // original
        this.ana.indirizzo = `${this.indirizzo.prefixTopo} ${this.indirizzo.denomTopo != null ? this.indirizzo.denomTopo : ''} ${(this.indirizzo.civico != '' && this.indirizzo.civico != null) ? 'n.' : ''} ${this.indirizzo.civico != null ? this.indirizzo.civico : ''}${(this.comune.cap != '' && this.comune.cap != null) ? ',' : ''} ${this.comune.cap != null ? this.comune.cap : ''} ${this.comune.comune != null ? this.comune.comune : ''}`;
      }  // Luke 17/02/2021 : visualizzazione corretta indirizzo strutturato
    } else {
      this.ana.indirizzo = 'SCONOSCIUTO';
    }

    this.ana.indirizzoStrutturato = {indirizzo: {prefixTopo: this.indirizzo.prefixTopo, denomTopo: this.indirizzo.denomTopo, civico: this.indirizzo.civico, nazionalita: this.indirizzo.nazionalita}, comune: {cap: this.comune.cap, comune: this.comune.comune, provincia: this.comune.provincia}};

  }

  console.log('anagrafica copyAddress()');
  console.log( this.ana.indirizzoStrutturato);

}

getComune() {
  if (this.ana.indirizzoStrutturato == undefined || this.ana.indirizzoStrutturato == null) {
    return {cap: '', comune: '', provincia: ''};
  } else { return this.ana.indirizzoStrutturato.comune; }
}
getIndirizzo() {
   if (this.ana.indirizzoStrutturato == undefined || this.ana.indirizzoStrutturato == null) {
    return {prefixTopo: '', denomTopo: '', civico: '', nazionalita: ''};
   } else { return this.ana.indirizzoStrutturato.indirizzo; }
}
setComuneScelto(comune: Comune) {
   console.log('alla setComune arriva:');
   console.log(comune);
   if (this.ana.indirizzoStrutturato != undefined) {

       this.ana.indirizzoStrutturato.comune.cap = comune.cap;
       this.ana.indirizzoStrutturato.comune.comune = comune.comune;
       this.ana.indirizzoStrutturato.comune.provincia = comune.provincia;
   } else {
      this.comune.cap = comune.cap;
      this.comune.comune = comune.comune;
      this.comune.provincia = comune.provincia;
   }

   console.log(`cap ${comune.cap} comune ${comune.comune} prov ${comune.provincia}`);
   this.copyAddress();
   }

 setIndirizzo(indirizzo: Indirizzo) {
   console.log('alla setINdirizzo arriva');
   console.log(indirizzo);

   if (this.ana.indirizzoStrutturato != undefined) {

       this.ana.indirizzoStrutturato.indirizzo.prefixTopo = indirizzo.prefixTopo;
       this.ana.indirizzoStrutturato.indirizzo.denomTopo = indirizzo.denomTopo;
       this.ana.indirizzoStrutturato.indirizzo.civico = indirizzo.civico;
       this.ana.indirizzoStrutturato.indirizzo.nazionalita = indirizzo.nazionalita;
    } else {
       this.indirizzo.prefixTopo = indirizzo.prefixTopo;
       this.indirizzo.denomTopo = indirizzo.denomTopo;
       this.indirizzo.civico = indirizzo.civico;
       this.indirizzo.nazionalita = indirizzo.nazionalita;

    }

   this.copyAddress();


 }

/* END Luke 17-01-2021*/



}
