import { Component, OnInit } from '@angular/core';
import {DocumentIconComponent} from '../document-icon/document-icon.component';

import {AppSettings} from '../../app-settings';
import {DbService} from '../../services/db.service'; 
import {StatusService} from '../../services/status.service';

@Component({
  selector: 'documentIconBuyImm',
  templateUrl: './document-icon-buy-imm.component.html',
  styleUrls: ['./document-icon-buy-imm.component.css'],
  providers: [DbService]

})
export class DocumentIconBuyImmComponent extends DocumentIconComponent {

 



}
