
import { Injectable } from '@angular/core';

@Injectable()
export class Account {

	constructor(public emailValida:boolean=false,public active:boolean=false,public idAccount:number=0,public email:string="",public ripetiEmail:string="",public password:string="",public ripetiPassword:string="", public completeAds:boolean=false,public completeBene:boolean=false,public kind:string="",public idSportello:number=0,public supervisor:boolean=false,public informativa:boolean=false, public head:boolean=false){

	} 


}
