import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'nota-ruolo',
  templateUrl: './nota-ruolo.component.html',
  styleUrls: ['./nota-ruolo.component.scss']
})
export class NotaRuoloComponent implements OnInit {

    @Input() copie;

    @Output() sendNotaRuolo = new EventEmitter();
    @Output() disableSendNotaRuolo= new EventEmitter();

    constructor() { }


    @ViewChild('notaRuoloForm',{static:false}) form;


    disableForm(){
        this.disableSendNotaRuolo.emit();
    }

    valuechange(val){
        this.disableSendNotaRuolo.emit();
    }

    sendForm(){
        console.log('sendForm click!');
        this.sendNotaRuolo.emit();
    }

    loggami(event){
        setTimeout(()=>{
            console.log(`evento vale ${event}`);
        },400)
    }



    ngOnInit() {
    }


}
