import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders,HttpResponse } from '@angular/common/http';
import {DbService} from '../../services/db.service';
import {StatusService} from '../../services/status.service';
import {Router} from '@angular/router';
import {Username} from '../../models/username';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {AppSettings} from '../../app-settings';
import {catchError} from 'rxjs/operators';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers:[DbService]
})
export class LoginComponent implements OnInit {

	
  private titlePage:string="sostegno all'amministratore";
  constructor(private http:HttpClient, private dbService:DbService, private router:Router, private statusService:StatusService) { }

  ngOnInit() {
    this.readCookie();
    this.dbService.deleteOldProfiles();
    this.deleteOldPdf();
  }

  deleteOldPdf(){

    var json={};
     var headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
       return this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/deletePdf.php`,json,{headers:headers})
        // .map(res => res.json())
         .subscribe(data => console.log((data as any).msg));
    }
  


 public accessError(){

  	return this.accessE;

  }

logButton(){
  return "log in";
}
regButton(){
	return "registrati";
}

rememberMe(){
	return "ricordami";
}

lostPassword(){
	return "password dimenticata?";
}


onSubmit(){
  console.log("login utente disabilitato");
  //this.salvaCookie();
  //this.logIn(); //ho disabilittato volontariamente il login in data agosto 2018

}
 accessE:string="errore";
  home:string="www.test.com";
activeAccount:boolean=false;

saveCookie:boolean=false;
success:boolean=true;
agent=null;
account?=null;

public user= new Username();


private alreadyIn(){
  return this.activeAccount;

}
private getSuccess(){
  return this.success;
}


  private   salvaCookie(){
      console.warn("salva cookie chiamata:",this.user.userName,this.saveCookie);

      var data = {uName:this.user.userName,remember:this.saveCookie};

      var json=JSON.stringify(data);
       
      var params=json;


      var headers=new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
      return this.http.post(`${AppSettings.API_ENDPOINT}/php/writeCookie.php`,params,{headers:headers})
      //.map(res=>res.json())
     .pipe(catchError(error=>{return this.handleError(error);}))
      .subscribe((data)=>console.log("ho salvato coockie",data));



     }

     unsetCookie(){


      var data = {uName:this.user.userName,remember:this.saveCookie};

      var json=JSON.stringify(data);
       
      var params=json;


      var headers=new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
      return this.http.post(`${AppSettings.API_ENDPOINT}/php/unsetCookie.php`,params,{headers:headers})
      //.map(res=>res.json())
      .pipe(catchError(error=>{return this.handleError(error);}))
      .subscribe((data)=>console.log("unsetCookieCompleted"));

     }

     

    



   readCookie (){


    /* const body = new URLSearchParams();
    body.set('userName', this.user.userName);
    body.set('password', this.user.password);*/
 

     var json=JSON.stringify(this.user);
      console.log("vorrei inserire: ",json);
      var params=json;
   
      var headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'  });
      return this.http.post(`${AppSettings.API_ENDPOINT}/php/readCookie.php`,params,{headers:headers})
      //.map(res=>res.json())
     .pipe(catchError(error=>{return this.handleError(error);}))
      .subscribe(
      (data) => this.setParams(data)
      );

    //insertUser
  }


  private setParams(p){
      this.user.userName=p.userName;
      this.saveCookie=(p.saveCookie==1);
      console.log("user:",this.user);
      console.log("saveCookie:",this.saveCookie);
      if(this.saveCookie){
        this.user.password="valida";
      }else
      {
        this.user.password="";
        this.user.userName="";
      }

  }
  private handleError(error: Response) {
        console.error(error);
        this.accessE=error.statusText;
        console.log("loggo l'errore:",error);
        console.log("statusText: ",error.statusText);
        return Observable.throw(error);
    }

     

   


private logIn(){
    

    

  this.accessE="log in chiamato";
   

       if(this.user.userName!='' && this.saveCookie){
         //this.logInFromCookie(this.cookieUserName);
         console.log("=>logInFromCookie: ");
         this.dbService.logInCookie(this.user)
             /*.map(res =>{
           if(res.status==204)
             throw new Error("Utente/email inesistente");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



         })*/
              .subscribe(
              data => this.setAccount(data),
              err => console.error(err+"erroe da log inFromCookie")
              );

           

       }
       else {
      

        if(!this.alreadyIn()){
          console.warn("active account vale: ",this.activeAccount);

          if(this.user.userName=="" && this.user.password==""){
              this.user.userName="void";
              this.user.password="void";
          }
        
          this.dbService.logIn(this.user)
              /*.map(res =>{
           if(res.status==204)
             throw new Error("Utente/email inesistente");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



         })*/
              .subscribe(
              data => this.setAccount(data),
              err => console.error(err+"errore da login standard")
              );
           }else {
               console.log("già loggato, procedi entrando nell'app...");
           }
      }
  }


private execLogOut(){

  this.agent=null;
  this.success=false;
  console.warn("logOutCompleted");
}


private setAccount(datum){
  console.warn("set account chiamta");
  this.account=datum.data;

  if(datum.status=="Success"){
    console.log("biribì");
    console.log("sono su ramo success, account vale",this.account);
      this.success=true;
      this.activeAccount=true;
      this.statusService.updateAccount(this.account);
      this.statusService.setAccess(true);
      console.log("loggo idAccount da statusService: ",this.statusService.getId());

      this.router.navigate(['/dashboard']);
      //this.salvaCookie();

    }
   else if(datum.status=="Failure")
   {  
     console.log("sono su ramo failure");
      this.success=false;
     this.activeAccount=false;
     this.accessE=datum.msg;
     // this.unsetCookie();
      //this.execLogOut();*/
   }

   console.log("da submit logIn:",datum);

 

}


          
        
      
    
    
    
    
    
 

}
