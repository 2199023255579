import {Injectable} from '@angular/core';

@Injectable()

export class DataDuplicaRicorso {

    public ricorrenti:boolean;
    public beneficiario:boolean;


    constructor(ric = true, bene = false)
    {
        this.ricorrenti=ric;
        this.beneficiario = bene;
    }
}
