import {Injectable} from '@angular/core';

@Injectable()

export class DataLegacy {

	//accettazione eredità
//gradoDecesso
//dataDeceduto
//data

	public data:string;
	public gradoDecesso:string;
  public sesso:string;  // Luke 7/3/2021 : controllo sesso in cod eredità (legacy)
	public dataDeceduto:string;
	public print:boolean;
	public view:boolean;
	public allegati:string;
	public premesse:string;
    public tipo:string;

	constructor(d:string="",grado:string="",dataDeceduto:string="",print:boolean=false,view:boolean=false,allegati:string='',premesse:string='',tipo:string='inventario'){

		this.data=d;
		this.gradoDecesso=grado;
		this.dataDeceduto=dataDeceduto;
		this.print=print;
		this.view=view;
		this.allegati=allegati;
		this.premesse=premesse;
        this.tipo=tipo;


	}
}
