import {Injectable} from '@angular/core';

@Injectable()


export class DataBuyImm {

	//obj->data (del documento)
//obj->valore (del bene immobile da acquistare)

	public data:string;
	public valore:string;
	public print:boolean;
	public view:boolean;

	constructor(data:string="",valore="",print:boolean=false,view:boolean=false){
		this.data=data;
		this.valore=valore;
		this.print=print;
		this.view=view;
	}

}
