import { Beneficiario } from './../../models/beneficiario';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {StatusService} from '../../services/status.service';
import {DbService} from '../../services/db.service';
import {Router} from '@angular/router';
import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { Amministratore } from '../../models/amministratore';
// import { Beneficiario} from '../../models/beneficiario';
import {AppSettings} from '../../app-settings';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {Ricorrente} from '../../models/ricorrente';
import {Ricorso} from '../../models/ricorso';
import {DataRicorso} from '../../data/data-ricorso';
import {Anagrafica } from '../../models/anagrafica';
import {DataSportello} from '../../data/data-sportello';
import {Account } from '../../models/account';
import {Username} from '../../models/username';
import {DataRichiestaCopie} from '../../data/data-richiesta-copie';
import {RichiestaCopieComponent} from '../richiesta-copie/richiesta-copie.component';
import { FilterPipe} from '../../filter.pipe';
import {catchError} from 'rxjs/operators';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {Resp} from '../../models/resp';
import { formatDate } from '@angular/common';



import { ElementRef, AfterViewInit } from '@angular/core';
import {fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, filter } from 'rxjs/operators';

import * as _ from 'lodash';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations';
import {DataDuplicaRicorso} from '../../data/data-duplica-ricorso';

@Component({
   selector: 'app-ricorsi-archive',
  templateUrl: './ricorsi-archive.component.html',
  styleUrls: ['./ricorsi-archive.component.css'],
  providers: [DbService],
  animations: [
      trigger('movePanel', [

            transition('* => *', [
                animate('4s', keyframes([
                    style({opacity: 0, transform: 'rotate(45deg)', offset: 0 }),
                    style({opacity: 0.3, transform: 'rotate(45deg)', offset: 0.15 }),
                    style({opacity: 0.7, transform: 'rotate(45deg)', offset: 0.30 }),
                    style({opacity: 1, transform: 'rotate(45deg)', offset: 0.45 }),
                    style({opacity: 1, transform: 'rotate(45deg)', offset: 0.60 }),
                    style({opacity: 1, transform: 'rotate(45deg)', offset: 0.75 }),
                    style({opacity: 1, transform: 'rotate(45deg)', offset: 0.90 }),
                    style({opacity: 1, transform: 'rotate(45deg)', offset: 1 })
                ]))
            ])

        ])
  ]


})



export class RicorsiArchiveComponent implements OnInit {




/* fine generazioe documenti */



  constructor(private http: HttpClient, private router: Router, private statusService: StatusService, private dbService: DbService) { }

  user: Username;

  copie: DataRichiestaCopie = new DataRichiestaCopie();


  public searchText: string;
  public searchSub: Subscription;

  public currentYear: number;

  public yearList: number[];
  public duplicaRicorsoSelection:DataDuplicaRicorso;

  @ViewChild('input', {static: false}) input: ElementRef;

/* metodi modale */


    @ViewChild('ricCopie', {static: false})
    ricCopieComponent: RichiestaCopieComponent;

    @ViewChild('richiestaCopieModal', {static: false})
    richiestaCopieModal: BsModalComponent;

    @ViewChild('notaIscrizioneModal', {static: false})
    notaIscrizioneModal: BsModalComponent;

     @ViewChild('ricorsoModal', {static: false})
    ricorsoModal: BsModalComponent;

    @ViewChild('ricorrentiModal', {static: false})
    ricorrentiModal: BsModalComponent;

    public _sportello: DataSportello;

    selected: string;
    output: string;

    public operatorData;
    public ascBene: boolean;
    public ascData: boolean;


    nomeRichiestaCopie: string;
    nomeNotaIscrizione: string;
    backdropOptions = [true, false, 'static'];
    cssClass = '';

    animation = true;
    keyboard = true;
    backdrop: string | boolean = true;
    css = false;

    selectedAds: Amministratore;
    selectedBene: Beneficiario;
    selectedKind: string;

    linkRichiestaCopie: boolean;
    pathRichiestaCopie: string;
    linkNotaIscrizione: boolean;
    pathNotaIscrizione: string;


   dataRicorso: DataRicorso = new DataRicorso();
    proposteNominativi:Anagrafica[];


   _localRicorrenti: Ricorrente[];
   _localBeneficiario: Beneficiario;  // Luke 02/03/2021
   _idRicorso: number;




/* metodi modale */

    @ViewChild('deleteModal', {static: false})
    deleteModal: BsModalComponent;

    @ViewChild('copyRicorsoModal', {static: false})
    copyRicorsoModal: BsModalComponent;


    selectedDocument: string;


    idToCopy:number;
    idToDelete: number;
    number: number;


   public ricUp = {
     idRicorso: '',
   depositoInCancelleria: '',
      dataNomina: '',
     approvato: ''};




  /*fine metodi modale */

  /* FINE MODALE PER CANCELLAZIONE */




/* generazione richiesta copie e nota iscrizione a ruolo */

obj;
ads;
bene;
  private list;
  private account;

  private idSportello;

  stateCopyDetails: string;

    ngAfterViewInit() {
            // server-side search
        this.searchSub = fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                filter(Boolean),
                debounceTime(450),
                distinctUntilChanged(),
                tap((text) => {
                  console.log(this.input.nativeElement.value);
                  this.searchText = this.input.nativeElement.value;
                })
            )
            .subscribe();
  }


    deletePdf(name) {
     return this.dbService.deletePdf(name);

    }



   closedRC() {
      this.linkRichiestaCopie = false;
      this.deletePdf(this.pathRichiestaCopie).subscribe(() => {
        this.richiestaCopieModal.dismiss();
      });
    }

    closedNI() {
       this.linkNotaIscrizione = false;
       this.deletePdf(this.pathNotaIscrizione).subscribe(() => {
        this.notaIscrizioneModal.dismiss();
      });
    }
    closedRicorso() {

    }

    closedRicorrenti() {

    }

    dismissedRC() {
      this.linkRichiestaCopie = false;
      this.deletePdf(this.pathRichiestaCopie).subscribe(() => {
        this.richiestaCopieModal.dismiss();
      });

    }
    dismissedNI() {
        this.linkNotaIscrizione = false;
        this.deletePdf(this.pathNotaIscrizione).subscribe(() => {
          this.notaIscrizioneModal.dismiss();
        });
    }

    dismissedRicorso() {

    }

     dismissedRicorrenti() {}

    openConfermaCopia(){}

    openedRC() {



        // this.linkRichiestaCopie=false;
    }
    openedNI() {
       // this.linkNotaIscrizione=false;
    }

    openedRicorso() {

    }
    openedRicorrenti() {

    }


    accountFromNominativoProposto(idx){
        console.log(` accountFromNominativoProposto i is  ${idx}`);
        console.log(`l'array dei nominativi proposti vale`);
        console.log(this.proposteNominativi);

        const _account: Account = new Account();
        const _anagrafica: Anagrafica = new Anagrafica();
        const _ricorrente: Ricorrente = new Ricorrente();
        const _beneficiario: Beneficiario = new Beneficiario();


        _anagrafica.nome = this.proposteNominativi[idx].nome;
        _anagrafica.cognome = this.proposteNominativi[idx].cognome;
        _anagrafica.indirizzo = this.proposteNominativi[idx].indirizzo;
        _anagrafica.codiceFiscale = this.proposteNominativi[idx].codiceFiscale;
        _anagrafica.dataDiNascita = this.proposteNominativi[idx].dataDiNascita;
        _anagrafica.luogoDiNascita = this.proposteNominativi[idx].luogoDiNascita;
        _anagrafica.sesso = this.proposteNominativi[idx].sesso;
        if (this.proposteNominativi[idx].indirizzoStrutturato != undefined) {
            _anagrafica.indirizzoStrutturato = _.cloneDeep(this.proposteNominativi[idx].indirizzoStrutturato);

        }




        _account.email = this.proposteNominativi[idx].email;



        _ricorrente.sesso = this.proposteNominativi[idx].sesso;
        _ricorrente.telefono = this.proposteNominativi[idx].telefono;
        _ricorrente.cellulare = this.proposteNominativi[idx].cellulare;

        _beneficiario.codiceFiscale = this.dataRicorso.beneficiario.codiceFiscale;
        _beneficiario.cognome = this.dataRicorso.beneficiario.cognome;
        _beneficiario.dataDiNascita = this.dataRicorso.beneficiario.dataDiNascita;
        _beneficiario.grado = this.dataRicorso.beneficiario.grado;
        _beneficiario.indirizzo = this.dataRicorso.beneficiario.indirizzo;
        // _beneficiario.luogoDiNascita=this.dataRicorso.beneficiario.indirizzo;  // Luke 16/02/2021 : bug luogo di nascita beneficiario (appariva uguale all'indirizzo)
        _beneficiario.luogoDiNascita = this.dataRicorso.beneficiario.luogoDiNascita; // Luke 16/02/2021 bug fix
        _beneficiario.nome = this.dataRicorso.beneficiario.nome;
        _beneficiario.sesso = this.dataRicorso.beneficiario.sesso;

        // Luke 02/02/2021 : salvataggio indirizzo strutturato beneficiario
        if (this.dataRicorso.beneficiario.indirizzoStrutturato != undefined) {
            _beneficiario.indirizzoStrutturato = _.cloneDeep(this.dataRicorso.beneficiario.indirizzoStrutturato);

        }
        this.dbService.createAccountFromRicorso(_account, _anagrafica, _ricorrente, _beneficiario, this.dataRicorso.nrg, 'ads', 'direct')
            .subscribe(
                data => this.linkAccontToWindow(_account),
                err => console.error(err)
            );

    }

   accountFromRicorrente(idx) {
     const _account: Account = new Account();
     const _anagrafica: Anagrafica = new Anagrafica();
     const _ricorrente: Ricorrente = new Ricorrente();
     const _beneficiario: Beneficiario = new Beneficiario();


     _anagrafica.nome = this.dataRicorso.ricorrenti[idx].nome;
     _anagrafica.cognome = this.dataRicorso.ricorrenti[idx].cognome;
     _anagrafica.indirizzo = this.dataRicorso.ricorrenti[idx].indirizzo;
     _anagrafica.codiceFiscale = this.dataRicorso.ricorrenti[idx].codiceFiscale;
     _anagrafica.dataDiNascita = this.dataRicorso.ricorrenti[idx].dataDiNascita;
     _anagrafica.luogoDiNascita = this.dataRicorso.ricorrenti[idx].luogoDiNascita;
     _anagrafica.sesso = this.dataRicorso.ricorrenti[idx].sesso;
     if (this.dataRicorso.ricorrenti[idx].indirizzoStrutturato != undefined) {
       _anagrafica.indirizzoStrutturato = _.cloneDeep(this.dataRicorso.ricorrenti[idx].indirizzoStrutturato);

     }




     _account.email = this.dataRicorso.ricorrenti[idx].email;



     _ricorrente.sesso = this.dataRicorso.ricorrenti[idx].sesso;
     _ricorrente.telefono = this.dataRicorso.ricorrenti[idx].telefono;
     _ricorrente.cellulare = this.dataRicorso.ricorrenti[idx].cellulare;

     _beneficiario.codiceFiscale = this.dataRicorso.beneficiario.codiceFiscale;
     _beneficiario.cognome = this.dataRicorso.beneficiario.cognome;
     _beneficiario.dataDiNascita = this.dataRicorso.beneficiario.dataDiNascita;
     _beneficiario.grado = this.dataRicorso.beneficiario.grado;
     _beneficiario.indirizzo = this.dataRicorso.beneficiario.indirizzo;
     // _beneficiario.luogoDiNascita=this.dataRicorso.beneficiario.indirizzo;  // Luke 16/02/2021 : bug luogo di nascita beneficiario (appariva uguale all'indirizzo)
     _beneficiario.luogoDiNascita = this.dataRicorso.beneficiario.luogoDiNascita; // Luke 16/02/2021 bug fix
     _beneficiario.nome = this.dataRicorso.beneficiario.nome;
     _beneficiario.sesso = this.dataRicorso.beneficiario.sesso;

     // Luke 02/02/2021 : salvataggio indirizzo strutturato beneficiario
     if (this.dataRicorso.beneficiario.indirizzoStrutturato != undefined) {
      _beneficiario.indirizzoStrutturato = _.cloneDeep(this.dataRicorso.beneficiario.indirizzoStrutturato);

     }
    // Luke 02/02/2021 : salvataggio indirizzo strutturato beneficiario END

     console.log(_anagrafica);
     console.log(_account);
     console.log(_beneficiario);
     console.log(_ricorrente);


     // idx va da 0 a 5
     console.log(idx);
     this.dbService.createAccountFromRicorso(_account, _anagrafica, _ricorrente, _beneficiario, this.dataRicorso.nrg, 'ads', 'direct')
           .subscribe(
             data => this.linkAccontToWindow(_account),
                err => console.error(err)
             );
   }


   linkAccontToWindow(user) {
     this.user.userName = user.email;
     this.user.password = user.password;

     this.dbService.linkAccountFromRicorrente(this.user, this.statusService.getSpecialAccount())
              .subscribe(
              data => this.setAccount(data),
              err => console.error(err + 'errore da login standard')
              );

   }


   private setAccount(datum) {
  console.warn('set account chiamta');


  if (datum.status == 'Success') {
    console.log('sono su ramo success');


    /* //carico profilo ads in area di lavoro
      this.statusService.setLoadedAccount(datum.data);

      //carico cognome dell'ads caricaato su navbar
      this.dbService.getAmministratore(this.statusService.getId())
      .map(res => res.json())
      .subscribe( data =>  this.statusService.updateSurname((data as Resp).data.cognome));
      console.log("loggo idAccount da statusService: ",this.statusService.getId());
      alert('amministratore di sostegno aggregato con successo!');

      this.router.navigate(['/dashboard']);
      //this.salvaCookie();*/
    alert('account creato con successo');

    } else if (datum.status == 'Failure') {
     console.log('sono su ramo failure');
     alert('Errore, account non creato');

     // this.unsetCookie();
      // this.execLogOut();*/
   }

  console.log('da submit logIn:', datum);



}
    setProposteNominativi(nominativi){
        this.proposteNominativi = [];
        for(let nom of nominativi){
            this.proposteNominativi.push(nom.propostaAds);
        }
    }


    showModalRicorrenti(ricorrenti, dettagli, beneficiario, nrg, idRicorso) {

        this.dbService.getPropostaNominativiFromDb(idRicorso)
            .subscribe(data =>  this.setProposteNominativi((data as Resp).data));

      // this._nRics=dettagli.nRicorrenti;
      console.log(dettagli.nRicorrenti);
      console.log(ricorrenti);
      // console.log(this.dataRicorso);
      this.dataRicorso.details.nRicorrenti = dettagli.nRicorrenti;
      this.dataRicorso.ricorrenti = ricorrenti;
      this.dataRicorso.beneficiario = beneficiario;
      this.dataRicorso.nrg = nrg;

      this.ricorrentiModal.open();
      console.log('showModalRicorrenti chiamato');

    }

    showModalBeneficiario() {

    }

    showModalDettagli() {

    }

   getOpenRCState() {
     return this.stateCopyDetails;
   }


   confermaCopie() {
     console.log('id ricorso ', this._idRicorso);
     console.log('tipo ', this.ricCopieComponent.copie.kind);
     this.dbService.acceptCopy(this._idRicorso, this.ricCopieComponent.copie.kind)
     // .map(res => res.json())
    .subscribe(data =>  alert((data as any).msg));
   }

   apriPdfCopie() {
      window.open(this.pathRichiestaCopie, '_blnk');
   }



    scartaCopie() {
     console.log('id ricorso ', this._idRicorso);
     console.log('tipo ', this.ricCopieComponent.copie.kind);
     this.dbService.discardCopy(this._idRicorso, this.ricCopieComponent.copie.kind)
       // .map(res => res.json())
    .subscribe(data =>  alert((data as any).msg));
   }

    openRichiestaCopie(rics, beneficiario, idRicorso) {
        this.stateCopyDetails = 'inactive';
        this.linkRichiestaCopie = false;
        this._localRicorrenti = rics;
        this._idRicorso = idRicorso;
        this._localBeneficiario = beneficiario; // Luke 02/03/2021 : aggiunta cognome beneficiario su nome file

        this.dataRicorso.ricorrenti = rics;
        this.richiestaCopieModal.open();
        console.log(this._localRicorrenti);
        console.log('_localBeneficiario: ', this._localBeneficiario);
        this.copie.date = formatDate(new Date().toDateString(), 'dd/MM/yyyy', 'it-IT');
        this.copie.comuneSportello = this._sportello.comune;

    }

      generaCopie() {
        console.log(this.copie);
       // this.print('copy','',this.dataRicorso.ricorrenti,null);  // original
        this.print('copy', '', this.dataRicorso.ricorrenti, this._localBeneficiario);  // Luke 02/03/2021 : cognome beneficiario aggiunto ai documenti
        console.log('genera copie clciccato');
    }


    openNotaIscrizione(kind, rics, benef, idRicorso) {
        this._idRicorso = idRicorso;
        console.error(`idRicorso vale ${this._idRicorso}`);
        this.selectedKind = kind;
        this.copie.date = formatDate(new Date().toDateString(), 'dd/MM/yyyy', 'it-IT');
        this.notaIscrizioneModal.open();
        this._localRicorrenti = rics;
        this._localBeneficiario = benef;
        //this.print('note', kind, rics, benef);
    }


    editStatusRicorso(id) {
      this.ricUp.idRicorso = id;
      this.dbService.getRicorsoFromDb(parseInt(this.ricUp.idRicorso))
    // .map(res => res.json())
    .subscribe(data => this.configRicorsoUpdate((data as Resp).data));
      this.ricorsoModal.open();
    }

   configRicorsoUpdate(data) {

     this.ricUp.approvato = data.approvato;
     this.ricUp.dataNomina = (data as any).dataNomina;
     this.ricUp.depositoInCancelleria = (data as any).dataDepositoCancelleria;
     console.log(this.ricUp);
   }


   updateStatoRicorso() {




     // data = {"obj":JSON.stringify(this.obj), "id":this.statusService.getId(),"idDoc":idDoc};
      const json = JSON.stringify(this.ricUp);

      console.log(json);
      this.dbService.update2dbRicorsoStatus(json)
       // .map(res =>res.json())
       .subscribe(data => this.showSuccessMsg(data));


   }


   showSuccessMsg(data) {
    console.log(data);
    if (data.status == 'Successo!') {


       alert((data as any).msg);

       for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].idDocumento === (data as Resp).data.idRicorso) {
                  this.list[i].dataNomina = (data as Resp).data.dataNomina;
                  this.list[i].dataDepositoCancelleria = (data as Resp).data.dataDepositoCancelleria;
                  this.list[i].approvato = (data as Resp).data.approvato;
                  break;
              }
      }
       this.ricorsoModal.close();
  }
    if (data.status == 'Errore') {
      alert((data as any).msg);

    }


  }


   closed() {

    }

    dismissed() {

    }

    opened() {

    }

    openCopyRicorso(){
        this.copyRicorsoModal.open();
    }

    open() {
        this.deleteModal.open();
    }

    setModalCopyRicorso(numero: number, idDocumento: number) {
        this.idToCopy = idDocumento;
        this.number = numero;
        this.copyRicorsoModal.open();
    }

    setModalDelete(numero: number, idDocumento: number) {
      this.idToDelete = idDocumento;
      this.number = numero;
      this.deleteModal.open();
    }

    salvaModale() {
       this.deleteDoc(this.idToDelete);
       this.deleteModal.close();
    }

  print(kind, idTipoDocumento: string, ricorrenti, beneficiario) {

    // 1

    console.log('idx vale', this.copie.idx);


    this.ads = ricorrenti[this.copie.idx];
    console.log('ricorrente è ', this.ads);
    this.bene = beneficiario;
    console.log('beneficiario è ', this.bene);

    let _details = {};
    if (kind == 'note') {
      _details = this.statusService.getRicorso().details;
   }
    let data;

    if (kind == 'copy') {
       data = {ads: JSON.stringify(this.ads), copie: JSON.stringify(this.copie), bene: JSON.stringify(this.bene), idTipoDocumento, id: this.statusService.getSpecialId()};
    } else {
       data = {details: JSON.stringify(_details), ads: JSON.stringify(this.ads), copie: JSON.stringify(this.copie), bene: JSON.stringify(this.bene), idTipoDocumento, id: this.statusService.getSpecialId()};
    }


    const json = JSON.stringify(data);
    console.log('sono la print');

    console.log(json);


    let headers;
    switch (kind) {
          case 'copy':
          console.log(json);
          headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
          this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/copyRicorso.php`, json, {headers})
             //  .map(res => res.json())
               .subscribe(data => this.showDocumentCopy(data));

          break;
          case 'note': {
             console.log(json);
             headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
             this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/noteRicorso.php`, json, {headers})
               // .map(res => res.json())
               .subscribe(data => this.showDocumentNote(data));

             break;
          }

        }


  }

  disableOpenCopy() {
    this.linkRichiestaCopie = false;
  }

  showDocumentCopy(data) {
    this.pathRichiestaCopie = data.fileName;
    this.linkRichiestaCopie = data.linkRichiestaCopie;
    this.dbService.addZip(this._idRicorso, this.pathRichiestaCopie).subscribe(() => {});
    console.log('richiesta copie creata');
  }
  getCopyCompleted() {
    return this.linkRichiestaCopie;
  }

  getNoteCompleted() {
    return this.linkNotaIscrizione;
  }

  showDocumentNote(data) {
    this.pathNotaIscrizione = data.fileName;
    this.linkNotaIscrizione = true;
    this.dbService.addZip(this._idRicorso, this.pathNotaIscrizione).subscribe(() => {});
  }

 checkAccess() {
    this.statusService.getAccess().subscribe(data => {
      console.log(`checAccess ha ricevuto ${data}`);
      if (!data) {  this.router.navigate(['']); }


    });



}


  sortSurname(ascParam) {


     this.ascBene = !ascParam;






     if (this.account.kind == 'opr') {
    console.warn('sono un operatore');
    this.idSportello = this.statusService.getIdSportello();
    console.warn('idSportello: ', this.idSportello);
    if (this.idSportello != 0) {
        this.loadWindowArchiveListOrdered(this.idSportello, 'surname', this.ascBene, this.currentYear);


    }
   }


      this.loadAdminArchiveList();

  }
    sortCreationDate(ascParam) {
        this.ascData = !ascParam;
        if (this.account.kind == 'opr') {
            console.warn('sono un operatore');
            this.idSportello = this.statusService.getIdSportello();
            console.warn('idSportello: ', this.idSportello);
            if (this.idSportello != 0) {
                console.warn('idSpertelo != 0');
                this.loadWindowArchiveListOrdered(this.idSportello, 'creationDate', this.ascData, this.currentYear);
            }
        }
        this.loadAdminArchiveList();
  }



   loadOperatorData() {

    this.dbService.getAccountOperatorFromId()
      // .map(res=>res.json())
       .pipe(catchError(error => this.handleError(error)))
      .subscribe(data => this.handleAnagrafica(data));

  }


  getOperatorAnagrafica(id) {
    if (this.operatorData != []) {
      if (id != 0) {
        //console.log(this.operatorData[id]);
        return this.operatorData[id].anagrafica;
      } else {
        return '';
      }
    } else { return ''; }
  }

handleAnagrafica(data) {

  this.operatorData = data;

}

  ngOnInit() {
    this.ascBene = true;
    this.ascData = true;
    this.checkAccess();

    this.operatorData = [];
    this.proposteNominativi = [];
    this.duplicaRicorsoSelection = new DataDuplicaRicorso();

    this.loadOperatorData();

    this.stateCopyDetails = 'inactive';
    console.log(this.statusService.getSpecialAccount());

    this.user = new Username();

    this.list = [];
    // recuper account operatore o admin (SpecialAccount)
    this.account = this.statusService.getSpecialAccount();

    console.warn('init RicrosoArchive - account:', this.account);

    this.idSportello = this.statusService.getIdSportello();
    if (this.account.kind == 'opr') {
        console.warn('sono un operatore');

        console.warn('idSportello: ', this.idSportello);
        if (this.idSportello != 0) {

            /* carico l'elenco delgli anni */
            this.dbService.getArchiveYearList(this.idSportello)
            .pipe(catchError(error => this.handleError(error)))
            .subscribe(data => this.setMaximumYear((data as Resp).data));


            /*carico elenco ricorsi per sportello, ultimo anno */
            console.warn('idSpertelo != 0');
            this.loadWindowArchiveList(this.idSportello);

        }
   }
    this.loadAdminArchiveList();


    if (this.idSportello != 0) {
       this.dbService.getWindowDetails(this.idSportello).subscribe(resp => this.setWindowDetails(resp.data));

    }



  }

  loadWindowArchiveListOrdered(idSportello:number,field:string,asc:any,year:number){
      this.dbService.loadWindowArchiveListOrdered(idSportello, field, asc, year)
          .pipe(catchError(error => this.handleError(error)))
          .subscribe(data => this.logList(this.list = (data as Resp).data));

  }


  loadWindowArchiveList(sportello:number, year?:number){
      this.dbService.loadWindowArchiveList(sportello,year)
          .pipe(catchError(error => this.handleError(error)))
          .subscribe(data => this.logList(this.list = (data as Resp).data));
  }

  loadAdminArchiveList(){
      if (this.account.kind == 'adm') {
          this.dbService.loadArchiveList()
              .pipe(catchError(error => this.handleError(error)))
              .subscribe(data => this.logList(this.list = (data as Resp).data));
      }
  }

  setWindowDetails(sportello: DataSportello) {
      console.log('setWindowDetails');
      this._sportello = sportello;
      console.log(this._sportello);
  }

  updateRicorsoArchive() {
    if (this.idSportello != 0) {
        this.loadWindowArchiveList(this.idSportello,this.currentYear);
    }
  }

  setMaximumYear(data) {
    console.log('setMaximumYear data vale');
    console.log(data);
    if (data != undefined) {
      this.yearList = data;

      let max = data[0];
      for (let i = 1; i < data.length; i++) {
        if (data[i] > max) {
          max = data[i];
        }
      }

      console.log(`anno max ${max}`);
      this.currentYear = +max;
    }

  }

  ngOnDestroy() {
    this.searchSub.unsubscribe();
  }

  handleError(error: Response) {
    console.log('loggo l\'errore:', error);
    console.log('statusText: ', error.statusText);
    this.list = [];
    return Observable.throw(error);
  }

  isOperator() {
    return this.idSportello != 0;
  }

  logList(list) {
    console.log('recupero ricorsi terminato');
    console.log(list);

  }


  loadDoc(idDocumento: number) {

    console.log('loadDoc chiamata');





    this.dbService.getRicorsoFromDb(idDocumento)
    // .map(res => res.json())
    .subscribe(data => this.configLoadedDoc((data as Resp).data, idDocumento));
      // console.log(data));
  }





  deleteDoc(idDocumento: number) {
    console.log('deleteDoc called, idDoc:: ', idDocumento);
    this.dbService.deleteRequestRicorso(idDocumento)
    .subscribe(data => this.setDeleteRequest(data, idDocumento));

  }

    excludeBeneficiario(){
        this.duplicaRicorsoSelection.beneficiario=false;
    }
    excludeRicorrenti(){
        this.duplicaRicorsoSelection.ricorrenti=false;
    }

    copyRicorsoFromModal(idDocumento:number){
        this.copyRicorsoModal.close();
        console.log('copyRicorsoFromModal called, idDoc:: ', idDocumento);
        console.log(this.duplicaRicorsoSelection);
        this.dbService.copyRequestRicorso(idDocumento,this.duplicaRicorsoSelection.beneficiario,this.duplicaRicorsoSelection.ricorrenti)
            .subscribe(data => {
                console.log('done copyRicorsoFromModal');
                console.log(data);
                //the list of documents must be updated
                this.updateRicorsoArchive();
            });
    }


    setDeleteRequest(data, idDocumento) {
        this.list = [];
        console.log('set deleteDoc called');
        if (data.status == 'Success') {
            console.log('success');
            // aggiorno lista documenti
            this.account = this.statusService.getSpecialAccount();
            if (this.account.kind == 'opr') {
                console.warn('sono un operatore');
                this.idSportello = this.statusService.getIdSportello();
                if (this.idSportello != 0) {
                    this.loadWindowArchiveList(this.idSportello);
                }
           }
           this.loadAdminArchiveList();
        }
        if (data.status == 'Error') {
            if ((data as any).msg == 'documento non trovato') {
                this.list = [];
            }
        }
    }


  showDeleteIcon(idDoc) {
     for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].idDocumento === idDoc) {
                return !this.list[i].requestDelete;

            }
        }

  }




  showKindDoc(kind) {
    switch (kind) {

        case 'SELLIMM':
          return 'richiesta di autoriz. vendita immobile';
        case 'BUYIMM':
          return 'Richiesta di autoriz. acquisto beni immobili';
        case 'LEGACY':
          return 'richiesta di accettazione eredità';
        case 'SELLGOODS':
          return 'richiesta di autoriz. vendita beni';
        case 'BUYCAR':
          return 'richiesta autoriz. acquisto autovettura';
        case 'EXT':
          return 'richiesta mod. decreto di estensione nomina';
        case 'AUTH':
          return 'richiesta autorizzazione generica';

      }
  }


isAdmin() {
  return this.statusService.isAdmin();
}

  configLoadedDoc(content, idDocumento) {

    console.log('config Loaded Doc chiamata');



    this.statusService.setLoadedDoc(idDocumento, 'RICORSO');

    console.log(content);


    this.statusService.updateRicorso(content);
    this.statusService.setViewRicorso(false);
    this.router.navigate(['/ricorso']);


  }

    generaNotaRuolo(){
        console.log('genera nota ruolo lanciato da emit!');
        this.print('note', '', this._localRicorrenti, this._localBeneficiario);  // Luke 02/03/2021 : cognome beneficiario aggiunto ai documenti

    }

    disableOpenNotaRuolo() {
        this.linkNotaIscrizione = false;
    }

}
