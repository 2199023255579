import { Component, OnInit, Input } from '@angular/core';
import {DbService} from '../../services/db.service';
import {Resp} from '../../models/resp';
import { trigger,
    state,
    style,
    transition,
    animate,
    keyframes } from '@angular/animations';

@Component({
  selector: 'details-panel',
  templateUrl: './details-panel.component.html',
  styleUrls: ['./details-panel.component.css'],
  animations: [

        trigger('roundIcon', [
            state('inactive', style({
                borderColor: '#FFF'
              
            })),
            state('active', style({
               
                borderColor: '#777'
            })),
            transition('inactive => active', animate('100ms ease-in')),
            transition('active => inactive', animate('100ms ease-out'))
        ]),
         trigger('dimensionBox', [
            state('inactive', style({
                height: '0px',
                borderColor: '#FFF',
                visibility:'hidden'
              
            })),
            state('active', style({
               
                height: '80px',
                borderColor: '#777',
                visibility:'visible'
            })),
            transition('inactive => active', animate('100ms ease-in')),
            transition('active => inactive', animate('100ms ease-out'))
        ]),

    ]
})
export class DetailsPanelComponent implements OnInit {

	@Input() state; 
	@Input() idRicorso;
	provvedimentoState:string;
	nominaState:string;
	verbaleState:string;
	fissazioneState:string;

  constructor(private dbService:DbService) { }

  ngOnInit() {
  	this.iconState='fa fa-plus-circle';
  	this.state='inactive';
  }

  getState(){
  	return this.state;
  }

 
  iconState:string='fa fa-plus-time';

  openDetailsAnim(){
  	if(this.state=='inactive'){
	  	this.iconState='fa fa-plus-circle';
	  	this.dbService.getRequestCopyState(this.idRicorso)
	  	// .map(res => res.json())
    .subscribe(data => this.assignState((data as Resp).data));
	  

	  	

	  }


	  this.state = (this.getState() === 'inactive' ? 'active' : 'inactive');
	  if(this.state=='inactive'){
	  	this.iconState='fa fa-plus-circle';
	  	
	  }
	  else
	  	this.iconState='fa fa-minus-circle';
  }

  assignState(data){
  		this.provvedimentoState=(data.statoProvvedimento  ? 'fa fa-check': 'fa fa-times');
	  	this.nominaState=(data.statoNomina ? 'fa fa-check': 'fa fa-times');
	  	this.verbaleState=(data.statoVerbale ? 'fa fa-check': 'fa fa-times');
	  	this.fissazioneState=(data.statoFissazione ? 'fa fa-check': 'fa fa-times');

	  	console.log(data);
	  	console.log(this.provvedimentoState);
	  	console.log(this.nominaState);
	  	console.log(this.verbaleState);
	  	console.log(this.fissazioneState);
  }

}
