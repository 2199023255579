import {Injectable} from '@angular/core';
import {Amministratore} from '../models/amministratore';
import {IndirizzoStrutturato} from '../models/indirizzo';

@Injectable()


export class DataExt extends Amministratore{

//estensione nomina
//obj-> tutti i vari campi che ha un ads nuovo ads con poteri disgiunti
//.$obj->cognome." ".$obj->nome.", .$obj->dataDiNascita." a ".$obj->luogoDiNascita.", residente a ".$obj->indirizzo.", tel: ".$obj->telefono." - C.F.: ".$obj->codiceFiscale

	public print:boolean;
	public view:boolean;
	public data:string;


  // Luke 28/01/2021 : aggiuto indirizzo strutturato
  // Luke 15/02/2021 : aggiunto sesso
	constructor(  nome:string="", cognome:string="", dataDiNascita:string="", codiceFiscale:string="",  luogoDiNascita:string="", public sesso:string="", indirizzo:string="", telefono:string="",  cellulare:string="",print:boolean=false,view:boolean=false,data:string="", public indirizzoStrutturato:IndirizzoStrutturato=new IndirizzoStrutturato()){
		super(nome,cognome,dataDiNascita,codiceFiscale,luogoDiNascita,indirizzo,telefono,cellulare,0,"","");
		this.print=print;
		this.view=view;
		this.data=data;

	}



}

