import { Component, OnInit, OnChanges, SimpleChange, SimpleChanges, Input} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders,HttpResponse } from '@angular/common/http';
import {DbService} from '../../services/db.service';
import {map} from 'rxjs/operators';
import {Observable,throwError} from 'rxjs';
import {LangService} from '../../services/lang.service';
import 'rxjs';
import {AppSettings} from '../../app-settings';
import {catchError } from 'rxjs/operators';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css'],
  providers:[DbService,LangService]
})


export class ResetpasswordComponent implements OnInit {



  public account: myAccount =null;  // original
  @Input() public pass:string;
  public backToLogin: boolean;
  public barLabel: string = "robustezza";

  // Luke: regex check
  public hasNumber: boolean;
  public hasSmallLetter: boolean;
  public hasBigLetter: boolean;
  public noSpecialChar: boolean;
  public zeroLength: boolean;


  public pwdOneFieldTextType: boolean;  // Hide/Show password one
  public pwdTwoFieldTextType: boolean;  // Hide/Show password two






  constructor(private route: ActivatedRoute,private http:HttpClient,private dbService:DbService, private langService:LangService) { }

   kind:string;

  ngOnInit() {
  	 // let email=this.route.snapshot.params['email'];  // original
     // let date=this.route.snapshot.params['date'];  // original
     // 21-12-2020 Luke
     this.backToLogin = false;

     // Luke 15/02/2021  regex password
     this.hasNumber = false;
     this.hasSmallLetter = false;
     this.hasBigLetter = false;
     this.noSpecialChar = false;
     this.zeroLength = true;

     // Luke 15/02/2021  Show/hide password fields
     this.pwdOneFieldTextType = false;
     this.pwdTwoFieldTextType = false;

     this.account = new myAccount();
     this.account.id= this.route.snapshot.params['id'];  // 21-12-2020 Luke
		 this.account.token= this.route.snapshot.params['token'];  // 21-12-2020 Luke
     this.kind=this.route.snapshot.params['kind'];
     // this.undecodePassword();  // original
     console.log('account id: '+this.account.id);  // Luke test 21-12-2020
     console.log('account token: '+this.account.token);  // Luke test 21-12-2020
     console.log('account kind: '+this.kind);  // Luke test 21-12-2020

  }

  /* Luke 15/02/2021 Hide/Show password fields */
  togglePwdOneFieldTextType() {
    this.pwdOneFieldTextType = !this.pwdOneFieldTextType;
  }

  togglePwdTwoFieldTextType() {
    this.pwdTwoFieldTextType = !this.pwdTwoFieldTextType;
  }



  /* Luke 15/02/2021 Password Regex Check */
  /*
  ngOnChanges(changes: SimpleChanges) { //(changes: {[propName: string]: SimpleChange}): void {
    var passwordToCheck = changes['passOne'].currentValue;
    console.log("resetPassword: ngOnChanges "+passwordToCheck);
    this.regexCheck(passwordToCheck);
}*/

  regexCheck(passwordToCheck: string) {
    console.log("resetPassword: regexCheck "+passwordToCheck);
    if(passwordToCheck.length != null && passwordToCheck.length> 0) {
      this.zeroLength = false;
      this.hasNumber = passwordToCheck.match(/\d/) ? true:false;
      this.hasSmallLetter = passwordToCheck.match(/[a-z]/) ? true:false;
      this.hasBigLetter = passwordToCheck.match(/[A-Z]/) ? true:false;
      //this.noSpecialChar = passwordToCheck.match(/[ /"'\s]/) ? true:false;  // no /, ", ' or whitespace char
    }
    else {
      this.hasNumber = false;
      this.hasSmallLetter = false;
      this.hasBigLetter = false;
      this.zeroLength = true;
    }
  }
  passwordOk() {
    return (this.hasNumber && this.hasSmallLetter && this.hasBigLetter /* && !this.noSpecialChar */);
  }

  send(){
    return this.langService.send();
  }
  passwordMismatch(){
    return this.langService.passwordMismatch();
  }


  undecodePassword(){



      var data = {agentID:this.route.snapshot.params['email'],data:this.route.snapshot.params['date']};

      var json=JSON.stringify(data);

      var params=json;


      var headers=new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
      return this.http.post(`${AppSettings.API_ENDPOINT}/php/undecodeParams.php`,params,{headers:headers})

       .pipe(catchError(error=>{return this.handleError(error);}))
      .subscribe(data=>{

        console.log(data);
        //posso fare che quando un utente arriav in questa pagina
        //abilito un account speciale nel db
        //che permette all metodo del db che userò (per modificare la password)
        //per autnenticare la chiaamta
        this.processDecodedParams((data as any).email);

      });

     }



     processDecodedParams(emailID){
       if(this.kind=='usr'){
     	console.log("processDecodedParams chiamata");
     	this.dbService.getAccountFromEmail(emailID)
     		.pipe(catchError(error=>{

          return  throwError("Utente/email inesistente")
         }))





     		.subscribe(
          data => this.setAccount(data),
          err => console.error(err)
          );
        }

        if(this.kind=='opr'){
          console.log("processDecodedParams chiamata");
       this.dbService.getAccountOperatorFromEmail(emailID)
       .pipe(
         catchError(error=>{
           return throwError("Utente/email inesistente");
         })
         )

         .subscribe(
          data => this.setAccount(data),
          err => console.error(err)
          );

        }



     }

     setAccount(user){
       this.account=user;
       this.account.pwd='';
     }

     resetPassword(user){

       console.log("resetPasswordCalled()");
       console.log(user);

       console.log("sto resettando la password");
        //var customerID=user.AG_RE_Cpt;
        //var customerMail=user.email;

      if(this.kind=='usr'){
            this.dbService.updateAccount(user)
            .pipe(
              catchError(err=>{
                //return throwError("Utente/email inesistente")
                console.log('Handling error locally and rethrowing it...', err);
                return throwError(err);
              })
              )

                .subscribe(
          data => this.displayConfirm(data),
          err => this.displayError(err)
          );

         }

         if(this.kind=='opr'){
           this.dbService.updateAccountOperator(user)
              .pipe(
              catchError(err=>{
                //return throwError("Utente/email inesistente")
                console.log('Handling error locally and rethrowing it...', err);
                return throwError(err);
              })
              )
                .subscribe(
          data => this.displayConfirm(data),  // console.log(data),
          err => this.displayError(err)
          );
         }

}


  // Luke: modificato l'accesso al json.. 21-12-2020
  public displayConfirm(obj){
    //let obj = JSON.parse(msg);
    this.account.pwd = "";
    this.account.pwd2 = "";
    this.backToLogin = true;  // show back to login link
    console.log('status: '+obj['status']+', msg: '+obj['msg']+', data: '+obj['data']);  // Luke test 21-12-2020
    alert(obj['status']+' '+obj['msg']);  // original
    //alert('Pippo'+msg.status+' '+msg.msg+'');  // Luke test 21-12-2020
  }

  public displayError(obj){
    //console.log(obj.error+' status: '+obj.status+', msg: '+obj.message);
    console.log(obj);
  }


  private handleError(error: Response) {
        console.error(error);

        return Observable.throw(error);
        //return Observable.throw('Pippo Pippo');  // Luke test 21-12-2020
  }
}
// 21-12-2020 Luke
/* Inner class for account data */
class myAccount {

  constructor(public active:boolean=false,public id:number=null,public email:string="",public token:string="",public pwd:string=null,public pwd2:string=null){

  }
}
