import { Component, OnInit, Input,Output, EventEmitter,ViewChild } from '@angular/core';
import {StatusService} from '../../services/status.service';
import {DbService} from '../../services/db.service';
import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import {Resp} from '../../models/resp';


@Component({
  selector: 'icon-list',
  templateUrl: './icon-list.component.html',
  styleUrls: ['./icon-list.component.scss']
})
export class IconListComponent implements OnInit {

 

	@Input() kind:string;
	@Input() size:string;
  @Input() where:string;
  @Input() whereOpt2:string;
  @Input() disabled?:boolean;
  @Input() id:string;
 
  @Input() numericId:number;
  @Input() index:number;
  @Output() action=new EventEmitter();
  @Output() deleteCompleted=new EventEmitter();
 

  lanciaClick(){
    this.action.emit();
  }



  constructor(public statusService:StatusService, public dbService:DbService) { }

  ngOnInit() {

   

    if(this.id=="idBene"){
        this.disabled=!this.statusService.getAccount().completeAds;
     //   console.log("chiamato get account(), che vale : ",this.statusService.getAccount());

    }
    if(this.id=="idAds"){
   //   console.log("idAds ed è ");
      this.disabled=(!this.statusService.getLoadedAccount() && (this.statusService.getSpecialAccount().kind=='opr' || this.statusService.getSpecialAccount().kind=='adm')) ;
    //  console.log(this.disabled);
    }

    if(this.id=="idList"){
        this.disabled=!this.statusService.getAccount().completeBene;

    }
   // console.log("chiamato get account(), che vale : ",this.statusService.getAccount());


    //console.log(this.id," ", this.disabled);
  }

  selected(){
    if(this.index!=undefined)
      return this.statusService.getSelectedBeneficiario(this.index);
    else
      return false;
  }

  selectItem(){
    
    this.statusService.updateSelectedBeneficiario(this.index);
  }

   @ViewChild('deleteModal',{static:false})
    deleteModal: BsModalComponent;
 

  setModalDelete(){
     console.warn('click per apertura modale');
     this.deleteModal.open();
  }

   closed() {
       
    }

    dismissed() {
       
    }

    opened() {
       
    }

   
    open() {
        this.deleteModal.open();
    }

      salvaModale(){
       //this.deleteDoc(this.numericId);
       console.warn(`cancello il beneficiario n. ${this.index} con id ${this.numericId}`);
       this.dbService.delBeneficiario(this.numericId).subscribe((resp)=>{
         this.deleteCompleted.emit();
         alert((resp as Resp).msg);
       });
       this.deleteModal.close();
    }

}
