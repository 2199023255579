import { Component, OnInit, OnDestroy } from '@angular/core';
import {IconComponent} from '../icon/icon.component';
import {StatusService} from '../../services/status.service';
import {DbService} from '../../services/db.service';
import { debounceTime,switchMap } from 'rxjs/operators';
import {Beneficiario} from '../../models/beneficiario';


import { Subscription,of } from 'rxjs';

import {Resp} from '../../models/resp';

import { RouterModule, Routes, Router, ActivatedRoute, Params, RouterEvent,NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers:[DbService]

})
export class DashboardComponent implements OnInit {

  constructor(private router:Router,private statusService:StatusService, private dbService:DbService) {

       this.routerSub=this.router.events.subscribe(event =>{
           // console.log(event);
            if(event instanceof NavigationEnd){

                 this.checkAccess();

                    this.idAccount=this.statusService.getId();

                    if(this.idAccount>0)

                      this.dbService.getAmministratore(this.idAccount)

                        .subscribe(data => this.updateAmministratore((data as Resp).data));




                    console.log("da dashboard: idAccount:", this.statusService.getId());

                 console.warn("dashboard routing event!");





      }// end of Nav Event
    });


   }


  private routerSub:Subscription;
  idAccount:number;
  idBeneficiario:number;
  idBeneficiarioArray:number[];


 checkAccess(){
    this.statusService.getAccess().subscribe(data=>{
      console.log(`checkAccess ha ricevuto ${data}`);
      if(!data)  this.router.navigate(['']);


    });



}

  ngOnInit() {

  }

  ngOnDestroy(){
    this.routerSub.unsubscribe();
  }


  updateBenefList(){
       this.idAccount=this.statusService.getId();

                    if(this.idAccount>0)

                      this.dbService.getAmministratore(this.idAccount)

                        .subscribe(data => this.updateAmministratore((data as Resp).data));
  }


    setNewDocument(){
      console.log("setNewDocument da dashboard chiamata");
      this.statusService.setNewDoc('ALL');
    }

    getCognomeBenefFromId(id){
     return this.dbService.getBeneficiario(this.idBeneficiario).pipe(debounceTime(10000),switchMap(benef=>{
        return of((benef as Beneficiario).cognome)} )
      );
    }


    updateAmministratore(amm){


  		this.statusService.updateAmministratore(amm);

  		this.idBeneficiario=this.statusService.getIdBeneficiario();
      this.idBeneficiarioArray=this.statusService.getIdBeneficiarioArray();
  		if(this.idBeneficiario==null)
  			this.idBeneficiario=0;
  		console.log("da dashboard: idbenef => ",this.idBeneficiario);
      console.log("idBeneficiarioArray vale");
      console.log(this.idBeneficiarioArray);


  }

}
