import { Injectable } from '@angular/core';

@Injectable()
export class Username {

  constructor(public userName:string="", public password:string="") { }

 

}
