import {Injectable} from '@angular/core';
import {Ricorrente} from '../models/ricorrente';
import {Beneficiario} from '../models/beneficiario';


@Injectable()


export class DataRaccomandate {


	public ricorrenti:Ricorrente[];
	public idx:number;
	public beneficiario:Beneficiario;
	public residenzaAlt:string;


	constructor(bene:Beneficiario=new Beneficiario(),ric:Ricorrente[]=[new Ricorrente(),new Ricorrente(),new Ricorrente(),new Ricorrente(),new Ricorrente(), new Ricorrente()],
 ){
		this.beneficiario=bene;
		this.ricorrenti=ric;
		this.idx=0;
		this.residenzaAlt='';
		

	}
}
