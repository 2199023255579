import { Component, OnInit } from '@angular/core';
import {DocumentIconComponent} from '../document-icon/document-icon.component';

import {AppSettings} from '../../app-settings';
import {DbService} from '../../services/db.service'; 
import {StatusService} from '../../services/status.service';

@Component({
  selector: 'documentIconSellGoods',
  templateUrl: './document-icon-sell-goods.component.html',
  styleUrls: ['./document-icon-sell-goods.component.css'],
  providers:[DbService]
})
export class DocumentIconSellGoodsComponent extends DocumentIconComponent {
	

 
}
