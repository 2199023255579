import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LangService} from '../../services/lang.service';
import {DomicilioDigitale} from '../../models/domicilio-digitale';

@Component({
  selector: 'input-domicilio-digitale',
  templateUrl: './input-domicilio-digitale.component.html',
  styleUrls: ['./input-domicilio-digitale.component.scss'],
    providers: [LangService]
})
export class InputDomicilioDigitaleComponent implements OnInit {

    @Input() forModal:boolean;
    @Input() dom:DomicilioDigitale;
    @Output() creaDomicilioDigitale = new EventEmitter();
    @ViewChild('domicilioForm',{static:false}) form;

  constructor(private langService:LangService) { }

  ngOnInit() {
  }

    submitForm() {
        this.form.onSubmit();
        if(this.forModal){
            if(this.send())
                this.creaDomicilioDigitale.emit();
        }
    }


    send() {
        return this.form.valid;
    }

    LabelRg(){
      return this.langService.LabelRg();
    }

    LabelCartaIdNumero(){
      return this.langService.LabelCartaIdNumero();
    }

    LabelPec(){
      return this.langService.LabelPec();
    }

    LabelCartaIdRilasciato(){
      return this.langService.LabelCartaIdRilasciato();
    }
    LabelDelegatoNome(){
      return this.langService.LabelDelegatoNome();
    }
    LabelDelegatoCognome(){
      return this.langService.LabelDelegatoCognome();
    }

    LabelCartaIdRilasciatoData(){
      return this.langService.LabelCartaIdRilasciatoData();
    }

}
