import { Injectable } from '@angular/core';
import {Anagrafica} from './anagrafica';


export class Amministratore extends Anagrafica {


    //public telefono:string="", public cellulare:string="", public idTribunale:number=0, public dataNomina:string="", public numero:string="",public sesso:string="",public idAccount?:number, public idBeneficiario?:number[], public kind?:string)

	constructor(public nome:string="",
                public cognome:string="",
                public dataDiNascita:string="",
                public codiceFiscale:string="",
                public luogoDiNascita:string="",
                public indirizzo:string="",
                public telefono:string="",
                public cellulare:string="",
                public idTribunale:number=0,
                public dataNomina:string="",
                public numero:string="",
                public sesso:string="",
                public idAccount:number=-1,
                public idBeneficiario:number[]=[],
                public kind:string="")
    {
		super(nome,cognome,dataDiNascita,codiceFiscale,luogoDiNascita,indirizzo);
	}


	updateAmmonistratore(amm:Amministratore){


		this.nome=amm.nome;
		this.idAccount=amm.idAccount;
		console.log('updateamm su amministratore.ts, ')
		console.log(amm.idBeneficiario);
		this.idBeneficiario=[...new Set(amm.idBeneficiario)];
		let locArray=[];
		this.idBeneficiario.forEach((id)=>{
			if(id!=null)
				locArray.push(id);
		});
		this.idBeneficiario=[...locArray];

		this.cognome=amm.cognome;
		this.dataDiNascita=amm.dataDiNascita;
		this.luogoDiNascita=amm.luogoDiNascita;
		this.codiceFiscale=amm.codiceFiscale;
		this.indirizzo=amm.indirizzo;
		this.telefono=amm.telefono;
		this.cellulare=amm.cellulare;
		this.idTribunale=amm.idTribunale;
		this.dataNomina=amm.dataNomina;
		this.numero=amm.numero;
		this.sesso=amm.sesso;
		this.kind=amm.kind;
	}

}
