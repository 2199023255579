import { Component, OnInit } from '@angular/core';
import { DocumentIconComponent } from '../document-icon/document-icon.component';

import {AppSettings} from '../../app-settings';
import {DbService} from '../../services/db.service'; 
import {StatusService} from '../../services/status.service';

@Component({
  selector: 'documentIconExt',
  templateUrl: './document-icon-ext.component.html',
  styleUrls: ['./document-icon-ext.component.css'],
  providers:[DbService]
})
export class DocumentIconExtComponent extends DocumentIconComponent {


}
