import { Component, OnInit,ViewChild } from '@angular/core';
import { RicorrenteComponent} from '../ricorrente/ricorrente.component';
import { LangService} from '../../services/lang.service';
import { Beneficiario } from '../../models/beneficiario';
import {Ricorso } from '../../models/ricorso';
import {DataRicorso} from '../../data/data-ricorso';
import {Ricorrente} from '../../models/ricorrente';
import { Subscription } from 'rxjs';

import {StatusService} from '../../services/status.service';
//import {DataSellImm} from '../../data-sell-imm';
import {DbService} from '../../services/db.service';
import { HttpHeaders,HttpClient} from '@angular/common/http';
import {AppSettings} from '../../app-settings';
import {Router} from '@angular/router';
import {NavigationEnd} from '@angular/router';

import {Resp} from '../../models/resp';

import { FlashMessagesService } from 'angular2-flash-messages';

import { SafeHtmlPipe } from '../../safe-html.pipe';
import {formatDate} from '@angular/common';


export class SavedRicorso {
  constructor(
    public status:string,
    public id:number,
    public msg:string,
    public data:string,
    public QUERY:string
    ){}

};

enum valueAction {
  AUTONOMO,
  ASSISTENZA,
  SOSTITUITO,
  OUT
};




@Component({
  selector: 'app-ricorso',
  templateUrl: './ricorso.component.html',
  styleUrls: ['./ricorso.component.css'],
  providers: [LangService,DbService]
})
export class RicorsoComponent implements OnInit {

   public statusPrintRicorso:boolean;
  public statusViewRicorso:boolean;

//verificare se sercono... ads, bene
  private ads;
  private bene;
  private obj;
  public showWhere:string;

   private disableRefresh:boolean;


   private ricorsoDraft;

   public actionsN:any;
   public checkAccordo:boolean;
   public checkRifiuta:boolean;
   public autoricorso:boolean;
   public routerSub:Subscription;
   public dataDocumento:string;


   constructor(private router:Router,private _flashMessagesService: FlashMessagesService,private langService:LangService, public statusService:StatusService,public dbService:DbService, public http:HttpClient) {


    this.statusPrintRicorso=statusService.getRicorso().print;
      console.log("statusPrint: ",this.statusPrintRicorso);
      this.statusViewRicorso=statusService.getRicorso().view;
      console.log("statusView:",this.statusViewRicorso);
      this.dataRicorso=new DataRicorso();

      this.dataRicorso=this.statusService.getRicorso();

         this.routerSub=this.router.events.subscribe(event =>{
           // console.log(event);
            if(event instanceof NavigationEnd){

                 console.warn("scheudle/n ROUTING EVENT!");

                 this.init();


               }
             });


     console.log("_____costruttore chiamato_____");

  }

  private disableForm=false;
  private hideSuccessMessage=true;

  public statusMsg;

 private dataRicorso;

 public goToRelatives:boolean;

 checkAccess(){
    this.statusService.getAccess().subscribe(data=>{
      console.log(`checAccess ha ricevuto ${data}`);
      if(!data)  this.router.navigate(['']);


    });



}

@ViewChild('accordo',{static:false}) accordo;
@ViewChild('rifiuta',{static:false}) rifiuta;

assertVolontaAccordo(){
  console.log(this.accordo);
  this.dataRicorso.details.volontaAccordo=this.accordo.nativeElement.checked;


  this.dataRicorso.details.volontaRifiuta=false;
  console.log(`ho impostato volonta accordo a true ${this.dataRicorso.details.volontaAccordo} e volontaRifiuta a false ${this.dataRicorso.details.volontaRufiuta} `);
  console.log("assertVolontaAccordo");
  this.checkAccordo=true;
  this.checkRifiuta=false;
}

assertVolontaRifiuta(){
  console.log(this.rifiuta);
  this.dataRicorso.details.volontaRifiuta=this.rifiuta.nativeElement.checked;
  this.dataRicorso.details.volontaAccordo=false;

  console.log(`ho impostato volonta accordo a false ${this.dataRicorso.details.volontaAccordo} e volontaRifiuta a true ${this.dataRicorso.details.volontaRufiuta} `);

  this.checkAccordo=false;
  this.checkRifiuta=true;
}

updateAutoricorso($event){
  console.log("update autoricorso!");
  console.log($event);

    this.autoricorso=$event.autoricorso;

    if($event.autoricorso){
      this.dataRicorso.beneficiario.nome=$event.ricorrente.nome;
      this.dataRicorso.beneficiario.cognome=$event.ricorrente.cognome;
      this.dataRicorso.beneficiario.codiceFiscale=$event.ricorrente.codiceFiscale;
      this.dataRicorso.beneficiario.luogoDiNascita=$event.ricorrente.luogoDiNascita;
      this.dataRicorso.beneficiario.dataDiNascita=$event.ricorrente.dataDiNascita;
      this.dataRicorso.beneficiario.sesso=$event.ricorrente.sesso;
      this.dataRicorso.beneficiario.codiceFiscale=$event.ricorrente.codiceFiscale;
      this.dataRicorso.beneficiario.indirizzo=$event.ricorrente.indirizzo;
    }

}




  ngOnInit() {

    this.init();



  }




  init(){




      this.autoricorso=false;
    this.goToRelatives=this.statusService.getRicorso().goToRelatives;
    this.ricorsoDraft=this.statusService.getRicorso().details.draft;
    console.log(`goToRelatives vale ${this.goToRelatives}`);


    console.log("/ricororso -> ng init chiamata");
    //this.nRicorrenti="1";
    this.checkAccess();
    this.dataRicorso=this.statusService.getRicorso();
    console.log('dataRicorso vale; ');
    console.log(this.dataRicorso);
    for(let ric of this.dataRicorso.ricorrenti){
       if(ric.candidato=='autoricorso'){
         this.autoricorso=true;
         console.log("fase di init, autoricorso messo a true");
       }
    }
    this.checkAccordo=this.dataRicorso.details.volontaAccordo;

    this.checkRifiuta=this.dataRicorso.details.volontaRifiuta;

    this.disableRefresh=false;

    this.statusPrintRicorso=this.statusService.getRicorso().print;
    this.statusViewRicorso=this.statusService.getRicorso().view;


    if(!this.dataRicorso.details.azioni || this.dataRicorso.details.azioni.length==0){


        console.log("azioni ha lunghezza zero");

    /*  if(this.dataRicorso.details.azioni==undefined){
        this.dataRicorso.details.azioni=new Array(10).fill({"description"})
      }*/






      this.dataRicorso.details.azioni=[{"description":"Il beneficiario è titolare di quale stipendio o pensioni?","value":''},
      {"description":"Il beneficiario è titolare di conti o depositi in banca od alle Poste?","value":''},
  {"description":"Il beneficiario è proprietario di quali immobili?","value":''},
  {"description":"Il beneficiario sostiene una spesa mensile (utente, canoni, rette, badanti...) di circa euro...","value":''},
  {"description":"A causa della sua situazione, la persona beneficiaria può compiere, da sola, i seguenti atti:","value":''},
  {"description":"non può compiere, da sola, i seguenti atti","value":''}






      ];
  }

    this.actionsN=[...Array(this.dataRicorso.details.azioni.length).keys()];

    console.log(this.dataRicorso.details.azioni);
    console.log(this.actionsN);


  }

  ngOnDestroy(){
    this.routerSub.unsubscribe();

    console.log("componente distrutta");
  }




  ngAfterViewInit(){

      if(this.statusService.updateDoc('RICORSO')){
        console.log('ricorso in modalità update')
        let id=+this.statusService.getIdDocToSave();
          if(id!=-1){
            console.log('id diverso da -1')
            this.nav.navs.push( {'to':'/extraRicorso/'+id,'text':'extra','icon':'external-link-square'});
           } else {
             console.log('id vale -1')
           }
      } else {
        console.log('ricorso NON è in modalità update')
      }
  }



  ngAfterViewChecked(){
   // console.warn("after view checked");

  }


  ngAfterContentChecked(){
    //console.warn("ngAfterContentChecked");

    //if(!this.disableRefresh)
     //

   this.disableRefresh=false;

  }


    showUpdateButton(){
    return this.statusService.updateDoc('RICORSO');
  }

  updateMessage(){
    this._flashMessagesService.show(this.statusMsg, { cssClass: 'alert-success', timeout: 4800 });
    console.warn("updateMessage()");
  }

  //nRicorrenti:string;
  //beneficiario=new Beneficiario();
  //details = new Ricorso();
   disableCreateAccountForm=false;
   createAccountMessage="ricorso creato con successo!";
   hideCreateAccuontMessage=true;

    backToLogIn(){
      return this.langService.backToLogIn();
    }


    nuovoDocumento(){
      return this.langService.nuovoDocumento();
    }

    aggiornaDocumento(){
      return this.langService.aggiornaDocumento();
    }



    @ViewChild('nav',{static:false}) nav;
    //ric1;ric2;ric3;ric4;ric5;ric6;
    @ViewChild('ric1',{static:false}) ric1;
    @ViewChild('ric2',{static:false}) ric2;
    @ViewChild('ric3',{static:false}) ric3;
    @ViewChild('ric4',{static:false}) ric4;
    @ViewChild('ric5',{static:false}) ric5;
    @ViewChild('ric6',{static:false}) ric6;

    @ViewChild('ricorsoForm',{static:false}) ricForm;
    @ViewChild('detailsForm',{static:false}) detForm;
    @ViewChild('anagrafica',{static:false}) refBeneficiario;


    createRicorso(){
      console.log("bottone cliccato");
      console.log("riccorrenti: ",this.dataRicorso.details.nRicorrenti);

      let ricorrentiValid=false;

      switch(this.dataRicorso.details.nRicorrenti.toString()){


          case '0':{
              console.log("nessun ricorrente");
              ricorrentiValid=true;

          break;
        }

        case '1':{
          console.log("un ricorrente");
          console.log(this.ric1.ricorrente);
          this.ric1.form.onSubmit();
          ricorrentiValid=this.ric1.isValid();
        break;
      }
        case '2':{
           console.log("due ricorrenti");
           console.log(this.ric1.ricorrente);
           console.log(this.ric2.ricorrente);
            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid();
        break;
      }
        case '3':{
            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid();

        break;
      }
        case '4': {
            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid();

        break;
      }
        case '5': {
            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid();


        break;
      }
        case '6': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid();



        break;
      }

      default: {
         console.log("lo switch non ha fatto match");

      }



      }//chiuso switch


      if(ricorrentiValid){
        console.log("tutti i ricorrenti sono validi");
      }else
        console.log("esiste almeno un ricorrente non valido");

      this.ricForm.onSubmit();
      this.detForm.onSubmit();
      if(!this.autoricorso)
        this.refBeneficiario.form.onSubmit();

      if(!this.autoricorso)
        return (this.ricForm.valid && this.detForm.valid && ricorrentiValid && this.refBeneficiario.form.valid);
      else
         return (this.ricForm.valid && this.detForm.valid && ricorrentiValid);



    }//chiuso CreateRicorso









  setElencoParenti(data){


    console.warn("setElencoParenti()");
    console.log("parenti caricati da db:");
    console.log(data);
    if(data!=null)
      if(data.parenti!=false){
      console.log("ho caricato i parenti dal db");
      this.statusService.updateElencoParentiInRicorso(data.parenti);

      let _currentPar=this.statusService.getParenti();
      console.log('componente ricorso, attualmente elenco parenti vale:')
      console.log(_currentPar.ricorrenti);

     /* if(_currentPar.ricorrenti.length<=10){
        while(_currentPar.ricorrenti.length<20){
          _currentPar.ricorrenti=[..._currentPar.ricorrenti,new Ricorrente()];
        }

        console.log('elenco parenti reinizializzato a 20 slot')
        this.statusService.updateElencoParentiInRicorso(_currentPar);
        console.log(_currentPar.ricorrenti);
      } */

      this.statusService.updateDetailsInRicorso(data.details);

      this.statusService.setRelativesCompleted(true);
      console.warn('elenco parenti caricato dal db')
    }else {
      //parenti non cariati
        //elenco parenti vuoto
        this.statusService.setRelativesCompleted(true);
        console.error("errore nel caricmento dell'elenco parenti, elenco parenti vuoto magari");
    }

   if(this.statusService.getRicorso().relativesCompleted){
         this.statusService.setPrintRicorso(true);
         this.statusPrintRicorso=this.statusService.getRicorso().print;
      }


     // this.dataRicorso=this.statusService.getParenti();

  }


  saveData(){
    if(!this.ricorsoDraft){

        this.disableRefresh=true;

          this.statusService.setNewDoc('RICORSO');

           this.statusService.setPrintRicorso(false);
            this.statusService.setViewRicorso(false);
              this.statusPrintRicorso=this.statusService.getRicorso().print;
            this.statusViewRicorso=this.statusService.getRicorso().view;

        if(this.createRicorso()){ //la create ricorso deve impostare i valori di DataRicorso
          //no, il binding deve avvenire nel form
          this.disableForm=true;
          this.hideSuccessMessage=false;
          this.dataRicorso.details.draft=this.ricorsoDraft;
          this.statusService.updateRicorso(this.dataRicorso);


          //carico i dati dell'elenco parenti






          this.statusMsg="campi impostati correttamente";
          this.updateMessage();




          console.log("campi impostati correttamente");

        }else
        console.log("campi non impostati correttamente");
    }
  }


  getGoToRelavies(){
    return this.goToRelatives;
  }


  updateDraft(event){
    console.warn(`update draft event ${event}`);
    this.ricorsoDraft=event;

    if(this.ricorsoDraft){
        this.hideSuccessMessage=true;
    } else{
       this.enableForm()

    }

    this.saveDraft();
  }

  saveDraft(){
    console.warn(`ricorso draft: ${this.ricorsoDraft}`);
     if(this.ricorsoDraft){

      let id=+this.statusService.getIdDocToSave();
      if(id!=-1 && id !=undefined){

      } else {
          this.statusService.setNewDoc('RICORSO');

          this.statusService.setPrintRicorso(false);
          this.statusService.setViewRicorso(false);
          this.statusPrintRicorso=this.statusService.getRicorso().print;
          this.statusViewRicorso=this.statusService.getRicorso().view;

      }

      this.dataRicorso.details.draft=this.ricorsoDraft;
      this.statusService.updateRicorso(this.dataRicorso);
      this.save();
    }
  }



  updateData(){
     if(!this.ricorsoDraft){

          this.statusService.setPrintRicorso(false);
          this.statusService.setViewRicorso(false);
            this.statusPrintRicorso=this.statusService.getRicorso().print;
          this.statusViewRicorso=this.statusService.getRicorso().view;

        if(this.createRicorso()){
          this.disableForm=true;
          this.hideSuccessMessage=false;
          this.dataRicorso.details.draft=this.ricorsoDraft;
          this.statusService.updateRicorso(this.dataRicorso);

          let id=+this.statusService.getIdDocToSave();
          if(id!=-1){
            console.warn(`id del ricorso corrente vale ${id}`);
              this.dbService.getParentiFromDb(+this.statusService.getIdDocToSave()).subscribe(data => this.setElencoParenti((data as Resp).data));

            this.statusService.setGoToRelatives(true);
            this.goToRelatives=this.statusService.getRicorso().goToRelatives;

          }



          this.statusMsg="campi aggiornati correttamente";
          this.updateMessage();


        console.error(`goToRelatives vale ${this.goToRelatives}`);


        }
    }
  }

  enableForm(){
       this.hideSuccessMessage=true;
       this.disableForm=false;
       this.statusMsg="documento pronto per essere modificato";

       this.updateMessage();

        this.statusService.setPrintRicorso(false);
        this.statusService.setViewRicorso(false);
        this.statusPrintRicorso=this.statusService.getRicorso().print;
        this.statusViewRicorso=this.statusService.getRicorso().view;



    }

   activateForm(){
       this.hideSuccessMessage=true;
       this.disableForm=false;

     }

    clear(){
        this.statusService.updateRicorso(new DataRicorso());
        this.statusService.setPrintRicorso(false);
        this.statusService.setViewRicorso(false);
        this.statusPrintRicorso=this.statusService.getRicorso().print;
        this.statusViewRicorso=this.statusService.getRicorso().view;
        this.dataRicorso=this.statusService.getRicorso();
        this.activateForm();
        this.statusMsg="nuovo documento generato con successo";
        this.updateMessage();
    }






   secondStepPrint(sportello){
        console.log(`secondStepPrint sportello vale`);
        console.log(sportello);

//1
   this.obj=this.statusService.getRicorso();



     const _par=this.statusService.getParenti();




    console.log("print() called(),oggetto ricorso");
    console.log(this.obj);
    console.log("oggetto parenti");
    console.log(_par);

  /*  console.log(` this.dataRicorso.details.volontaAccordo ${this.dataRicorso.details.volontaAccordo}`);
    console.log(` this.dataRicorso.details.volontaRifiuta ${this.dataRicorso.details.volontaRifiuta}`);
   console.log(` this.obj.details.volontaAccordo ${this.obj.details.volontaAccordo}`);
    console.log(` this.obj.details.volontaRifiuta ${this.obj.details.volontaRifiuta}`);*/

    //faccio chiamata a libreria per creare pdf
    let _volonta='';
    if(this.obj.details.volontaAccordo)
      _volonta="Il beneficiando è d'accordo alla misura di protezione.";
    if(this.obj.details.volontaRifiuta)
      _volonta="Il beneficiando rifiuta la misura di protezione.";

    const data = {nRicorrenti:this.obj.details.nRicorrenti,details:JSON.stringify(this.obj.details),ric1:JSON.stringify(this.obj.ricorrenti[0]),
              ric2:JSON.stringify(this.obj.ricorrenti[1]),
              ric3:JSON.stringify(this.obj.ricorrenti[2]),
              ric4:JSON.stringify(this.obj.ricorrenti[3]),
              ric5:JSON.stringify(this.obj.ricorrenti[4]),
              ric6:JSON.stringify(this.obj.ricorrenti[5]),
              beneficiario:JSON.stringify(this.obj.beneficiario),
              id:this.statusService.getId(),
              actions:JSON.stringify(this.obj.details.azioni),
              needs:JSON.stringify(this.obj.details.bisogni),
              durata:this.obj.details.durata,
              durataValue:this.obj.details.durataValue,
              volonta:_volonta,
              nParenti:_par.details.nRicorrenti,
              detailsParenti:JSON.stringify(_par.details),
              ric1Parenti:JSON.stringify(_par.ricorrenti[0]),
              luogo:sportello.comune,
              ric2Parenti:JSON.stringify(_par.ricorrenti[1]),
              ric3Parenti:JSON.stringify(_par.ricorrenti[2]),
              ric4Parenti:JSON.stringify(_par.ricorrenti[3]),
              ric5Parenti:JSON.stringify(_par.ricorrenti[4]),
              ric6Parenti:JSON.stringify(_par.ricorrenti[5]),
              ric7Parenti:JSON.stringify(_par.ricorrenti[6]),
              ric8Parenti:JSON.stringify(_par.ricorrenti[7]),
              ric9Parenti:JSON.stringify(_par.ricorrenti[8]),
              ric10Parenti:JSON.stringify(_par.ricorrenti[9]),
              ric11Parenti:JSON.stringify(_par.ricorrenti[10]),
              ric12Parenti:JSON.stringify(_par.ricorrenti[11]),
              ric13Parenti:JSON.stringify(_par.ricorrenti[12]),
              ric14Parenti:JSON.stringify(_par.ricorrenti[13]),
              ric15Parenti:JSON.stringify(_par.ricorrenti[14]),
              ric16Parenti:JSON.stringify(_par.ricorrenti[15]),
              ric17Parenti:JSON.stringify(_par.ricorrenti[16]),
              ric18Parenti:JSON.stringify(_par.ricorrenti[17]),
              ric19Parenti:JSON.stringify(_par.ricorrenti[18]),
              ric20Parenti:JSON.stringify(_par.ricorrenti[19]),
              data:JSON.stringify(this.obj.data)






            };



    const json=JSON.stringify(data);



        console.log(json);



        const headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
       return this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/ricorso.php`,json,{headers:headers})
        // .map(res => res.json())
         .subscribe(dataPost => this.showDocument(dataPost));



















     //2








   }








  print(){



  this.dbService.getWindow(this.statusService.getIdSportello())
    //.map(res =>res.json())
    .subscribe(data => this.secondStepPrint((data as Resp).data[0]));
   }

    //1







  showDocument(data){

    this.showWhere=data.fileName;

    this.statusService.setViewRicorso(true);
    this.statusViewRicorso=this.statusService.getRicorso().view;
     this.statusMsg="documento generato con successo";
    this.updateMessage();


  }


  showApiMsg(data){
    this.statusMsg=data.msg;
    this.updateMessage();
  }


   showSuccessMsg(msg){
    console.log(msg);
     this.statusMsg="documento salvato con successo";
    this.updateMessage();



  }

save(){
    console.log("called save()");


    this.obj=this.statusService.getRicorso();

    let idOperatore=this.statusService.getSpecialId();

  //console.log(this.obj);

   var update= this.statusService.updateDoc('RICORSO');
    var data;
    var json;





   if(update){
      let idDoc=this.statusService.getIdDocToSave();

      if(this.statusViewRicorso){
         this.dbService.addZip(idDoc,this.showWhere).subscribe((data)=>{
           console.log('_____addZip ricorso');
           console.log(data);
           console.log('____');

           setTimeout(()=>{
             this.showApiMsg(data);
           },1500);
         });
      }



      //this.obj.details.azioni=JSON.stringify(this.obj.details.azioni);

  data = {"details":JSON.stringify(this.obj.details),
          "rics":JSON.stringify(this.obj.ricorrenti),
          "beneficiario":JSON.stringify(this.obj.beneficiario),
          "idDoc":idDoc,
          "modificationAuthor":idOperatore,
          "data":JSON.stringify(this.obj.data)
  };


      json=JSON.stringify(data);
      //console.log("update");
      //console.log(json);
     this.dbService.update2dbRicorso(json)
       //.map(res =>res.json())
       .subscribe(data => this.showSuccessMsg(data));

    }else
    {




     data = {"details":JSON.stringify(this.obj.details),
             "rics":JSON.stringify(this.obj.ricorrenti),
             "beneficiario":JSON.stringify(this.obj.beneficiario),
             "idSportello":this.statusService.getSportelloOpr(),
             "kind":"RICORSO",
             "creationAuthor":idOperatore,
             "data":JSON.stringify(this.obj.data)
            };

      json=JSON.stringify(data);


       console.log("sono su ramo save (non update), salverò: ",json);
       this.dbService.save2dbRicorso(json)
      // .map(res =>res.json())
       .subscribe(data  => {

         console.warn(`id del cocumento appena salvato vale ${(data as SavedRicorso).id }`);
         if((data as SavedRicorso).id!=-1){
            this.statusService.updateIdDocToSave((data as SavedRicorso).id);
            if(this.statusViewRicorso){
               this.dbService.addZip((data as SavedRicorso).id,this.showWhere).subscribe((data)=>{
                   console.log('_____');
           console.log(data);
           console.log('____');
                    setTimeout(()=>{
                     this.showApiMsg(data);
                   },1500);

               });
            }

            this.statusService.setGoToRelatives(true);
            this.goToRelatives=this.statusService.getRicorso().goToRelatives;

         }


         this.showSuccessMsg(data)});


    }




    //da fare
  }



relatives(){
      this.router.navigate(['/elencoParenti/'+ this.statusService.getIdDocToSave()]);
}




goToRelativesDraft($event){

   if(this.ricorsoDraft){





          let id=+this.statusService.getIdDocToSave();
          if(id!=-1){
            console.warn(`id del ricorso corrente vale ${id}`);
              this.dbService.getParentiFromDb(+this.statusService.getIdDocToSave()).subscribe(data => this.setElencoParenti((data as Resp).data));

            this.statusService.setGoToRelatives(true);
            this.goToRelatives=this.statusService.getRicorso().goToRelatives;
            this.relatives();

          }






        console.error(`goToRelatives vale ${this.goToRelatives}`);



    }


}







}
