import {Injectable} from '@angular/core';

@Injectable()

export class DataSportello {


	public idSportello: number;
	public nomeSportello: string;
	public indirizzo: string;
	public telefono: string;
	public comune: string;
	public responsabile: string;
	public cf: string;
	public email: string;
	public url: string;

	constructor(id: number= 0, nome: string= '', indirizzo: string= '', telefono: string= '', comune: string= '', responsabile: string= '', cf: string= '', email: string= '', url: string= '') {

		this.idSportello = id;
		this.nomeSportello = nome;
		this.indirizzo = indirizzo;
		this.telefono = telefono;
		this.comune = comune;
		this.responsabile = responsabile;
		this.cf = cf;
		this.email = email;
		this.url = url;

	}
}
