import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {StatusService} from '../../services/status.service';
import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import {DbService} from '../../services/db.service';
import {AppSettings} from '../../app-settings';
import {HttpHeaders,HttpClient} from '@angular/common/http';
import {DataIdoneita} from '../../data/data-idoneita';
import {DataDettagliExtraRicorso} from '../../data/data-dettagli-extra-ricorso';
import {DataSportello} from '../../data/data-sportello';
import { DataRicorso } from '../../data/data-ricorso';
import { FlashMessagesService } from 'angular2-flash-messages';
import { DomSanitizer } from '@angular/platform-browser';
import { Anagrafica} from '../../models/anagrafica';
import {Beneficiario} from '../../models/beneficiario';
import {LangService} from '../../services/lang.service';
import {Amministratore} from '../../models/amministratore';
import { ViewChild} from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@Component({
  selector: 'kind-account',
  templateUrl: './kind-account.component.html',

  providers: [DbService]
})
export class KindAccountComponent implements OnInit {

	@Input() amministratore:Amministratore;

	constructor(public langService:LangService){}

	ngOnInit(){}

	@ViewChild('kindAccountForm',{static:false}) form;

	submitForm(){
		this.form.onSubmit();
	}

	onSubmit(){
		console.log("kind account form inviato");
	}

	send(){

			
		   return (this.form.valid);
	}


	Labelkind(){
		return this.langService.labelKind();
	}


}
