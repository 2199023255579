import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { FlashMessagesService } from 'angular2-flash-messages';
import {StatusService} from '../../services/status.service';
import {Router} from '@angular/router';
import {DataRicorso} from '../../data/data-ricorso';
import {Ricorso} from '../../models/ricorso';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

@Input()	navs :any[];
@Input() status:string;
@Input() ric:Ricorso;
@Output() cleared= new EventEmitter;


  

  constructor(private router:Router, private statusService:StatusService) { }
  private account;
  private _operator;
  private surname;

  

  ngOnInit() {

  	this.account=this.statusService.getAccount();
    this._operator=this.statusService.getSpecialAccount();





  	
  }



  newRicorso(){

    console.log("new ricorso, tutto vuoto");
     this.statusService.updateRicorso(new DataRicorso());
     this.statusService.setNewDoc('RICORSO');
     this.statusService.resetParenti();
     this.statusService.setPrintExt(false);
     this.statusService.setViewExt(false);
     this.statusService.setGoToRelatives(false);
     this.statusService.setRelativesCompleted(false);
     this.statusService.resetActions();
     this.router.navigate(['/']);

     setTimeout(()=>{
         this.router.navigate(['/ricorso']);
     },500)
   

      this.cleared.emit();
     console.log(this.statusService.getRicorso());
     console.log("______________");
  }

  showRicorsoCorrente(){
    return this.statusService.updateDoc('RICORSO');
  }


  getIdSportello(){
    return this.statusService.getIdSportello();
  }


  supervisor(){
    return this.statusService.isSupervisor();
  }

  admin(){
  	return this._operator.kind=='adm';
  }

  operator(){
    return this._operator.kind=='opr';
  }

  logOut(){
    
    this.router.navigate(['']);
  }

  isLoadedAccount(){
    return this.statusService.isLoadedAccount();
  }

  getCognome(){
    if(this.statusService.isLoadedAccount()){
      return "area di lavoro su "+this.statusService.getSurname();
    }
  }


 
  


}
