import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import {AppSettings} from '../app-settings';
import {Md5} from "md5-typescript";

import {CompleteAccount} from '../models/complete-account';
import {Anagrafica} from '../models/anagrafica';
import {Account} from '../models/account';
import {Amministratore} from '../models/amministratore';
import {AccountOperator} from '../models/account-operator';
import {Beneficiario} from '../models/beneficiario';
import {Ricorrente} from '../models/ricorrente';
import {Username} from '../models/username';

import {Observable} from 'rxjs';
import {FrontOffice} from '../models/front-office';
import {DataSportello} from '../data/data-sportello';


@Injectable({
  providedIn: 'root'
})
export class DocService {

	private serviceBase=`${AppSettings.API_ENDPOINT}/php/documents/`;

  constructor(private http:HttpClient) {}


  	getPrivacy(sportello:DataSportello){
  		var headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
  		let body={'sportello':sportello};
  		let json=JSON.stringify(body);

       return this.http.post(this.serviceBase+'informativa.php',json,{headers:headers});
  	}
  	  

   
}
