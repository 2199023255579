import { Injectable } from '@angular/core';
import {Account} from './account';
import {Anagrafica} from './anagrafica';
import {Amministratore} from './amministratore';
/*import {Md5} from 'ts-md5/dist/md5';*/
import { DatePipe } from '@angular/common';
import {IndirizzoStrutturato} from './indirizzo';

import {Md5} from "md5-typescript";

import * as _ from 'lodash';



@Injectable()
export class CompleteAccount {

	constructor(){
		var datePipe = new DatePipe("en-US");
		this.creationDate=new Date();
		this.creationDate = datePipe.transform(this.creationDate, 'yyyy-MM-dd');

	}

	//kind può essere
	//ads => amministratoredisostegno
	//adm => admin
	//opr => operatore

    public emailValida:boolean;
	public email:string="";
	public ripetiEmail:string="";
	public password:string="";
	public ripetiPassword:string="";
	public nome:string="";
	public sesso:string="";
	public cognome:string="";
	public dataDiNascita:string="";
	public codiceFiscale:string="";
	public luogoDiNascita:string="";
	public indirizzo:string="";
	public creationDate;
	public kind:string; //ads o altro...
	public informativa:boolean;
	public idSportello:number;
	public telefono:string;
	public cellulare:string;
	public kindAccount:string; //volontario o familiare
	public indirizzoStrutturato:IndirizzoStrutturato;


	public setSportello(n){
		this.idSportello=n;
	}

	public setAmministratoreData(amm:Amministratore){
		this.telefono=amm.telefono;
		this.cellulare=amm.cellulare;
		this.kindAccount=amm.kind; //volontario o familiare
	}

	public addAccount(account:Account){


		this.emailValida=account.emailValida;
		this.email=account.email;

		this.ripetiEmail=account.ripetiEmail;


		//this.password=Md5.init(account.password).toString();

		//this.ripetiPassword=account.ripetiPassword;

		this.informativa=account.informativa;


	}

	public addAnagrafica(anagrafica:Anagrafica){

		console.log("addAnagrafica di complete-account.ts");
		console.log("loggo anagrafica: ",anagrafica);

		this.indirizzoStrutturato=new IndirizzoStrutturato();
		this.indirizzoStrutturato=_.cloneDeep(anagrafica.indirizzoStrutturato);

		this.nome=anagrafica.nome;

		console.log("ho assegnato il nome");
		this.cognome=anagrafica.cognome;

		console.log("ho assegnato il conome");

		this.sesso=anagrafica.sesso;

		this.dataDiNascita=anagrafica.dataDiNascita;
		console.log("ho assegnato la deata di nascita");
		this.luogoDiNascita=anagrafica.luogoDiNascita;

		console.log("ho assegnato la data di nascita");
		this.codiceFiscale=anagrafica.codiceFiscale;


		console.log("ho assegnato il codice fisclae");
		this.indirizzo=anagrafica.indirizzo;
		console.log("ho assegnato l'indirizzo");

		console.log("fine di addAnagrafica di complete-account.ts");
	}

	public setKind(k:string){
		console.log("setKind di complete-account.ts");
		this.kind=k;
	}
	public getKind(){
		return this.kind;
	}



}
