import {Injectable} from '@angular/core';

@Injectable()

export class DataAuth {
//autorizzazione varia (esempio modifica tetto prelievo)
//obj->object oggetto autorizzazione, si rende necessario...(aprire un nuovo cc presso...) (modificare il tetto di prelievo mensile di 500 €, portandolo a 1000€)
//obj->auth
//di essere autorizzat   dalla  S.V. a compiere gli atti di rito necessari a (aprire un nuovo c/c presso la banca tal dei tali)  (modificare il tetto di prelievo mensile, portandolo al valore di 1000€)
//obj->attach;

	public data:string;
	public object:string;
	public auth:string;
	public attach:string;
	public print:boolean;
	public view:boolean;

	constructor(d:string="",ogg:string="",autorizza:string="",allegato:string="", print:boolean=false,view:boolean=false){
		
		this.data=d;
		this.object=ogg;
		this.auth=autorizza;
		this.attach=allegato;
		this.print=print;
		this.view=view;
		
	}
}
