import { Component, OnInit } from '@angular/core';
import {DocumentIconComponent} from '../document-icon/document-icon.component';

import {AppSettings} from '../../app-settings';
import {DbService} from '../../services/db.service'; 
import {StatusService} from '../../services/status.service';

@Component({
  selector: 'documentIconBuyCar',
  templateUrl: './document-icon-buy-car.component.html',
  styleUrls: ['./document-icon-buy-car.component.css'],
  providers: [DbService]
})
export class DocumentIconBuyCarComponent extends DocumentIconComponent {


}
