import { Component, OnInit,Input } from '@angular/core';
import { DocumentIconComponent  } from '../document-icon/document-icon.component';
import {KindDocument} from '../../kind-document';
import {StatusService} from '../../services/status.service';
import {DataSellImm} from '../../data/data-sell-imm';
import {DbService} from '../../services/db.service';

import {AppSettings} from '../../app-settings';


@Component({
  selector: 'documentIconSellImm',
  templateUrl: './document-icon-sell-imm.component.html',
  styleUrls: ['./document-icon-sell-imm.component.css'],
    providers:[DbService]

})
export class DocumentIconSellImmComponent extends DocumentIconComponent {



}
