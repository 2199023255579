
import { Injectable } from '@angular/core';


export class IndirizzoStrutturato {
	public comune:Comune;
	public indirizzo:Indirizzo;

	constructor(com:Comune={cap:'',comune:'',provincia:''},ind:Indirizzo={prefixTopo:'',denomTopo:'',civico:'',nazionalita:''}){
		this.comune=com;
		this.indirizzo=ind;
	}

}


export interface Comune {
  cap:string;
  comune:string;
  provincia:string;
}


export interface Indirizzo {
  prefixTopo:string;
  denomTopo:string;
  civico:string;
  nazionalita:string;
}


export enum STATO {
	IT='IT',
	ESTERO='ESTERO',
	SCONOSCIUTO='SCONOSCIUTO'
}
