import { Injectable } from '@angular/core';
import {IndirizzoStrutturato} from './indirizzo';

export enum Relazione {
    Parente=0,
    Affine=1,
    Altro=2
}

@Injectable()
export class Ricorrente {

	constructor(public nome:string="",public cognome:string="",public dataDiNascita:string="",public codiceFiscale:string="",public professione:string="",public qualificaCandidato:string="", public luogoDiNascita:string="",public indirizzo:string="", public sesso:string="", public telefono:string="", public cellulare:string="",public email:string="",public candidato:string="",public grado:string="",public relazione:Relazione=0, public indirizzoStrutturato:IndirizzoStrutturato=new IndirizzoStrutturato()
		){

	} 
}
